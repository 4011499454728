import { CircularProgress } from '@material-ui/core';
import React from 'react';

import useProfile from 'hooks/useProfile';

const UserName = () => {
  const { profile, loading } = useProfile();

  if (loading || !profile) return <CircularProgress size={24} />;

  return (
    <span>
      {profile.given_name} {profile.family_name}
    </span>
  );
};

export default UserName;
