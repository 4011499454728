import React from 'react';
import { get } from 'lodash';
import { TwoLinesLabel } from 'components/common';
import moment from 'moment';

const DateTimeField = (props) => {
  const { record } = props;

  if (!record) {
    return null;
  }

  const date = get(record, 'scheduledDate');
  const time = get(record, 'time');

  return (
    <TwoLinesLabel
      label="Date"
      primary={date ? moment(date, 'YYYY-MM-DD').format('ll') : ''}
      secondary={time ? moment(time, 'hh:mm:ss').format('h:mm a') : ''}
    />
  );
};

export default DateTimeField;
