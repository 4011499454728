export const SET_VERIFICATION_CLOSED = 'Rollo/SET_VERIFICATION_CLOSED';
export const VERIFICATION_DIALOG_OPEN = 'Rollo/VERIFICATION_DIALOG_OPEN';
export const VERIFICATION_DIALOG_CLOSE = 'Rollo/VERIFICATION_DIALOG_CLOSE';
export const SET_VERIFIED_PHONE = 'Rollo/SET_VERIFIED_PHONE';

export const setVerificationClosed = (payload) => ({
  type: SET_VERIFICATION_CLOSED,
  payload,
});

export const openVerification = (newPhoneNumber?: string) => ({
  type: VERIFICATION_DIALOG_OPEN,
  payload: {
    newPhoneNumber,
  },
});

export const closeVerification = () => ({
  type: VERIFICATION_DIALOG_CLOSE,
});

export const setVerifiedPhone = (verifiedPhone: string | null) => ({
  type: SET_VERIFIED_PHONE,
  payload: {
    verifiedPhone,
  },
});
