import {
  SET_VERIFICATION_CLOSED,
  VERIFICATION_DIALOG_CLOSE,
  VERIFICATION_DIALOG_OPEN,
  SET_VERIFIED_PHONE,
} from 'store/actions/verificationActions';

interface State {
  verificationClosed: boolean;
  dialogOpen: boolean;
  newPhoneNumber?: string;
  verifiedPhone?: string | null;
}

const INITIAL_STATE = {
  verificationClosed: false,
  dialogOpen: false,
  verifiedPhone: null,
};

const verificationReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_VERIFICATION_CLOSED:
      return { ...state, verificationClosed: payload };
    case VERIFICATION_DIALOG_OPEN:
      return {
        ...state,
        dialogOpen: true,
        newPhoneNumber: payload.newPhoneNumber,
        verifiedPhone: null,
      };
    case VERIFICATION_DIALOG_CLOSE:
      return {
        ...state,
        dialogOpen: false,
        newPhoneNumber: null,
      };
    case SET_VERIFIED_PHONE:
      return {
        ...state,
        verifiedPhone: payload.verifiedPhone,
      };
    default:
      return state;
  }
};

export default verificationReducer;
