import React, { MouseEvent, useState } from 'react';
import { Link, Popover, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  helpText: {
    fontWeight: 'bold',
  },
  popover: {
    padding: 10,
    borderRadius: '8px',
    lineHeight: 1.2,
    zIndex: 3,
    fontSize: '14px',
    backgroundColor: '#EAE6FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
}));

const LabeledUrlField = ({
  record = {},
  source,
  labelSource = source,
  target = '_blank',
  hasHelpText = false,
  ...rest
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Link
        {...(hasHelpText && {
          onMouseEnter: handleOpen,
          onMouseLeave: handleClose,
        })}
        className={rest.className}
        href={record[source]}
        target={target}
        underline="always"
        data-test="link"
        onClick={(e) => e.stopPropagation()}>
        {rest.children || record[labelSource]}
      </Link>
      {hasHelpText && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            top: 10,
            left: 20,
            pointerEvents: 'none',
          }}
          disableRestoreFocus>
          <div className={classes.popover}>
            <Typography className={classes.helpText}>
              {rest.children || record[labelSource]}
            </Typography>
          </div>
        </Popover>
      )}
    </>
  );
};

export default LabeledUrlField;
