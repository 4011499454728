import { GET_ONE } from 'react-admin';

export default {
  [GET_ONE]: async ({ response, url, options, httpClient, params, apiUrl }) => ({
    ...response,
    json: {
      ...response.json,
      items: await httpClient(url + '/items', { ...options, method: 'GET' })
        .then((r) => r.json)
        .catch((e) => []),
    },
  }),
};
