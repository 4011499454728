import { get } from 'lodash';

interface AddressPathsMapping {
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

const defaultPaths = {
  name: 'name',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  addressLine3: 'addressLine3',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
  country: 'country',
};

const addressToString = (
  address,
  options?: { withName?: boolean; withCountry?: boolean; paths?: AddressPathsMapping }
) => {
  const { withName = false, withCountry = true, paths } = options || {};

  const { name, addressLine1, addressLine2, addressLine3, city, state, zipCode, country } = {
    ...defaultPaths,
    ...paths,
  };

  return [
    withName && get(address, name),
    get(address, addressLine1),
    get(address, addressLine2),
    get(address, addressLine3),
    get(address, city),
    [
      get(address, state) ||
        get(address, 'stateProvinceCode') ||
        get(address, 'stateOrProvince.code'),
      get(address, zipCode) || get(address, 'postalCode'),
    ]
      .filter((s) => s)
      .join(' '),
    withCountry &&
      (get(address, country) ||
        get(address, 'countryCode') ||
        get(address, 'stateOrProvince.country.name')),
  ]
    .filter((s) => s)
    .map((s) => s.replace(/(^,)|(,$)/g, ''))
    .join(', ');
};

export default addressToString;
