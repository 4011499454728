import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Warning = (props) => (
  <SvgIcon {...props} viewBox="0 0 19 19">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM10.8332 13.3337C10.8332 13.7939 10.4601 14.167 9.99984 14.167C9.5396 14.167 9.1665 13.7939 9.1665 13.3337C9.1665 12.8734 9.5396 12.5003 9.99984 12.5003C10.4601 12.5003 10.8332 12.8734 10.8332 13.3337ZM9.99984 10.8337C10.4601 10.8337 10.8332 10.4606 10.8332 10.0003V6.66699C10.8332 6.20675 10.4601 5.83366 9.99984 5.83366C9.5396 5.83366 9.1665 6.20675 9.1665 6.66699V10.0003C9.1665 10.4606 9.5396 10.8337 9.99984 10.8337Z"
      fill="#FFC20E"
    />
  </SvgIcon>
);

export default Warning;
