import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { get } from 'lodash';
import { Dimensions, Weight } from 'icons';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'bottom',
    color: theme.palette.primary.light,
    marginRight: theme.spacing(1),
  },

  params: {
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.text.secondary,
  },
}));

const PackageField = ({ record = {} }) => {
  const classes = useStyles();

  return (
    <Grid container data-test="package">
      <Grid item xs={6} className={classes.params} data-test="dimensions">
        <Dimensions className={classes.icon} />
        {get(record, 'size.width')}&times;{get(record, 'size.height')}&times;
        {get(record, 'size.length')} in
      </Grid>
      <Grid item xs={6} className={classes.params} data-test="weight">
        <Weight className={classes.icon} />
        {get(record, 'weight.lb')} lb {get(record, 'weight.oz')} oz
      </Grid>
    </Grid>
  );
};

export default PackageField;
