import React from 'react';

import { useUpdate, useListController, useGetOne, CRUD_GET_ONE, useVersion } from 'react-admin';
import { get } from 'lodash';

import ListView from './ListView';

export const usePagingStoreController = (listProps) => {
  const version = useVersion();

  const { data, loaded } = useGetOne('rowsPerPageSettings', listProps.resource, {
    version,
    action: CRUD_GET_ONE,
  });

  const [update] = useUpdate('rowsPerPageSettings', listProps.resource);

  return {
    update,
    data,
    loaded,
  };
};

const ListViewWrapped = ({ perPage, update, ...props }) => {
  const controllerProps = useListController({ ...props, perPage });

  const setPerPage = (value) => {
    update({
      payload: {
        data: {
          perPage: value,
        },
      },
    });

    controllerProps.setPerPage(value);
  };

  return <ListView {...props} {...controllerProps} setPerPage={setPerPage} />;
};

const List = (props) => {
  const { data: perPageStore, update } = usePagingStoreController(props);
  const perPage = get(perPageStore, 'perPage', 10);

  return <ListViewWrapped perPage={perPage} update={update} {...props} />;
};

export default List;
