import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as MUILink } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { IWarehousePreset } from 'services/data/interfaces';
import Option from './Option';
import OptionList from './OptionList';
import { makeStyles } from '@material-ui/styles';

type OptionType =
  | IWarehousePreset
  | { postalCode: string; dummy?: boolean; addNewOption?: boolean };

const useStyles = makeStyles((theme) => ({
  dummyOption: {
    backgroundColor: 'transparent !important',
    cursor: 'inherit',
  },
  addNewOptionLink: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
  },
}));

const ZipAutocomplete = ({
  className,
  value,
  options,
  renderInput,
  onChange,
  isDestinationAddress,
  zipCodeRequired,
  redirectAfterSubmit,
}: {
  className?: string;
  value: { postalCode: string; id: string; city: string };
  options: IWarehousePreset[];
  renderInput: (params) => React.ReactNode;
  onChange: (event, option) => void;
  isDestinationAddress: boolean;
  zipCodeRequired: boolean;
  redirectAfterSubmit: string;
}) => {
  const classes = useStyles();

  const zipValue = useMemo(
    () => options && options.find((wp) => wp.id === value.id),
    [options, value]
  );

  const [inputValue, setInputValue] = useState(zipValue?.postalCode ?? '');

  const filterOptions = createFilterOptions<OptionType>({
    stringify: (option) => option.postalCode ?? '',
  });

  const optionsExist = filterOptions(options, { inputValue }).length > 0;

  const finalOptions = optionsExist
    ? [...options, { postalCode: inputValue, addNewOption: true }]
    : [...options, { postalCode: inputValue, dummy: true }];

  const renderOption = (option, { selected }) => {
    if (option.dummy) {
      return (
        <div>
          No saved ship from addresses have been added. Do you want to{' '}
          <MUILink
            to={`/warehousePresets/create?redirectAfterSubmit=${redirectAfterSubmit}`}
            component={Link}>
            add one
          </MUILink>
          ?
        </div>
      );
    }

    if (option.addNewOption) {
      return (
        <MUILink
          className={classes.addNewOptionLink}
          to={`/warehousePresets/create?redirectAfterSubmit=${redirectAfterSubmit}`}
          component={Link}>
          Add new address
        </MUILink>
      );
    }

    return <Option option={option} checked={selected} />;
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleChange = (event, option) => {
    if (option.dummy) {
      return;
    }

    onChange(event, option);
  };

  return (
    <Autocomplete<OptionType>
      className={className}
      classes={{ option: optionsExist ? undefined : classes.dummyOption }}
      disableClearable
      forcePopupIcon={false}
      options={finalOptions}
      value={zipValue}
      inputValue={inputValue}
      getOptionLabel={(option) => option.postalCode ?? ''}
      filterOptions={filterOptions}
      PaperComponent={({ children, ...props }) => (
        <OptionList
          {...props}
          listName={`SHIP ${isDestinationAddress ? 'TO' : 'FROM'} ADDRESS`}
          isDestinationAddress={isDestinationAddress}>
          {children}
        </OptionList>
      )}
      renderOption={renderOption}
      renderInput={renderInput}
      onInputChange={handleInputChange}
      onChange={handleChange}
    />
  );
};

export default ZipAutocomplete;
