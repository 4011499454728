import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: 0,
      position: 'relative',
    },

    checkedIcon: {
      position: 'absolute',
      fontSize: 14,
      color: theme.palette.primary.main,
      left: 0,
      top: theme.spacing(2.6),
    },

    content: {
      padding: theme.spacing(2, 2, 2, 2.5),
      overflow: 'hidden',
    },

    primary: {
      color: theme.palette.text.primary,
      fontSize: 18,
      lineHeight: '20px',
      fontWeight: 'normal',
      marginBottom: theme.spacing(0.5),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    secondary: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });

const Option = ({ classes, option, checked }) => (
  <div className={classes.root}>
    {checked && <CheckOutlined className={classes.checkedIcon} />}
    <div className={classes.content}>
      <span className={classes.primary}>{option.name} </span>
      <span className={classes.secondary}>
        ({option.city}, {option.state}, {option.postalCode})
      </span>
    </div>
  </div>
);

export default withStyles(styles)(Option);
