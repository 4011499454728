import React, { ReactElement, ReactNode } from 'react';
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import SubmitButton from 'components/buttons/SubmitButton';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    minWidth: 0,
    right: theme.spacing(2.5),
    top: theme.spacing(3.25),
    color: theme.palette.secondary.contrastText,
  },

  title: {
    padding: theme.spacing(3, 3, 1),
    '& .MuiTypography-h6': {
      fontSize: '28px !important',
    },
  },

  content: {
    padding: theme.spacing(0, 1),
    minWidth: theme.spacing(60),
    minHeight: theme.spacing(8),
    overflowX: 'hidden',
  },

  error: {
    padding: theme.spacing(2, 4, 0),
    color: theme.palette.error.main,
  },

  fixError: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,

    '&:hover': {
      borderColor: theme.palette.error.dark,
    },
  },

  actions: {
    padding: theme.spacing(4),

    '& .MuiButton-containedSecondary': {
      backgroundColor: theme.palette.secondary.light,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },

  note: {
    padding: theme.spacing(0, 4, 4),
    fontSize: 14,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },

  button: {
    textTransform: 'none',
  },
}));

interface BillDialogProps {
  open: boolean;
  title: ReactNode;
  confirmText?: string;
  confirmDisabled?: boolean;
  cancelText?: string;
  note?: ReactNode;
  children: ReactNode;
  submitting?: boolean;
  error?: string;
  onCancel: () => void;
  onConfirm?: (event) => void;
  onFixError?: () => void;
  progress?: ReactElement;
  dialogProps?: Record<string, any>;
}

const BillDialog = (props: BillDialogProps) => {
  const {
    open,
    title,
    confirmText = 'Ok',
    confirmDisabled = false,
    cancelText = 'Cancel',
    note = null,
    children,
    submitting,
    error,
    onCancel,
    onConfirm,
    onFixError,
    dialogProps,
    progress,
  } = props;

  const classes = useStyles();

  return (
    <MUIDialog open={open} onClose={onCancel} maxWidth="md" {...dialogProps}>
      <Button className={classes.closeButton} onClick={onCancel}>
        <CloseOutlined />
      </Button>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      {error && (
        <div className={classes.error}>
          {error}
          {onFixError && (
            <Button
              classes={{ root: classes.fixError }}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={onFixError}>
              Fix it
            </Button>
          )}
        </div>
      )}
      <DialogActions className={classes.actions}>
        <Grid container spacing={4}>
          <Grid item xs={onConfirm ? 6 : 12}>
            <Button
              fullWidth
              variant="contained"
              color={onConfirm ? 'secondary' : 'primary'}
              size="large"
              onClick={onCancel}
              className={classes.button}>
              {cancelText}
            </Button>
          </Grid>
          {onConfirm && (
            <Grid item xs={6}>
              <SubmitButton
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                label={confirmText}
                disabled={submitting || confirmDisabled}
                submitting={submitting}
                onClick={onConfirm}
                className={classes.button}
                progress={progress}
              />
            </Grid>
          )}
        </Grid>
      </DialogActions>
      {note && <div className={classes.note}>{note}</div>}
    </MUIDialog>
  );
};

export default BillDialog;
