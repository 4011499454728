import { useState } from 'react';

type State<T> =
  | { status: 'PENDING'; value: null }
  | { status: 'PROGRESS'; value: null }
  | { status: 'SUCCESS'; value: T }
  | { status: 'FAILED'; value: any };

const useAsyncTask = <R, T extends (...args: any[]) => Promise<R>>(
  task: T
): [(...args: Parameters<T>) => Promise<R>, State<R>] => {
  const [result, setResult] = useState<State<R>>({ status: 'PENDING', value: null });

  const run: (...args: Parameters<T>) => Promise<R> = (...args: Parameters<T>) => {
    setResult({ status: 'PROGRESS', value: null });

    const promise = task(...args);

    promise
      .then((value) => setResult({ status: 'SUCCESS', value }))
      .catch((value) => setResult({ status: 'FAILED', value }));

    return promise;
  };

  return [run, result];
};

export default useAsyncTask;
