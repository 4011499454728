import { MenuItem } from '@material-ui/core';
import SelectBase, { ISelectProps } from 'components/form/SelectBase';
import React, { useMemo } from 'react';
import { FieldProps } from 'react-final-form';

const Select = ({ options, ...rest }: FieldProps<any, any> & ISelectProps) => (
  <SelectBase {...rest} inputProps={{ readOnly: !!!options.length }}>
    {useMemo(
      () =>
        options.map((option) => (
          <MenuItem key={option.value.id || option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )),
      [options]
    )}
  </SelectBase>
);

export default Select;
