import React, { useState } from 'react';
import { DayPickerRangeController as ADateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';
import { createStyles, withStyles } from '@material-ui/core';

export const styles = (theme) =>
  createStyles({
    root: {
      '& .CalendarDay__selected, .CalendarDay__selected:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '& .CalendarDay__selected_span, .CalendarDay__hovered_span': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.7,
        color: 'white',
        borderColor: theme.palette.primary.main,
      },
      '& .DayPickerKeyboardShortcuts_show__bottomRight::before': {
        borderRightColor: theme.palette.primary.main,
      },
      '& .DayPickerNavigation_button__horizontalDefault': {
        borderRadius: 18,
      },
    },
  });

const DateRangeInput = ({ input: { value, onChange }, classes, handleClose, ...rest }) => {
  const [focused, setFocused] = useState('startDate');

  const onDatesChange = (val) => {
    onChange(focused === 'startDate' ? { startDate: val.startDate, endDate: undefined } : val);
    if (focused === 'endDate') handleClose();
  };

  return (
    <span className={classes.root}>
      <ADateRangePicker
        startDate={value.from ? moment(value.from) : undefined}
        endDate={value.to ? moment(value.to) : undefined}
        onDatesChange={onDatesChange}
        focusedInput={focused}
        onFocusChange={(focusedInput) => setFocused(focusedInput)}
        numberOfMonths={2}
        initialVisibleMonth={() => moment()}
        minimumNights={0}
        {...rest}
      />
    </span>
  );
};

export default withStyles(styles)(DateRangeInput);
