import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useRedirect } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2.5),
    lineHeight: '16px',
  },
}));

const OpenOrderButtonField = ({ record }: { record? }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const handleClick = (e) => {
    e.stopPropagation();
    redirect('show', '/orders', record.id);
  };

  return (
    <Button
      className={classes.root}
      color="primary"
      variant="contained"
      size="small"
      onClick={handleClick}>
      Open&nbsp;order
    </Button>
  );
};

export default OpenOrderButtonField;
