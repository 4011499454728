import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  Grid,
} from '@material-ui/core';
import { CloseOutlined, HighlightOffOutlined } from '@material-ui/icons';
import { closeAll, closeDoc } from 'store/actions/lightboxActions';
import useCustomNotify from 'hooks/useCustomNotify';
import printPdf from '../../../services/helpers/printPDF';
import ReactHtmlParser from 'react-html-parser';

declare const InstallTrigger: any;

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflow: 'hidden',
  },

  closeButton: {
    position: 'absolute',
    minWidth: 0,
    right: theme.spacing(2.5),
    top: theme.spacing(1.75),
    color: theme.palette.secondary.contrastText,
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    // paddingRight: theme.spacing(8),
    flexWrap: 'wrap',
  },

  actionButton: {
    marginLeft: theme.spacing(4),
  },

  content: {
    position: 'relative',
    width: theme.spacing(75),
    marginBottom: theme.spacing(2),
  },

  hidden: {
    position: 'absolute',
    visibility: 'hidden',
    top: 0,
    width: 0,
    height: 0,
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4, 0),
  },

  embedView: {
    height: theme.spacing(75),
  },

  internationalEmbedView: {
    '@media (min-width: 1601px)': {
      height: theme.spacing(75),
    },
    '@media (max-width: 1600px)': {
      height: theme.spacing(50),
    },
  },

  docView: {
    position: 'relative',
    maxHeight: '56.25%',
    overflow: 'hidden',
    backgroundColor: '#555',
    fontSize: 0,
    width: '100%',
  },

  page: {
    display: 'inline-block',
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },

  frame: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  },

  tabLabel: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  tabClose: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.light,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  internationalButton: {
    height: 35,
    marginTop: theme.spacing(2),
    '&.MuiButton-containedSecondary': {
      backgroundColor: theme.palette.secondary.light,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },

  internationalNotice: {
    marginTop: theme.spacing(2),
    '& ol': {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const Document = ({ document, visible, onInitActions, showInternational }) => {
  const [data, setData] = useState<
    { blob: Blob; url: string; originalUrl: string } | { error: string } | null
  >(null);
  const [actions, setActions] = useState<any>(null);
  const classes = useStyles();

  useEffect(() => {
    if (document.blob) {
      setData({
        blob: document.blob,
        url: URL.createObjectURL(document.blob),
        originalUrl: document.url,
      });
    } else {
      fetch(document.url)
        .then((res) => res.blob())
        .then((blob) => {
          setData({
            blob,
            url: URL.createObjectURL(blob),
            originalUrl: document.url,
          });
        })
        .catch((error) => {
          setData({
            error: error.message,
          });
        });
    }

    return () => {
      if (data && 'url' in data) {
        URL.revokeObjectURL(data.url);
      }
    };
  }, [document]); //eslint-disable-line

  useEffect(() => {
    if (visible && actions) {
      onInitActions(actions);
    }
  }, [visible, actions]); //eslint-disable-line

  const handleInitActions = (actions) => setActions(actions);

  return (
    <div className={visible ? '' : classes.hidden}>
      <DocumentFrame
        data={data}
        onInitActions={handleInitActions}
        showInternational={showInternational}
      />
    </div>
  );
};

const DocumentFrame = ({ data, onInitActions, showInternational }) => {
  const classes = useStyles();
  const notify = useCustomNotify();
  const pageRef = useRef<HTMLDivElement>(null);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isWindows = navigator.userAgent.toLocaleLowerCase().includes('windows');

  const handleLoad = (e) => {
    const frame = e.target as HTMLIFrameElement;

    // reference to .print throws a Security Error in Firefox due to bug: https://bugzilla.mozilla.org/show_bug.cgi?id=911444
    try {
      if (!isFirefox && frame.contentWindow && frame.contentWindow.print) {
        onInitActions({
          print: () => {
            try {
              frame.contentWindow?.print();
            } catch (err) {
              notify(err.message, 'error');
            }
          },
        });
      }
      if (isFirefox) {
        onInitActions({
          print: () => {
            try {
              printPdf(data.originalUrl, notify);
            } catch (err) {
              notify(err.message, 'error');
            }
          },
        });
      }
    } catch (err) {
      onInitActions({
        download: data.originalUrl,
      });
    }

    if (data.blob.type !== 'text/html') {
      return;
    }

    const html = frame.contentDocument?.querySelector('html');
    const body = frame.contentDocument?.body;

    if (!pageRef.current || !html || !body) {
      return;
    }

    html.style.overflow = 'hidden';

    Object.assign(pageRef.current.style, {
      width: `${body.scrollWidth}px`,
      height: `${body.scrollHeight}px`,
    });
  };

  if (!data) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  if (data.error) {
    return (
      <div className={classes.loading}>
        <Typography color="error">{data.error}</Typography>
      </div>
    );
  }

  if (data.blob.type === 'text/html') {
    return (
      <div className={classes.docView}>
        <div ref={pageRef} className={classes.page}>
          <iframe title="PDF" className={classes.frame} src={data.url} onLoad={handleLoad} />
        </div>
      </div>
    );
  }

  if (isFirefox) {
    return (
      <div className={showInternational ? classes.internationalEmbedView : classes.embedView}>
        <object
          className={classes.frame}
          data={data.originalUrl ? data.originalUrl : data.url}
          type="application/pdf">
          <iframe
            title="PDF"
            className={classes.frame}
            src={data.originalUrl ? data.originalUrl : data.url}
            onLoad={handleLoad}
          />
        </object>
      </div>
    );
  }

  if (isWindows) {
    return (
      <div className={showInternational ? classes.internationalEmbedView : classes.embedView}>
        <iframe
          title="PDF"
          className={classes.frame}
          src={`${data.url}#navpanes=0`}
          onLoad={handleLoad}
        />
      </div>
    );
  }

  return (
    <div className={showInternational ? classes.internationalEmbedView : classes.embedView}>
      <object className={classes.frame} data={data.url} type="application/pdf">
        <iframe title="PDF" className={classes.frame} src={data.url} onLoad={handleLoad} />
      </object>
    </div>
  );
};

const Lightbox = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const docs = useSelector((state) => state.lightbox.docs, shallowEqual);
  const [tab, setTab] = useState(0);
  const [actions, setActions] = useState<any>(null);
  const [showInternational, setShowInternational] = useState<boolean>(false);
  const isFirefox = typeof InstallTrigger !== 'undefined';

  useEffect(() => {
    const hideInternational = JSON.parse(localStorage.getItem('hideInternational') as string);
    setShowInternational(!hideInternational);
  }, [docs]);

  const handleClose = () => {
    setTab(0);
    dispatch(closeAll());
  };

  const handleCloseTab = (index) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    setTab(Math.min(tab, docs.length - 2));

    const doc = docs[index];
    dispatch(closeDoc(doc.id));
  };

  const handleChangeTab = (e, index) => {
    setActions(null);
    setTab(index);
  };

  const handleInitActions = (actions) => setActions(actions);

  if (!docs || !docs.length) {
    return null;
  }

  const doc = docs[tab];

  const onConfirm = () => {
    setShowInternational(false);
  };

  const onCancel = () => {
    setShowInternational(false);
    localStorage.setItem('hideInternational', JSON.stringify(true));
  };

  return (
    <Dialog
      open={true}
      onEscapeKeyDown={handleClose}
      PaperProps={{
        style: { overflow: 'hidden' },
      }}>
      <Button className={classes.closeButton} onClick={handleClose}>
        <CloseOutlined />
      </Button>
      <DialogTitle disableTypography={true} className={classes.title}>
        <Typography variant="h6">{docs.length > 1 ? 'Multiple Documents' : doc.name}</Typography>
        {actions && actions.print && (
          <Button
            className={classes.actionButton}
            color="primary"
            size="small"
            variant="contained"
            onClick={actions.print}>
            Print
          </Button>
        )}
        {actions && actions.download && (
          <Button
            className={classes.actionButton}
            component="a"
            color="primary"
            size="small"
            variant="contained"
            href={actions.download}
            target="_blank"
            download>
            {isFirefox ? 'Print' : 'Download'}
          </Button>
        )}
        {showInternational && !!doc.noticeHtml && (
          <div className={classes.internationalNotice}>
            <Typography>For international shipments</Typography>
            {ReactHtmlParser(decodeURIComponent(doc.noticeHtml.replace(/\+/g, ' ')))}
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  fullWidth
                  className={classes.internationalButton}
                  onClick={onCancel}>
                  Don't show this again
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={classes.internationalButton}
                  onClick={() => onConfirm()}>
                  Ok
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </DialogTitle>
      {docs.length > 1 && (
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          onChange={handleChangeTab}>
          {docs.map((doc, i) => (
            <Tab
              key={doc.id}
              value={i}
              label={
                <span className={classes.tabLabel}>
                  <span>{doc.name}</span>
                  <HighlightOffOutlined
                    className={classes.tabClose}
                    fontSize="small"
                    onClick={handleCloseTab(i)}
                  />
                </span>
              }
            />
          ))}
        </Tabs>
      )}
      <div className={classes.content}>
        {docs.map((doc, index) => (
          <Document
            key={doc.id}
            document={doc}
            visible={tab === index}
            onInitActions={handleInitActions}
            showInternational={showInternational}
          />
        ))}
      </div>
    </Dialog>
  );
};

export default Lightbox;
