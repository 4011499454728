import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core';
import CheckOutlined from '@material-ui/icons/CheckOutlined';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      paddingBottom: theme.spacing(5),
    },

    step: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      paddingLeft: theme.spacing(2),

      boxSizing: 'border-box',
      height: theme.spacing(5.5),
      borderColor: theme.palette.primary.light,
      borderStyle: 'solid none',
      borderWidth: 1,

      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 'bold',
      color: theme.palette.secondary.contrastText,

      '&:first-child': {
        borderLeftStyle: 'solid',
        borderTopLeftRadius: theme.spacing(3.5),
        borderBottomLeftRadius: theme.spacing(3.5),
      },

      '&:last-child': {
        borderRightStyle: 'solid',
        borderTopRightRadius: theme.spacing(3.5),
        borderBottomRightRadius: theme.spacing(3.5),
      },

      '&:not(:first-child)': {
        marginLeft: theme.spacing(-1.5),
      },

      '&:not(:last-child)': {
        marginRight: theme.spacing(1.5),

        '&:after': {
          position: 'absolute',
          content: '""',
          width: 50,
          height: 50,
          right: -11,
          top: -5,
          border: '1px solid',
          borderColor: 'inherit',
          transform: 'rotateZ(45deg)',
          clip: 'rect(-1px, 53px, 31px, 21px)',
        },
      },
    },

    passed: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },

    completed: {
      cursor: 'pointer',
    },

    icon: {
      fontSize: 14,
      marginRight: theme.spacing(1),
    },
  });

const WizardSteps = ({ classes, steps, currentStep, onSelect }) => (
  <div className={classes.root}>
    {steps.map((step, index) => (
      <div
        key={step.label}
        className={classNames(
          classes.step,
          currentStep >= index && classes.passed,
          currentStep > index && classes.completed
        )}
        onClick={() => currentStep > index && onSelect(index)}>
        {currentStep > index && <CheckOutlined className={classes.icon} />}
        {step.label}
      </div>
    ))}
  </div>
);

export default withStyles(styles)(WizardSteps);
