import Select from './Select';
import React from 'react';
import { GET_LIST, Query } from 'react-admin';
import { CircularProgress } from '@material-ui/core';

const OrderStatusSelect = ({ name, label, ...rest }) => (
  <Query type={GET_LIST} resource="orderStatuses">
    {({ data, loading, error }) => {
      if (loading) {
        return <CircularProgress />;
      }

      if (error) {
        return <p>error</p>;
      }

      return (
        <Select
          label={label}
          name={name}
          options={data.map((status) => ({ value: status.id, label: status.name }))}
          {...rest}
        />
      );
    }}
  </Query>
);

export default OrderStatusSelect;
