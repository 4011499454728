import React from 'react';
import { Field, FieldProps } from 'react-final-form';
import { FormLabel } from '@material-ui/core';
import ToggleOptionsButtons from 'components/buttons/ToggleOptions';

const ToggleOptions = ({
  className,
  name,
  options,
  multi,
  onChange,
  ...rest
}: {
  className?: string;
  name: string;
  options: Array<{ value: any; label: string }>;
  multi?: boolean;
  onChange?: (option: boolean) => void;
} & FieldProps<any, any>) => (
  <Field
    {...rest}
    name={name}
    render={({ input: { value, onChange: fieldOnChange }, meta: { touched, error } }) => {
      const handleChange = (option) => {
        if (onChange) {
          onChange(option);
        } else {
          fieldOnChange(option);
        }
      };
      return (
        <div className={className}>
          <ToggleOptionsButtons
            value={value}
            options={options}
            multi={multi}
            error={touched && error}
            onChange={handleChange}
          />
          {touched && error && <FormLabel error>{error}</FormLabel>}
        </div>
      );
    }}
  />
);

export default ToggleOptions;
