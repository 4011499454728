import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles, TextField, FormHelperText } from '@material-ui/core';
import { Field, FieldProps } from 'react-final-form';
import { TextFieldProps } from '@material-ui/core/TextField';

interface IInputProps {
  inputProps?: TextFieldProps;
}

const styles = (theme) =>
  createStyles({
    root: {},

    input: {
      marginTop: theme.spacing(2),

      '& .MuiFormLabel-root': {
        color: theme.palette.secondary.contrastText,
      },

      '& .MuiInput-underline:before': {
        borderBottomColor: '#E0E0E0',
      },

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: theme.palette.primary.main,
      },

      '& .MuiInputBase-root': {
        lineHeight: 'normal',
      },
    },
  });

const Input = ({
  className,
  classes,
  name,
  disabled,
  label,
  inputProps = {},
  fullWidth = true,
  type = 'text',
  changeProxy = (value) => value,
  multiline = false,
  onFocus,
  fixedValue,
  helperText,
  ...rest
}: FieldProps<any, any> & IInputProps) => (
  <Field
    name={name}
    render={({ input: { name, onChange, value }, meta: { touched, error } }) => {
      const isError = !!(touched && error);

      const handleChange = (event) => {
        if (rest.onChange) {
          rest.onChange(changeProxy(event.target.value));
        } else {
          onChange(changeProxy(event.target.value));
        }
      };

      return (
        <div className={classNames(classes.root, className)}>
          {multiline ? (
            <TextField
              className={classes.input}
              name={name}
              type={type}
              label={label}
              value={fixedValue ?? value}
              onChange={handleChange}
              disabled={disabled}
              error={isError}
              fullWidth={fullWidth}
              multiline
              onFocus={onFocus}
              helperText={helperText}
              {...inputProps}
            />
          ) : (
            <TextField
              className={classes.input}
              name={name}
              type={type}
              label={label}
              value={fixedValue ?? value}
              onChange={handleChange}
              disabled={disabled}
              error={isError}
              fullWidth={fullWidth}
              onFocus={onFocus}
              helperText={helperText}
              {...inputProps}
            />
          )}
          {isError && <FormHelperText error>{error}</FormHelperText>}
        </div>
      );
    }}
    {...rest}
  />
);

export default withStyles(styles)(Input);
