import { Avatar, Chip, Grow, makeStyles } from '@material-ui/core';
import { get, omit } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters as actionSetFilters } from 'store/actions/filtersActions';

import { useGetMany } from 'react-admin';

const inventoryFilters: Record<string, string> = {
  IN_STOCK: 'In stock',
  LOW_STOCK: 'Low stock',
  OUT_OF_STOCK: 'Out of stock',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  STOCK_UNINITIALIZED: 'Stock not available',
};

const FilterChip = (props) => (
  <Grow in>
    <Chip style={{ margin: '4px 8px 4px 0' }} variant="outlined" {...props} />
  </Grow>
);

const useReferenceChipProps = (chipProps) => {
  const { filterValues, filterName, setFilters, filters, resource } = chipProps;
  const dispatch = useDispatch();

  const reference = get(
    filters.find((f) => f.props.source === filterName),
    'props.reference'
  );
  const onDelete = (id) => {
    const newFiltersValue = {
      ...filterValues,
      [filterName]: filterValues[filterName].filter((e) => e !== id),
    };
    setFilters(newFiltersValue);
    dispatch(
      actionSetFilters({
        resource,
        filters: newFiltersValue,
      })
    );
  };

  const { data, error, loading } = useGetMany(reference, filterValues[filterName]);
  return [
    data?.filter((i) => i),
    ...(data && data.length === 1 && !data[0] ? [true] : [error]),
    loading,
    onDelete,
  ];
};

const MarketChip = (props) => {
  const [data, error, loading, onDelete] = useReferenceChipProps(props);
  if (error) return null;
  if (loading) return null;

  return data.map(
    (entity) =>
      entity && (
        <FilterChip
          key={entity.id}
          avatar={<Avatar alt={entity.provider.fullName} src={entity.provider.smallLogoUrl} />}
          label={entity.number}
          onDelete={() => onDelete(entity.id)}
        />
      )
  );
};

const ShippingChip = (props) => {
  const [data, error, loading, onDelete] = useReferenceChipProps(props);
  if (error) return null;
  if (loading) return null;

  return data.map(
    (entity) =>
      entity && (
        <FilterChip
          key={entity.id}
          avatar={<Avatar alt={entity.fullName} src={entity.logoUrl} />}
          label={entity.code}
          onDelete={() => onDelete(entity.id)}
        />
      )
  );
};

const StatusChip = (props) => {
  const [data, error, loading, onDelete] = useReferenceChipProps(props);
  const isGrouped = useSelector((store) => store.orders.isGrouped);
  if (isGrouped && props.resource !== 'shipments') return null;
  if (error) return null;
  if (loading) return null;

  return data.map((entity) => (
    <FilterChip key={entity.id} label={entity.name} onDelete={() => onDelete(entity.id)} />
  ));
};

const ServiceChip = (props) => {
  const [data, error, loading, onDelete] = useReferenceChipProps(props);
  if (error) return null;
  if (loading) return null;

  return data.map((entity) => (
    <FilterChip key={entity.id} label={entity.name} onDelete={() => onDelete(entity.id)} />
  ));
};

const DateChip = (props) => {
  const dispatch = useDispatch();
  const { filterName, filterValues, setFilters, resource } = props;
  const onDelete = () => {
    const newFiltersValue = omit(filterValues, [filterName]);
    setFilters(newFiltersValue);
    dispatch(
      actionSetFilters({
        resource,
        filters: newFiltersValue,
      })
    );
  };
  const from = get(filterValues, `${filterName}.from`);
  const to = get(filterValues, `${filterName}.to`);
  let label = `${from ? moment(from).format('L') : '*'} - ${to ? moment(to).format('L') : '*'}`;

  if (moment(from).format('L') === moment(to).format('L'))
    label = from ? moment(from).format('L') : 'All time';

  return <FilterChip label={label} onDelete={() => onDelete()} />;
};

const BooleanChip = (props) => {
  const { setFilters, filterName, filterValues, resource } = props;
  const dispatch = useDispatch();
  const onDelete = () => {
    const newFiltersValues = omit(filterValues, [filterName]);
    setFilters(newFiltersValues);
    dispatch(
      actionSetFilters({
        resource,
        filters: newFiltersValues,
      })
    );
  };

  return <FilterChip key={filterName} label={filterName} onDelete={onDelete} />;
};

const InventoryChip = (props) => {
  const { setFilters, filterName, filterValues, resource } = props;
  const dispatch = useDispatch();

  const onDelete = (id) => {
    const newFiltersValue = {
      ...filterValues,
      [filterName]: filterValues[filterName].filter((e) => e !== id),
    };
    setFilters(newFiltersValue);
    dispatch(
      actionSetFilters({
        resource,
        filters: newFiltersValue,
      })
    );
  };

  const data = filterValues[filterName];

  return data?.map((item) => (
    <FilterChip key={item} label={inventoryFilters[item]} onDelete={() => onDelete(item)} />
  ));
};

const ChipsSwitch = (props) => {
  const { filterName } = props;

  const CHIP_TYPES = {
    shipping: <ShippingChip {...props} />,
    account: <ShippingChip {...props} />,
    provider: <ShippingChip {...props} />,
    market: <MarketChip {...props} />,
    status: <StatusChip {...props} />,
    date: <DateChip {...props} />,
    manual: <BooleanChip {...props} />,
    'shipping-service': <ServiceChip {...props} />,
    inventoryStatus: <InventoryChip {...props} />,
    active: <InventoryChip {...props} />,
  };

  if (props.resource === 'inventory' && filterName === 'status') {
    return CHIP_TYPES.inventoryStatus;
  }

  return CHIP_TYPES[filterName] ?? null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2.5, 0, -0.5),
  },
}));

const FilterChips = (props) => {
  const { filterValues, classesOverride } = props;

  const filters = Object.keys(filterValues);
  const classes = useStyles();

  const inner = useMemo(
    () =>
      filters.map((filterName) => (
        <ChipsSwitch key={filterName} filterName={filterName} {...props} />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  if (!filters.length) {
    return null;
  }

  return <div className={classesOverride ? classesOverride : classes.root}>{inner}</div>;
};

export default FilterChips;
