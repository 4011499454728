export const getCalculatorAddressData = (data) => ({
  addressLine1: data.addressLine1,
  addressLine2: data.addressLine2,
  city: data.city,
  country: data.countryCode ?? data.stateOrProvince?.country?.code,
  stateProvinceCode: data.stateOrProvince?.code ?? data.stateProvinceCode,
  zipCode: data.postalCode ?? data.zipCode,
});

export const getBuyLabelAddressData = (data) => ({
  addressLine1: data.addressLine1,
  addressLine2: data.addressLine2,
  city: data.city,
  countryCode: data.countryCode ?? data.stateOrProvince?.country?.code,
  stateProvinceCode: data.stateOrProvince?.code ?? data.stateProvinceCode,
  zipCode: data.postalCode ?? data.zipCode,
});

export const getValidatorAddressData = (data) => ({
  addressLine1: data.addressLine1,
  addressLine2: data.addressLine2,
  city: data.city,
  countryCode: data.countryCode ?? data.stateOrProvince?.country?.code,
  stateProvinceCode: data.stateOrProvince?.code ?? data.stateProvinceCode,
  postalCode: data.postalCode ?? data.zipCode,
});

export const getAddressDataFromSuggestion = (data) => ({
  addressLine1: data.addressLine1,
  addressLine2: data.addressLine2,
  city: data.city,
  countryCode: data.countryCode ?? data.stateOrProvince?.country?.code,
  stateProvinceCode: data.stateProvince?.code ?? data.stateProvinceCode,
  zipCode: data.postalCode ?? data.zipCode,
});
