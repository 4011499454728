import { GET_LIST } from 'react-admin';

export default {
  [GET_LIST]: ({ response }) => ({
    ...response,
    json: response.json.map((item) => ({
      ...item,
      id: item.code,
    })),
  }),
};
