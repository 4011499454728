import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import { LabeledUrlField, ProviderField } from 'components/fields';

const useStyles = makeStyles({
  trackNumberContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  trackNumber: {
    fontSize: 14,
  },
  providerField: {
    minWidth: 240,
    alignItems: 'center',
  },
});

const TrackProviderField = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.providerField}>
      <Grid item xs={3}>
        <ProviderField
          record={props.record}
          imageSource="account.provider.logoUrl"
          source="account.provider.code"
          secondarySource="account.alias"
          hideLabel={true}
        />
      </Grid>
      <Grid item xs={9}>
        <Grid container direction="column">
          {props.record.service.code && (
            <Grid item xs={12}>
              <Typography
                component="div"
                variant="body1"
                color="textPrimary"
                data-test="primary"
                className={classes.trackNumber}
                dangerouslySetInnerHTML={{
                  __html: props.record.service.name?.replace('®', '<sup>&reg;</sup>'),
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.trackNumberContainer}>
            <LabeledUrlField
              className={classes.trackNumber}
              record={props.record}
              source="trackLink"
              labelSource="trackNumber"
              hasHelpText
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrackProviderField;
