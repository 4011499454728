import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Billing = (props) => (
  <SvgIcon {...props}>
    <path
      d="M15.699 5a1 1 0 01.759.35l5.143 6a1 1 0 010 1.3l-5.143 6a1 1 0 01-.76.35H3.159a1 1 0 01-1-1V6a1 1 0 011-1zm-.46 2H4.159v10h11.08l4.285-5zm-2.08 6a1 1 0 110 2h-6a1 1 0 110-2zm-4-4a1 1 0 110 2h-2a1 1 0 110-2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Billing;
