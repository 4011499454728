import React, { forwardRef } from 'react';
import { createStyles, withStyles, TextField } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      '& .MuiFormLabel-root, & .MuiInputBase-root': {
        color: theme.palette.text.primary,
        fontSize: 20,
        fontWeight: 'normal',

        '$error &': {
          color: theme.palette.error.main,
        },
      },

      '& .MuiFormLabel-root': {
        transform: 'translate(0, 16px) scale(1)',
      },

      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 4px) scale(0.7)',
        color: theme.palette.primary.main,
      },

      '& .MuiInputBase-root': {
        padding: 0,
      },

      '& .MuiInputBase-input': {
        padding: `${theme.spacing(2) - 1}px 0 !important`,

        '&::placeholder': {
          opacity: 1,
        },
      },

      '& .MuiInput-underline': {
        '&:before, &:after': {
          display: 'none',
        },
      },
    },

    recipientSelectorRoot: {
      '& .MuiFormLabel-root, & .MuiInputBase-root': {
        color: '#969AB3',

        '$error &': {
          color: theme.palette.error.main,
        },
      },

      '& .MuiInputLabel-formControl': {
        top: 24,
        position: 'relative',
      },

      '& .MuiInputBase-root': {
        padding: 0,
      },

      '& .MuiInputBase-input': {
        padding: `${theme.spacing(2) - 1}px 5px !important`,
        color: '#212121',

        '&::placeholder': {
          opacity: 1,
        },
      },

      '& .MuiInput-underline:before': {
        borderBottomColor: '#E0E0E0',
      },

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  });

const SuggestibleTextInput = forwardRef<any, any>(
  ({ classes, isRecipientSelector, ...rest }, ref) => (
    <TextField
      className={isRecipientSelector ? classes.recipientSelectorRoot : classes.root}
      fullWidth
      color="primary"
      ref={ref}
      {...rest}
    />
  )
);

export default withStyles(styles)(SuggestibleTextInput);
