import React, { useState } from 'react';
import classNames from 'classnames';
import { createStyles, Divider, IconButton, Popover, withStyles } from '@material-ui/core';
import { InfoOutlined, CloseOutlined } from '@material-ui/icons';
import { LabeledPriceField } from 'components/fields';

const styles = (theme) =>
  createStyles({
    infoButton: {
      color: theme.palette.secondary.contrastText,
      marginLeft: theme.spacing(1.5),
    },

    content: {
      padding: theme.spacing(2, 2, 3),
      width: theme.spacing(50),
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },

    title: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: 12,
    },

    closeButton: {
      color: theme.palette.secondary.contrastText,
      marginRight: theme.spacing(-1),
    },

    divider: {
      margin: theme.spacing(2, 0, 1.5),
    },

    primaryRow: {
      height: theme.spacing(4),
    },

    primaryPrice: {
      fontSize: 20,
    },

    crossedOut: {
      textDecoration: 'line-through',
      fontSize: 16,
    },

    secondaryRow: {
      marginTop: theme.spacing(2),
      alignItems: 'flex-start',
    },

    secondaryLabel: {
      maxWidth: theme.spacing(40),
      textTransform: 'capitalize',
    },

    totalPrice: {},
  });

const PriceDetails = ({
  classes,
  title,
  primaries,
  secondaries,
  total,
}: {
  classes: any;
  title?: React.ReactNode;
  primaries: {
    description: string;
    cost: number | string;
    crossedOut?: boolean;
    isDisplayString?: boolean;
  }[];
  secondaries?: { description: string; cost: number | string }[];
  total?: {
    description: string;
    cost: number | string;
    isDisplayString?: boolean;
  };
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton className={classes.infoButton} size="small" onClick={handleOpen}>
        <InfoOutlined />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <div className={classes.content}>
          <div className={classes.header}>
            {title && <div className={classes.title}>{title}</div>}
            <IconButton className={classes.closeButton} size="small" onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </div>
          {primaries.map((item) => (
            <LabeledPriceField
              key={item.description}
              classes={{
                root: classes.primaryRow,
                price: item.crossedOut ? classes.crossedOut : classes.primaryPrice,
              }}
              record={item}
              source="cost"
              label={item.description.toLocaleLowerCase()}
            />
          ))}
          {secondaries && secondaries.length && (
            <>
              <Divider className={classes.divider} />
              {secondaries.map((item) => (
                <LabeledPriceField
                  key={item.description}
                  classes={{
                    root: classes.secondaryRow,
                    label: classes.secondaryLabel,
                  }}
                  record={item}
                  source="cost"
                  label={item.description.toLocaleLowerCase()}
                  labelVariant="body2"
                />
              ))}
            </>
          )}
          {total && (
            <>
              <Divider className={classes.divider} />
              <LabeledPriceField
                key={total.description}
                classes={{
                  root: classes.primaryRow,
                  price: classNames(classes.primaryPrice, classes.totalPrice),
                }}
                record={total}
                source="cost"
                label={total.description.toLocaleLowerCase()}
              />
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default withStyles(styles)(PriceDetails);
