import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { WizardSteps } from './index';
import {
  createStyles,
  withStyles,
  Button,
  Grid,
  Dialog as MUIDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import SubmitButton from 'components/buttons/SubmitButton';
import FormErrorLabel from './FormErrorLabel';
import { CloseOutlined } from '@material-ui/icons';

const styles = (theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      minWidth: 0,
      right: theme.spacing(2.5),
      top: theme.spacing(3.25),
      color: theme.palette.secondary.contrastText,
    },

    title: {
      padding: theme.spacing(4),
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },

    steps: {
      padding: theme.spacing(0, 4, 5),
    },

    content: {
      padding: theme.spacing(0, 4, 2),
      minWidth: theme.spacing(50),
      minHeight: theme.spacing(8),
      overflowX: 'hidden',
    },

    error: {
      padding: theme.spacing(2, 4, 0),
    },

    actions: {
      padding: theme.spacing(4),

      '& .MuiButton-containedSecondary': {
        backgroundColor: theme.palette.secondary.light,

        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
  });

const WizardDialog = ({
  classes,
  open,
  initialValues = {},
  initialValuesEqual,
  title,
  common = <></>,
  steps,
  stepProps,
  onClose,
  onSubmit,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const isLastStep = currentStep === steps.length - 1;

  const goToPreviousStep = () => setCurrentStep((index) => index - 1);
  const goToNextStep = () => setCurrentStep((index) => index + 1);

  const handleSubmitForm = (values) => {
    if (!isLastStep) {
      goToNextStep();
      return;
    }

    return onSubmit(values).catch((err) => ({ [FORM_ERROR]: err.message }));
  };

  const Page = steps[currentStep].component;

  return (
    <MUIDialog open={open} onClose={onClose}>
      <Button className={classes.closeButton} onClick={onClose}>
        <CloseOutlined />
      </Button>
      <Form
        initialValues={initialValues}
        initialValuesEqual={initialValuesEqual}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, submitting, values, ...renderProps }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <DialogTitle className={classes.title}>{title}</DialogTitle>

            <WizardSteps
              classes={{ root: classes.steps }}
              steps={steps}
              currentStep={currentStep}
              onSelect={setCurrentStep}
            />

            <DialogContent className={classes.content}>
              {common}
              <Page values={values} {...stepProps} />
            </DialogContent>

            <FormErrorLabel className={classes.error} {...renderProps} />

            <DialogActions className={classes.actions}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Button
                    className={classes.secondaryButton}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={currentStep === 0}
                    onClick={goToPreviousStep}>
                    Previous
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <SubmitButton
                    fullWidth
                    color="primary"
                    size="large"
                    submitting={submitting}
                    label={isLastStep ? 'Submit' : 'Next'}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      />
    </MUIDialog>
  );
};

export default withStyles(styles)(WizardDialog);
