import React, { cloneElement } from 'react';
import classNames from 'classnames';
import { createStyles, Typography, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    group: {
      paddingBottom: theme.spacing(5),
    },

    lastGroup: {},

    groupTitle: {
      display: 'flex',
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(1.5, 3),
      margin: theme.spacing(0, -3),
      borderRadius: theme.spacing(2),
    },

    groupTitleIcon: {
      color: theme.palette.primary.main,
      verticalAlign: 'middle',
      marginRight: theme.spacing(2),
    },

    groupContent: {
      display: 'flex',
      flexWrap: 'wrap',
      width: `calc(100% + ${theme.spacing(4)}px)`,
      margin: theme.spacing(0, -2),
      boxSizing: 'border-box',
    },

    groupItem: {
      flex: '0 0 50%',
      maxWidth: '50%',
      padding: theme.spacing(0, 2),
      boxSizing: 'border-box',
    },

    groupItemWide: {
      flex: '0 0 100%',
      maxWidth: '100%',
      padding: theme.spacing(0, 2),
      boxSizing: 'border-box',
    },
  });

const withClasses = withStyles(styles);

export const Group = withClasses(({ classes, last, ...props }: any) => (
  <div
    {...props}
    className={classNames(last ? classes.lastGroup : classes.group, props.className)}
  />
));

export const GroupTitle = withClasses(({ classes, icon, children, ...props }: any) => (
  <Typography
    component="div"
    variant="overline"
    {...props}
    className={classNames(classes.groupTitle, props.className)}>
    {icon && cloneElement(icon, { className: classes.groupTitleIcon })}
    {children}
  </Typography>
));

export const GroupContent = withClasses(({ classes, ...props }: any) => (
  <div {...props} className={classNames(classes.groupContent, props.className)} />
));

export const GroupItem = withClasses(({ classes, wide, ...props }: any) => (
  <div
    {...props}
    className={classNames(wide ? classes.groupItemWide : classes.groupItem, props.className)}
  />
));
