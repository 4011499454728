import moment from 'moment';

const storage: Storage = window.localStorage;

export const getDefaultShipmentDate = (shipmentDate?: string): string => {
  const today = moment().format('YYYY-MM-DD');
  const lastShipmentDate = storage.getItem('shipmentDate') ?? today;
  try {
    const isLastBeforeToday = moment(lastShipmentDate, 'YYYY-MM-DD').isBefore(
      moment(today, 'YYYY-MM-DD')
    );
    const isCurrentBeforeToday = moment(shipmentDate, 'YYYY-MM-DD').isBefore(
      moment(today, 'YYYY-MM-DD')
    );
    const isLastBeforeCurrent = moment(lastShipmentDate, 'YYYY-MM-DD').isBefore(
      moment(shipmentDate, 'YYYY-MM-DD')
    );
    if (isLastBeforeToday) {
      storage.removeItem('shipmentDate');
    }

    if (!shipmentDate) {
      return isLastBeforeToday ? today : lastShipmentDate;
    }

    return !isLastBeforeToday && !isLastBeforeCurrent
      ? lastShipmentDate
      : isCurrentBeforeToday
      ? today
      : shipmentDate;
  } catch {
    return today;
  }
};

export const setDefaultShipmentDate = (shipmentDate?: string): void => {
  const today = moment().format('YYYY-MM-DD');

  try {
    const isNewBeforeToday = moment(shipmentDate ?? today, 'YYYY-MM-DD').isBefore(
      moment(today, 'YYYY-MM-DD')
    );
    storage.setItem('shipmentDate', !isNewBeforeToday ? shipmentDate ?? today : today);
  } catch {}
};
