import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import React, { ReactElement, forwardRef } from 'react';
import { ButtonProps } from '@material-ui/core/Button';

interface SubmitButtonProps extends ButtonProps {
  submitting?: boolean;
  label?: string;
  progress?: ReactElement;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}));

const SubmitButton = (
  {
    submitting = false,
    disabled = false,
    label = 'Submit',
    progress = <CircularProgress size={24} />,
    size = 'large',
    ...rest
  }: SubmitButtonProps,
  ref: any
) => {
  const classes = useStyles();

  return (
    <Button
      classes={classes}
      variant="contained"
      color="primary"
      size={size}
      type="submit"
      fullWidth
      disabled={submitting || disabled}
      ref={ref}
      {...rest}>
      {!submitting && label}
      {submitting && progress}
    </Button>
  );
};

export default forwardRef(SubmitButton);
