import React, { Children, cloneElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { getListControllerProps, useDataProvider } from 'react-admin';
import { Card, makeStyles } from '@material-ui/core';

import { ActionToolbar } from 'components/common';
import Pagination from '../pagination/Pagination';
import Actions from './Actions';
import BulkActions from './BulkActions';

const useStyles = makeStyles((theme) => ({
  root: {},

  loadingContainer: {
    textAlign: 'center',
  },
  short: {
    margin: '0 auto',
    maxWidth: theme.spacing(172),
  },

  main: {
    display: 'flex',
  },

  content: {
    marginTop: 0,
    transition: theme.transitions.create('margin-top'),
    position: 'relative',
    overflow: 'visible',
    flex: '1 1 auto',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    },
  },

  emptyContent: {
    padding: theme.spacing(2),
  },

  actions: {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },

  noResults: {
    padding: 20,
  },
}));
// @ts-ignore
const ListView = ({
  className,
  children,
  filters,
  initialFilters,
  bulkActions,
  actions = <Actions />,
  pagination = <Pagination />, //eslint-disable-line
  emptyMessage = <>No results found</>,
  welcomeMessage,
  filterDefaultValues,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dataProvider = useDataProvider();

  const controllerProps = getListControllerProps(props);

  const [ordersExist, setOrdersExist] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { loaded, ids, total } = controllerProps;

  useEffect(() => {
    dataProvider
      .get('checkOrders')
      .then(({ data }) => setOrdersExist(data))
      .finally(() => setLoading(false));
  }, [dataProvider]);

  const isEmpty = loaded && (ids.length === 0 || total === 0);
  const isWelcome = !total && !('q' in controllerProps.filterValues) && !ordersExist;

  useEffect(() => {
    if (history.action === 'POP') return;
    const { setPage } = props;
    setPage(1);
  }, []); //eslint-disable-line

  useEffect(() => {
    const { setFilters } = props;
    if (filterDefaultValues) setFilters(filterDefaultValues);
  }, []); //eslint-disable-line

  return (
    <div className={classNames(className, classes.root, props.short && classes.short)}>
      {(filters || actions) && (
        <ActionToolbar
          left={
            filters &&
            React.cloneElement(Children.only(filters), {
              ...controllerProps,
              ...filters.props,
              filterDefaultValues,
              initialFilters,
            })
          }>
          {Children.map(actions, (child) =>
            React.cloneElement(child, {
              ...controllerProps,
              ...child.props,
            })
          )}
        </ActionToolbar>
      )}
      <div className={classes.main}>
        <Card
          className={classNames(classes.content, isEmpty && classes.emptyContent)}
          key={props.version}>
          {bulkActions && <BulkActions {...controllerProps}>{bulkActions}</BulkActions>}
          {children &&
            cloneElement(Children.only(children), {
              ...controllerProps,
              hasBulkActions: !!bulkActions,
            })}
          {!loading &&
            (isWelcome
              ? welcomeMessage
              : isEmpty
              ? emptyMessage
              : pagination && cloneElement(pagination, controllerProps))}
        </Card>
      </div>
    </div>
  );
};

export default ListView;
