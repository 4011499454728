import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles, Typography } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    primary: {},

    secondary: {},

    sizeSmall: {
      height: theme.spacing(5.5),
    },

    sizeMedium: {
      height: theme.spacing(6),
    },

    sizeBig: {
      height: theme.spacing(7),

      '& $primary': {
        fontSize: 20,
        lineHeight: '32px',
        fontWeight: 'bold',
      },
    },

    sizeRefresh: {
      height: theme.spacing(6),
      minWidth: theme.spacing(26),
    },

    noSize: {},
  });

const TwoLinesLabel = ({
  classes,
  className,
  primary,
  secondary,
  autoSize,
  small,
  big,
  refresh,
  ...rest
}) => {
  let sizeClass = classes.sizeMedium;

  switch (true) {
    case autoSize:
      sizeClass = '';
      break;

    case small:
      sizeClass = classes.sizeSmall;
      break;

    case big:
      sizeClass = classes.sizeBig;
      break;

    case refresh:
      sizeClass = classes.sizeRefresh;
      break;
  }

  return (
    <div className={classNames(classes.root, sizeClass, className)} {...rest}>
      <Typography
        className={classes.primary}
        component="div"
        variant="body1"
        color="textPrimary"
        align={rest.align}
        data-test="primary">
        {primary}
      </Typography>
      <Typography
        className={classes.secondary}
        component="div"
        variant="body2"
        color="textSecondary"
        align={rest.align}
        data-test="secondary">
        {secondary}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(TwoLinesLabel);
