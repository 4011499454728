import React, { useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { Typography, IconButton, Tabs, Tab } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Form } from 'react-final-form';
import { get } from 'lodash';
import Dialog from 'components/common/Dialog';

import FormRightColumn from './FormRightColumn';
import FormLeftColumn from './FormLeftColumn';
import BuyerForm from '../BuyerForm';
import useCustomNotify from 'hooks/useCustomNotify';
import { useCreatePreset } from 'pages/warehousePresets/utils';

const RateEditField = (props) => {
  const dataProvider = useDataProvider();
  const createPreset = useCreatePreset();
  const notify = useCustomNotify();
  const {
    initData,
    packageTypes,
    record,
    onSubmit,
    isLoading,
    disabled,
    updateError,
    setUpdateError,
    deliveryTimes,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [recipient, setRecipient] = useState<any>({});
  const [isDateSet, setIsDateSet] = useState(false);

  const handleOpen = () => {
    setIsDateSet(false);
    setCurrentTab(0);
    dataProvider.getOne('orders', { id: record.orderId }).then(({ data }) => setRecipient(data));
    setIsOpen(true);
    setUpdateError('');
  };

  const handleClose = () => {
    setUpdateError('');
    setIsOpen(false);
  };

  const handleTabChange = useCallback((e, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleSubmit = (data) => {
    setUpdateError(false);
    const { packageInfo, isPackageSave, packageName } = data;
    if (isPackageSave) {
      const packageType = packageTypes.find((item) => item.code === packageInfo[0].type);
      return createPreset({
        packageName,
        packageInfo: packageInfo[0],
        packageType,
      })
        .then(() => {
          notify('Preset was saved', 'success');
          return onSubmit(data, record.orderId, recipient);
        })
        .then(() => handleClose())
        .catch((error) => {
          setUpdateError(error.message);
        });
    }
    onSubmit(data, record.orderId, recipient)
      .then(() => handleClose())
      .catch((error) => {
        setUpdateError(error.message);
      });
  };

  return (
    <>
      <IconButton
        {...props}
        onClick={handleOpen}
        disabled={record.isShipped || isLoading || disabled}>
        <Edit color="disabled" />
      </IconButton>

      <Form
        initialValues={{ ...initData[`${record.orderId}`], ...recipient, isPackageSave: false }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, form, values, ...renderProps }) => {
          const packageType = packageTypes?.find(
            (i) => i.code === get(values, 'packageInfo[0].type')
          );

          if (packageType) {
            packageType.length && form.change('packageInfo[0].size.length', packageType.length);
            packageType.width && form.change('packageInfo[0].size.width', packageType.width);
            packageType.height && form.change('packageInfo[0].size.height', packageType.height);
          }

          if (!!initData?.shipmentDate && !isDateSet) {
            form.change('shipmentDate', initData?.shipmentDate);
            setTimeout(() => {
              setIsDateSet(true);
            }, 100);
          }

          const onTooltipClick = (e) => {
            e.preventDefault();
          };

          return (
            <form onSubmit={handleSubmit}>
              <Dialog
                open={isOpen}
                confirmText={'Save'}
                title={
                  <>
                    <div>Edit</div>
                    <Typography color="textSecondary">
                      Order No. {record.marketplaceOrSellerOrderId}
                    </Typography>
                    <Tabs
                      value={currentTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary">
                      <Tab label="Shipping" />
                      <Tab label="Recipient" />
                    </Tabs>
                  </>
                }
                error={!!updateError && `Save failed. ${updateError}`}
                onCancel={handleClose}
                onConfirm={handleSubmit}>
                <div hidden={currentTab !== 0}>
                  <FormLeftColumn
                    form={form}
                    values={values}
                    packageType={packageType}
                    isAdjustForm={true}
                    onTooltipClick={onTooltipClick}
                    deliveryTimes={deliveryTimes}
                    {...props}
                  />
                  <FormRightColumn form={form} values={values} isAdjustForm={true} {...props} />
                </div>
                <div hidden={currentTab !== 1}>
                  <BuyerForm {...props} form={form} values={values} isAdjustForm={true} />
                </div>
              </Dialog>
            </form>
          );
        }}
      />
    </>
  );
};

export default RateEditField;
