/* eslint-disable react-hooks/rules-of-hooks */
import { useDataProvider } from 'react-admin';

const useCreatePreset = () => {
  const dataProvider = useDataProvider();

  const preparePresetData = ({ packageName, packageInfo, packageType }) => {
    const {
      size: { height, length, width },
      weight: { oz: weightOz, lb: weightLb },
    } = packageInfo;

    return {
      name: packageName,
      height,
      length,
      width,
      packageType,
      weightOz,
      weightLb,
    };
  };

  const createPreset = (data) => {
    return dataProvider.create('shippingPresets', { data: preparePresetData(data) });
  };
  return createPreset;
};

export default useCreatePreset;
