import React, { useEffect, useMemo, useState } from 'react';
import { useDataProvider, useRefresh } from 'react-admin';
import { useHistory, Link } from 'react-router-dom';
import {
  makeStyles,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Popover,
  Typography,
} from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import RefreshStoreField from 'components/fields/RefreshStoreField';
import { TwoLinesLabel, SellingChannelStatus } from 'components/common';
import ActionButton from './ActionButton';
import useCustomNotify from 'hooks/useCustomNotify';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: theme.spacing(40),
  },

  header: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: theme.spacing(10),
  },

  list: {
    maxHeight: theme.spacing(60),
    overflow: 'auto',
    padding: 0,
  },

  item: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 10, 2, 3),
  },
  noChannels: {
    padding: theme.spacing(2),
  },
  noChannelsConnect: {
    marginTop: theme.spacing(2),
  },
}));

const MarketItem = ({ market, onUpdate, handleClose }) => {
  const classes = useStyles();

  const handleClick = () => {
    onUpdate(market.id);
  };

  const isUpdating =
    market.lastRefreshProcess && market.lastRefreshProcess.status === 'IN_PROGRESS';

  return (
    <ListItem className={classes.item}>
      <TwoLinesLabel
        primary={
          <RefreshStoreField
            record={market}
            labelSource="number"
            logoSource="provider.smallLogoUrl"
          />
        }
        secondary={<SellingChannelStatus market={market} closePopupCb={handleClose} />}
        refresh
      />
      <ListItemSecondaryAction>
        <IconButton disabled={isUpdating} onClick={handleClick}>
          {isUpdating ? <CircularProgress size={24} /> : <RefreshOutlined />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const MarketRefresher = ({ handleClose }) => {
  const classes = useStyles();

  const dataProvider = useDataProvider();
  const notify = useCustomNotify();
  const refresh = useRefresh();
  const { location } = useHistory();

  const [updatingInitiated, setUpdatingInitiated] = useState(false);
  const [markets, setMarkets] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const areMarketsUpdating = () => {
    return (
      markets &&
      markets.some(
        (market) => market.lastRefreshProcess && market.lastRefreshProcess.status === 'IN_PROGRESS'
      )
    );
  };

  const updateList = () => {
    setLoading(true);
    dataProvider
      .getList('sellingChannels', { pagination: { perPage: 999 }, filter: { manual: true } })
      .then(({ data }) => {
        setMarkets(data);
      })
      .catch((err) => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const handleUpdate = (id) => {
    setUpdatingInitiated(true);

    setMarkets((markets) => {
      if (!markets) {
        return null;
      }

      return markets.map((market) =>
        market.id === id
          ? {
              ...market,
              lastRefreshProcess: { status: 'IN_PROGRESS' },
            }
          : market
      );
    });

    dataProvider
      .post('refreshMarketOrders', { id })
      .catch((err) => {
        notify(err.message, 'error');
      })
      .finally(() => {
        updateList();
      });
  };

  const handleUpdateAll = () => {
    setUpdatingInitiated(true);

    setMarkets((markets) => {
      if (!markets) {
        return null;
      }

      return markets.map((market) => ({
        ...market,
        lastRefreshProcess: { status: 'IN_PROGRESS' },
      }));
    });

    dataProvider
      .post('refreshAllOrders', { data: { marketAccountIds: [] } })
      .catch((err) => {
        notify(err.message, 'error');
      })
      .finally(() => {
        updateList();
      });
  };

  useEffect(() => {
    updateList();
  }, []); //eslint-disable-line

  const updating = !!areMarketsUpdating();

  useEffect(() => {
    if (updating) {
      const timer = setTimeout(() => {
        updateList();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [markets]); //eslint-disable-line

  useEffect(() => {
    if (location.pathname === '/orders' && updatingInitiated && !updating) {
      refresh();
    }
  }, [updatingInitiated, updating]); //eslint-disable-line

  const filtredMarkets = useMemo(
    () =>
      markets
        ? markets.filter((market) => market.active && !market.disabled && market.provider.visible)
        : null,
    [markets]
  );

  const noMarkets = useMemo(() => filtredMarkets && filtredMarkets.length === 0, [filtredMarkets]);

  return (
    <div className={classes.container}>
      {!noMarkets && loaded && (
        <div className={classes.header}>
          <Button
            fullWidth
            color="primary"
            size="medium"
            variant="contained"
            onClick={handleUpdateAll}>
            Update all markets
          </Button>
        </div>
      )}
      <div className={classes.content}>
        {loading && !loaded && <CircularProgress />}
        <>
          {!noMarkets ? (
            <List className={classes.list}>
              {filtredMarkets &&
                filtredMarkets.map((market) => (
                  <MarketItem
                    key={market.id}
                    market={market}
                    onUpdate={handleUpdate}
                    handleClose={handleClose}
                  />
                ))}
            </List>
          ) : (
            <div className={classes.noChannels}>
              <Typography variant="body1">Connect your selling channels</Typography>
              <Typography variant="body2" color="textSecondary">
                Rollo will automatically prepare your open orders for shipping.
              </Typography>
              <Button
                fullWidth
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleClose}
                to="/sellingChannels"
                component={Link}
                className={classes.noChannelsConnect}>
                Connect
              </Button>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

const MarketRefreshButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ActionButton onClick={handleOpen}>
        <RefreshOutlined />
      </ActionButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}>
        <MarketRefresher handleClose={handleClose} />
      </Popover>
    </>
  );
};

export default MarketRefreshButton;
