import { GET_LIST, GET_MANY } from 'react-admin';

const getDeliveryProvidersEnum = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.map((item) => ({
    ...item,
    id: item.code,
  })),
});

export default {
  [GET_LIST]: getDeliveryProvidersEnum,
  [GET_MANY]: getDeliveryProvidersEnum,
};
