import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RedirectActionButton from './RedirectActionButton';

const styles = (theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      marginLeft: theme.spacing(-2),
    },
  });

const BackActionButton = ({
  classes,
  to,
  basePath = '',
  id,
  data,
  ...rest
}: {
  classes: any;
  to?: string;
  basePath?: string;
  id?: string | number;
  data?: any;
} & any) => {
  const history = useHistory();

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const optionalProps = useMemo(() => {
    const oProps: { onClick?: () => void } = {};
    if (!to) oProps.onClick = onBack;
    return oProps;
  }, [to, onBack]);

  return (
    <RedirectActionButton
      classes={classes}
      basePath={basePath}
      id={id}
      to={to}
      data={data}
      color="default"
      variant="text"
      {...rest}
      {...optionalProps}>
      <KeyboardArrowLeftIcon />
    </RedirectActionButton>
  );
};

export default withStyles(styles)(BackActionButton);
