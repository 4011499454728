import React from 'react';
import { Field, FieldProps } from 'react-final-form';
import { makeStyles, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { SwitchProps } from '@material-ui/core/Switch';
import classNames from 'classnames';

import { Switch as SwitchField } from 'components/common';

interface SwitchPropsInterface {
  inputProps?: SwitchProps;
  classes?: Record<string, string>;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '72px',
  },
  root: {
    height: '100%',
    justifyContent: 'flex-end',
  },
  label: {
    margin: 0,
    color: '#757575',
  },
  switch: {
    marginRight: theme.spacing(2),
  },
}));

const Switch = (props: FieldProps<any, any> & SwitchPropsInterface) => {
  const switchClasses = useStyles();

  const { classes, name, disabled, label, inputProps = {}, fullWidth = true, ...rest } = props;

  return (
    <div className={classNames(switchClasses.wrapper, classes?.wrapper)}>
      <Field
        type="checkbox"
        name={name}
        render={({
          input: { name, onBlur, onChange, onFocus, value },
          meta: { touched, error },
        }) => {
          const handleChange = (event) => {
            if (rest.onChange) {
              rest.onChange(event.target.checked);
            } else {
              onChange(event);
            }
          };

          return (
            <FormControl className={switchClasses.root} fullWidth={fullWidth}>
              <FormControlLabel
                className={switchClasses.label}
                control={
                  <SwitchField
                    color="primary"
                    name={name}
                    onChange={handleChange}
                    checked={!!value}
                    classes={{ root: classNames(switchClasses.switch, classes?.switch) }}
                    {...inputProps}
                  />
                }
                label={label}
              />
              {touched && error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
          );
        }}
        {...rest}
      />
    </div>
  );
};

export default Switch;
