import React from 'react';
import { createStyles, withStyles, Link as MUILink, Typography } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 4, 2),
    },

    row: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),

      '& > :not(:last-child)': {
        marginRight: theme.spacing(4),
      },
    },

    copyright: {
      color: theme.palette.primary.light,
    },

    link: {
      fontWeight: 'bold',
      color: theme.palette.secondary.contrastText,
    },

    buildNumber: {
      color: theme.palette.primary.light,
    },
  });

const Footer = ({ classes, ...props }) => (
  <footer className={classes.root}>
    <div className={classes.row}>
      <MUILink
        className={classes.link}
        color="textSecondary"
        variant="body2"
        href="https://www.rollo.com/terms-of-service/"
        target="_blank">
        Terms of Service
      </MUILink>
      <MUILink
        className={classes.link}
        color="textSecondary"
        variant="body2"
        href="https://www.rollo.com/privacy-policy/"
        target="_blank">
        Privacy Policy
      </MUILink>
      <MUILink
        className={classes.link}
        color="textSecondary"
        variant="body2"
        href="https://support.rollo.com/"
        target="_blank">
        Contact Support
      </MUILink>
    </div>
    <div className={classes.row}>
      <Typography className={classes.copyright} color="textSecondary" variant="body2">
        Copyright &copy; 2019&ndash;{new Date().getFullYear()} Nelu LLC
      </Typography>
    </div>
    <div className={classes.row}>
      <Typography className={classes.copyright} color="textSecondary" variant="body2">
        {process.env.REACT_APP_MIN_VERSION} {process.env.REACT_APP_BUILD_DATE}
      </Typography>
    </div>
  </footer>
);

export default withStyles(styles)(Footer);
