export const SET_FILTERS = 'Rollo/SET_FILTERS';

export interface IPayload {
  resource: string;
  filters: Filters | DateFilter;
}

export interface Filters {
  market?: string[];
  date?: {
    from: string;
    to: string;
  };
  status?: string[];
  q?: string;
  accounts?: string[];
}

export interface DateFilter {
  market?: string[];
  date?: string;
  status?: string[];
  q?: string;
  accounts?: string[];
}

export const setFilters = (payload: IPayload) => {
  return {
    type: SET_FILTERS,
    payload,
  };
};
