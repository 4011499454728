import React from 'react';
import { Field, FieldProps } from 'react-final-form';
import {
  createStyles,
  withStyles,
  Select as MUISelect,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { ExpandMoreOutlined } from '@material-ui/icons';

export interface OptionInterface {
  value: any;
  label: string;
}

export interface ISelectProps {
  inputProps?: SelectProps;
}

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      minWidth: theme.spacing(20),
    },

    label: {
      color: theme.palette.secondary.contrastText,
    },

    select: {
      '&:before': {
        borderBottomColor: '#E0E0E0',
      },

      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: theme.palette.primary.main,
      },

      '& .MuiSelect-select': {
        backgroundColor: 'transparent',
        padding: '8px 0 13px',
      },

      '& .MuiSelect-icon': {
        top: theme.spacing(0.5),
        color: theme.palette.primary.light,
      },
    },
  });

const SelectBase = ({
  classes,
  name,
  disabled,
  label,
  inputProps = {},
  fullWidth = true,
  children,
  helperText = '',
  ...rest
}: FieldProps<any, any> & ISelectProps) => (
  <Field
    name={name}
    render={({ input: { name, onChange, value }, meta: { touched, error } }) => {
      const handleChange = (event) => {
        if (rest.onChange) {
          rest.onChange(event.target.value);
        } else {
          onChange(event.target.value);
        }
      };

      return (
        <FormControl className={classes.root} fullWidth={fullWidth}>
          <InputLabel className={classes.label}>{label}</InputLabel>
          <MUISelect
            className={classes.select}
            IconComponent={ExpandMoreOutlined}
            value={value}
            error={touched && !!error}
            disabled={disabled}
            onChange={handleChange}
            inputProps={{
              name: name,
            }}
            {...inputProps}>
            {children}
          </MUISelect>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {touched && error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      );
    }}
    {...rest}
  />
);

export default withStyles(styles)(SelectBase);
