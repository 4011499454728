import React, { memo, useState } from 'react';
import { makeStyles, Typography, Checkbox } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { RadioButtonUnchecked, CheckCircle } from '@material-ui/icons';

import { Dialog } from 'components/common';
import { ReactComponent as Van } from '../../assets/rolloDelivery/Van.svg';
import { ReactComponent as Pricing } from '../../assets/rolloDelivery/Pricing.svg';
import { ReactComponent as Tracking } from '../../assets/rolloDelivery/Tracking.svg';
import { ReactComponent as Delivery } from '../../assets/rolloDelivery/Delivery.svg';
import { ReactComponent as Accountability } from '../../assets/rolloDelivery/Accountability.svg';

const usePromoStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 3, 3),
    borderRadius: 16,
  },
  navButton: {
    color: theme.palette.primary.main,
    opacity: 0.3,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 0.6,
    },
  },
  activeNavButton: {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  carouselItem: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    height: 350,
    minWidth: 505,
  },
  title: {
    fontWeight: 700,
    fontSize: 21,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 15,
  },
  content: {
    padding: 0,
    margin: theme.spacing(0, 3),
    minWidth: 500,
    boxSizing: 'border-box',
    borderRadius: 16,
  },
  actions: {
    padding: theme.spacing(3, 4, 2, 4),
    '& .MuiButton-root': {
      textTransform: 'none',
      fontWeight: 700,
    },
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      width: 22,
      height: 22,
    },
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  note: {
    padding: theme.spacing(0, 0, 2, 0),
  },
}));

const RolloDeliveryPromo = ({
  open,
  onCancel,
  onConfirm,
  submitting,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  submitting?: boolean;
}) => {
  const classes = usePromoStyles();

  const [checked, setChecked] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleConfirm = async () => {
    try {
      if (checked) {
        localStorage.setItem('promoRememberedChoice', 'ROLLO');
      }
      return await onConfirm();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCancel = () => {
    if (checked) {
      localStorage.setItem('promoRememberedChoice', 'ALL_CARRIERS');
    }
    onCancel();
  };

  return (
    <Dialog
      open={open}
      onCancel={handleCancel}
      onClose={onCancel}
      title="Being a local has its perks!"
      onConfirm={handleConfirm}
      confirmText="Give me Rollo delivery"
      cancelText="Show all carriers options"
      submitting={submitting}
      error={error}
      classes={{
        content: classes.content,
        actions: classes.actions,
        note: classes.note,
      }}
      note={
        <div className={classes.checkboxContainer}>
          <Checkbox
            icon={<RadioButtonUnchecked />}
            checkedIcon={<CheckCircle color="primary" />}
            checked={checked}
            onChange={() => setChecked((prev) => !prev)}
            className={classes.checkbox}
            disableTouchRipple
            disableRipple
          />
          <Typography>Remember my choice</Typography>
        </div>
      }>
      <Carousel
        navButtonsAlwaysInvisible={true}
        indicatorIconButtonProps={{
          className: classes.navButton,
        }}
        activeIndicatorIconButtonProps={{
          className: classes.activeNavButton,
        }}
        interval={6000}
        className={classes.root}>
        <div className={classes.carouselItem}>
          <Van />
          <Typography className={classes.title}>Free pick ups</Typography>
          <Typography className={classes.text}>
            Rollo will pick up your package for free. No need to drop off or wait in line!
            <br />
            Our drivers are friendly and awesome. Max 35 lbs per package.
          </Typography>
        </div>
        <div className={classes.carouselItem}>
          <Pricing />
          <Typography className={classes.title}>Flat rate pricing</Typography>
          <Typography className={classes.text}>
            No need to measure dimensions or weight. As long as your
            <br />
            package can be picked up by one person and under 35 lbs, it is $5.
          </Typography>
        </div>
        <div className={classes.carouselItem}>
          <Tracking />
          <Typography className={classes.title}>Real time GPS tracking</Typography>
          <Typography className={classes.text}>
            You will know exactly where your package is at all times. No
            <br />
            generic statuses. No wishing your package gets delivered.
          </Typography>
        </div>
        <div className={classes.carouselItem}>
          <Delivery />
          <Typography className={classes.title}>Same day delivery</Typography>
          <Typography className={classes.text}>
            Create your shipment before noon for same day delivery.
          </Typography>
        </div>
        <div className={classes.carouselItem}>
          <Accountability />
          <Typography className={classes.title}>Accountability</Typography>
          <Typography className={classes.text}>
            Communicate with your Rollo delivery person and rate your
            <br />
            experience. Plus! Receive photo confirmation of delivery.
          </Typography>
        </div>
      </Carousel>
    </Dialog>
  );
};

export default memo(RolloDeliveryPromo);
