import fakeRestProvider from 'ra-data-fakerest';
import Auth from '@aws-amplify/auth';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import { omit } from 'lodash';
import apiRest, { GET, POST, PUT, POST_FORM_DATA, PUT_FORM_DATA } from './rest';
import profileProvider, { CHANGE_PASSWORD } from './profileProvider';
import localstorage from 'services/data/localstorage';
import fetchJson from './fetchJson';

const apiUrl = process.env.REACT_APP_DATA_PROVIDER_URL;
const contactsUrl = process.env.REACT_APP_CONTACTS_PROVIDER_URL;

const getBlackbox = () => {
  const { ioGetBlackbox } = window as any;

  if (typeof ioGetBlackbox !== 'function') {
    return;
  }

  const bbData = ioGetBlackbox();

  if (bbData.finished) {
    return bbData.blackbox;
  }
};

const binaryFetch = (url, options) => {
  if (options.user && options.user.authenticated && options.user.token) {
    options.headers.set('Authorization', options.user.token);
  }

  return fetch(url, options).then((response) => {
    const { status, statusText, headers } = response;

    if (status < 200 || status >= 300) {
      return response.text().then((text) => {
        let json;
        try {
          json = JSON.parse(text);
        } catch (e) {}

        return Promise.reject({
          message: json?.message || text || statusText,
          status,
          json,
        });
      });
    }

    return response.blob().then((blob) => ({
      status,
      headers,
      blob,
    }));
  });
};

export const httpClient = (url, options) =>
  Auth.currentSession().then((aws) => {
    const idToken = aws['idToken'];

    options.user = {
      authenticated: true,
      token: `Bearer ${idToken.jwtToken}`,
    };

    options.headers = new Headers({
      Accept: 'application/json',
      'X-UPS-Device-Id': getBlackbox(),
      ...options.headers,
    });

    switch (options.headers.get('Accept')) {
      case 'application/json':
      case 'text/html':
        return fetchJson(url, options);

      default:
        return binaryFetch(url, options);
    }
  });

const localDefaultParams = {
  pagination: {
    page: 1,
    perPage: 100,
  },
  sort: {
    field: 'id',
    order: 'ASC',
  },
};

const typeToMethodMap = {
  GET_ONE: 'getOne',
  GET_NEST: 'getNest',
  GET_LIST: 'getList',
  GET_MANY: 'getMany',
  GET_MANY_REFERENCE: 'getManyReference',
  CREATE: 'create',
  UPDATE: 'update',
  UPDATE_MANY: 'updateMany',
  DELETE: 'delete',
  DELETE_MANY: 'deleteMany',
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  CHANGE_PASSWORD: 'changePassword',
  GET_IDS: 'getIds',
};

const fakeData = require('./db.json');
const fakeRest = fakeRestProvider(fakeData);

const providers = {
  local: (type, resource, params) =>
    type === 'GET_ONE'
      ? new Promise((resolve) => {
          setTimeout(() => {
            if (resource === 'inventory') {
              resolve({ data: fakeData['inventoryOne'] });
            }

            resolve({ data: fakeData[resource].find((item) => item.id === params.id) });
          }, 1000);
        })
      : fakeRest(type, resource, omit(params, ['filter'])),
  stubRequest: () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1);
    }),
  liveApi: apiRest(apiUrl, httpClient),
  contactsApi: apiRest(contactsUrl, httpClient),
  publicFiles: apiRest(window.location.origin, httpClient),
  profileProvider,
  localstorage: (type, resource, params) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(localstorage(type, resource, params));
      }, 60);
    }),
};

class RestAdapter {
  constructor(public switchProvider: Function) {}

  getList = (resource, params) => this.switchProvider(GET_LIST, resource, params);
  getOne = (resource, params) => this.switchProvider(GET_ONE, resource, params);
  get = (resource, params) => this.switchProvider(GET, resource, params);
  getNest = (resource, params) => this.switchProvider('GET_NEST', resource, params);
  post = (resource, params) => this.switchProvider(POST, resource, params);
  postFormData = (resource, params) => this.switchProvider(POST_FORM_DATA, resource, params);
  put = (resource, params) => this.switchProvider(PUT, resource, params);
  getMany = (resource, params) => this.switchProvider(GET_MANY, resource, params);
  getManyReference = (resource, params) =>
    this.switchProvider(GET_MANY_REFERENCE, resource, params);
  create = (resource, params) => this.switchProvider(CREATE, resource, params);
  update = (resource, params) => this.switchProvider(UPDATE, resource, params);
  updateMany = (resource, params) => this.switchProvider(UPDATE_MANY, resource, params);
  delete = (resource, params) => this.switchProvider(DELETE, resource, params);
  deleteMany = (resource, params) => this.switchProvider(DELETE_MANY, resource, params);
  changePassword = (resource, params) => this.switchProvider(CHANGE_PASSWORD, resource, params);
  getIds = (resource, params) => this.switchProvider('GET_IDS', resource, params);
  putFormData = (resource, params) => this.switchProvider(PUT_FORM_DATA, resource, params);
}

const dataProvider = new RestAdapter((type, resource, params) => {
  switch (resource) {
    case 'shipmentTypes':
    case 'paymentStatuses':
      // case 'inventory':
      return providers.local(type, resource, { ...localDefaultParams, ...params });
    case 'recipientsSearch':
    case 'contactsSearch':
      return !!contactsUrl
        ? providers.contactsApi(type, resource, params)
        : providers.stubRequest();
    case 'contactsBrowse':
      return !!contactsUrl
        ? providers.contactsApi(type, resource, params)
        : providers.stubRequest();
    case 'recipients':
    case 'contacts':
      return !!contactsUrl
        ? providers.contactsApi(type, resource, params)
        : providers.stubRequest();
    case 'contactsCSV':
      return !!contactsUrl
        ? providers.contactsApi(type, resource, params)
        : providers.stubRequest();
    case 'recentContacts':
      return !!contactsUrl
        ? providers.contactsApi(type, resource, params)
        : providers.stubRequest();
    case 'contactUsed':
      return !!contactsUrl
        ? providers.contactsApi(type, resource, params)
        : providers.stubRequest();
    case 'profile':
      return providers.profileProvider(type, resource, params);
    case 'tableSettings':
    case 'rowsPerPageSettings':
      return providers.localstorage(type, resource, params);
    case 'help':
    case 'helpIndex':
      return providers.publicFiles(type, resource, params);
    case 'scanForms':
      return providers.liveApi(type, resource, { filter: params.filter });
    case 'scanFormsWarehouses':
    case 'scanFormsProviders':
      if (type === 'GET_MANY' && params?.ids?.length) return providers.stubRequest();
      else return providers.liveApi(type, resource, params);
    case 'infoStateCity':
      if (params.countryId !== 'US') return providers.stubRequest();
      else return providers.liveApi(type, resource, params);
    default:
      return providers.liveApi(type, resource, params);
  }
});

export default new Proxy(dataProvider, {
  get: (target: RestAdapter, property: string | number | symbol, receiver: any): any =>
    Reflect.get(target, target[property] ? property : typeToMethodMap[property], receiver),
});
