const formatPrice = (price: string | number, options?) => {
  if (typeof price === 'string' && /^\$/.test(price)) {
    return price;
  }

  const value = typeof price === 'string' ? parseFloat(price) : price;
  return Number.isFinite(value)
    ? value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        ...options,
      })
    : '$0.00';
};

export default formatPrice;
