import { SvgIcon } from '@material-ui/core';
import React from 'react';

const Columns = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12,5 L12,19.22 L17,19.22 L17,12 L19,12 L19,19 C19,20.05 18.1799587,20.9177686 17.1486946,20.9944872 L17,21 L5,21 C3.95,21 3.0822314,20.1799587 3.00551277,19.1486946 L3,19 L3,7 C3,5.95 3.82004132,5.0822314 4.85130541,5.00551277 L5,5 L12,5 Z M10,7 L5,7 L5,19.22 L10,19.22 L10,7 Z M19,2 L19,5 L22,5 L22,7 L19,7 L19,9.99 C19,9.99 17.01,10 17,9.99 L17,7 L14,7 C14,7 14.01,5.01 14,5 L17,5 L17,2 L19,2 Z"
      fillRule="evenodd"
      fill="currentColor"></path>
  </SvgIcon>
);

export default Columns;
