import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Delete = (props) => (
  <SvgIcon {...props}>
    <path
      d="M18 7v13a1 1 0 01-1 1H7a1 1 0 01-1-1V7a1 1 0 01-1-1V5a1 1 0 011-1h3.333l.37-.555A1 1 0 0110.535 3h2.93a1 1 0 01.832.445l.37.555H18a1 1 0 011 1v1a1 1 0 01-1 1zM8 7v12h8V7zm2.5 2c.276 0 .5.199.5.444v7.112c0 .245-.224.444-.5.444s-.5-.199-.5-.444V9.444c0-.245.224-.444.5-.444zm3 0c.276 0 .5.199.5.444v7.112c0 .245-.224.444-.5.444s-.5-.199-.5-.444V9.444c0-.245.224-.444.5-.444z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Delete;
