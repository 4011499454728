import { LogoField } from 'components/fields/index';
import React from 'react';
import { get } from 'lodash';
import { createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 0,
    },
  });

interface IStoreFieldProps {
  record?;
  logoSource?;
  labelSource?;
  source?;
  sortBy?;
  label?;
  classes?;
}

const truncate = (value) => {
  if (value === undefined) return '';
  if (value.length <= 15) return value;
  else return value.substr(0, 15) + '...';
};

const RefreshStoreField = ({ record, logoSource, labelSource, classes }: IStoreFieldProps) => (
  <div className={classes.root} data-test="store">
    <LogoField record={record} source={logoSource} />
    {truncate(get(record, labelSource))}
  </div>
);

export default withStyles(styles)(RefreshStoreField);
