import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { useQuery } from 'react-admin';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import ToolButton from 'components/buttons/ToolButton';
import ShippingPresetDialog from './ShippingPresetDialog';
import { useWindowSize } from '../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(3, 0, 1.5, 0),
    justifyContent: 'flex-start',
  },

  button: {
    marginRight: theme.spacing(1),
    width: 'fit-content',
    maxWidth: '220px',
    minWidth: '100px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    justifyContent: 'flex-start',

    '& span': {
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
      textTransform: 'none',
      fontSize: 13,
    },
  },

  active: {
    color: `${theme.palette.primary.main} !important`,
    padding: theme.spacing(0.5, 2),
  },
}));

const ShippingPresetChooser = ({ name, country }: { name: string; country?: string }) => {
  const classes = useStyles();

  const refContainer = useRef(null);
  const size = useWindowSize(refContainer);

  const { loading, data } = useQuery({
    type: 'get',
    resource: 'popularShippingPresets',
    payload: { amount: 100, dstCountry: country },
  });

  const {
    input: { value, onChange },
  } = useField(name);

  const customPreset = useMemo(
    () => ({
      id: null,
      name: 'Custom',
      type: 'PACKAGE',
      size: {
        width: null,
        height: null,
        length: null,
      },
      weight: {
        lb: null,
        oz: null,
      },
      flatRate: false,
    }),
    []
  );

  const [initialPresets, setInitialPresets] = useState<[]>([]);
  const [presets, setPresets] = useState<[]>([]);
  const [quickPresets, setQuickPresets] = useState<any[]>([customPreset]);

  useEffect(() => {
    if (!loading && data && data.length) {
      loadPresets(data);
    }
  }, [loading, data]);

  const loadPresets = (presetsList) => {
    const presets = presetsList.map((item) => ({
      id: item.id,
      name: item.name,
      type: item.packageType.code,
      size: {
        width: item.width,
        height: item.height,
        length: item.length,
      },
      weight: {
        lb: item.weightLb,
        oz: item.weightOz,
      },
      flatRate: item.packageType.flatRate,
    }));

    setPresets(presets);
    setInitialPresets(presets);
  };

  useLayoutEffect(() => {
    if (initialPresets.length) {
      const middleButtonSize = 160;

      // Width using only for the initial render
      const width = refContainer?.current?.offsetWidth ?? 0;

      const amountQuickPresets = Math.max(
        Math.floor((size || width - middleButtonSize) / middleButtonSize),
        2
      );

      const newQuickPresets = initialPresets.slice(0, amountQuickPresets);
      const existingPreset = initialPresets.find((preset) => preset.id === value.id);
      if (
        value?.id &&
        !newQuickPresets.some((preset) => preset.id === value.id) &&
        existingPreset
      ) {
        setQuickPresets([
          customPreset,
          existingPreset,
          ...newQuickPresets.slice(0, amountQuickPresets - 1),
        ]);
      } else {
        if (!existingPreset) value.id = null;
        setQuickPresets([customPreset, ...newQuickPresets]);
      }
    }
  }, [customPreset, initialPresets, size, value]);

  const handleChange = (selectedPreset) => () => {
    onChange({
      ...value,
      ...selectedPreset,
    });
  };

  const isQuickPreset = () => quickPresets.some((preset) => preset.id === value.id);

  return (
    <>
      <div className={classes.container} ref={refContainer}>
        {quickPresets.map((preset) => {
          const isActive = preset.id === value.id;
          return (
            <ToolButton
              key={preset.id ?? preset.name}
              onClick={handleChange(preset)}
              className={classNames(classes.button, isActive && classes.active)}>
              {preset.name}
            </ToolButton>
          );
        })}
        {quickPresets.length >= 3 && (
          <ShippingPresetDialog
            initialPresets={initialPresets}
            presets={presets}
            setPresets={setPresets}
            onChange={onChange}
            fieldValue={value}
            isQuickPreset={isQuickPreset}
          />
        )}
      </div>
    </>
  );
};

export default ShippingPresetChooser;
