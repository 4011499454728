import React, { memo } from 'react';
import { SvgIcon } from '@material-ui/core';

const NoInternetConnection = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4999 15C13.0519 15 13.4999 15.448 13.4999 16C13.4999 16.552 13.0519 17 12.4999 17C11.9479 17 11.4999 16.552 11.4999 16C11.4999 15.448 11.9479 15 12.4999 15ZM15.3539 13.7703C15.5489 13.9663 15.5489 14.2823 15.3539 14.4783C15.1579 14.6733 14.8419 14.6733 14.6459 14.4783C13.3419 13.1733 11.6579 13.1733 10.3539 14.4783C10.1579 14.6733 9.84188 14.6733 9.64588 14.4783C9.45088 14.2823 9.45088 13.9663 9.64588 13.7703C11.3419 12.0753 13.6579 12.0753 15.3539 13.7703ZM16.8898 11.8123C17.0628 12.0273 17.0278 12.3423 16.8118 12.5143C16.5968 12.6873 16.2818 12.6523 16.1098 12.4363C14.3098 10.1873 10.6898 10.1873 8.88982 12.4363C8.71782 12.6523 8.40282 12.6873 8.18782 12.5143C7.97182 12.3423 7.93682 12.0273 8.10982 11.8123C10.3098 9.06127 14.6898 9.06127 16.8898 11.8123ZM18.3799 9.79975C18.5589 10.0087 18.5349 10.3247 18.3249 10.5048C18.1159 10.6837 17.7999 10.6597 17.6199 10.4497C14.8199 7.18275 10.1799 7.18275 7.37988 10.4497C7.19988 10.6597 6.88388 10.6837 6.67488 10.5048C6.46488 10.3247 6.44088 10.0087 6.61988 9.79975C9.81988 6.06675 15.1799 6.06675 18.3799 9.79975Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2ZM19.2078 5.99902L6.49939 18.7074C8.09174 20.1329 10.1947 20.9997 12.5 20.9997C17.4706 20.9997 21.5 16.9702 21.5 11.9997C21.5 9.69429 20.6332 7.59137 19.2078 5.99902ZM3.5 12C3.5 7.02944 7.52944 3 12.5 3C14.8054 3 16.9083 3.8668 18.5006 5.29226L5.79226 18.0006C4.3668 16.4083 3.5 14.3054 3.5 12Z"
      fill="white"
    />
  </SvgIcon>
);

export default memo(NoInternetConnection);
