let lbTimerId;
let ozTimerId;

export const lbWeightConversion = (form, lbField: string, ozField: string) => (value) => {
  const lb = parseFloat(value);

  if (isNaN(lb)) {
    form.change(lbField, '');
    form.change(ozField, '');
    return;
  }

  const restLb = lb - Math.floor(lb);
  const restOz = restLb * 16;

  form.change(lbField, value);

  if (Number.isInteger(lb)) return;

  if (lbTimerId) {
    clearTimeout(lbTimerId);
  }
  lbTimerId = setTimeout(() => {
    form.change(lbField, Math.floor(value));
    if (restOz) {
      form.change(ozField, restOz.toFixed(2));
    }
    return;
  }, 1000);
};

export const ozWeightConversion = (form, lbField: string, ozField: string) => (value) => {
  const oz = parseFloat(value);

  if (isNaN(oz)) {
    form.change(ozField, '');
    clearTimeout(ozTimerId);
    return;
  }

  form.change(ozField, value);

  if (ozTimerId) {
    clearTimeout(ozTimerId);
  }
  ozTimerId = setTimeout(() => {
    if (oz > 15.99) {
      const lb = Math.floor(oz / 16);
      const restOz = oz - lb * 16;
      form.change(lbField, Math.floor(lb));
      form.change(ozField, Number(restOz.toFixed(1)));
      return;
    } else {
      form.change(ozField, Number(oz.toFixed(1)));
      return;
    }
  }, 1000);
};

export const formatWeight = (lb: number | string, oz: number | string) => {
  return {
    lb: parseFloat(Number(lb).toFixed(2)),
    oz: parseFloat(Number(oz).toFixed(2)),
  };
};
