import React, { forwardRef } from 'react';
import { get } from 'lodash';
import { MenuItem } from '@material-ui/core';
import usePackingSlip from 'hooks/usePackingSlip';
import ActionButton from 'components/buttons/ActionButton';

const PrintPackingSlipButton = ({ data }) => {
  const { openingDocument, openDocument } = usePackingSlip('orders', get(data, 'id'));

  return (
    <ActionButton disabled={openingDocument} onClick={openDocument}>
      Print Packing Slip
    </ActionButton>
  );
};

const PrintPackingSlipActionField = forwardRef((props: any, ref: any) => {
  const { openDocument } = usePackingSlip('orders', get(props.record, 'id'));

  return (
    <MenuItem ref={ref} onClick={openDocument}>
      Print packing slip
    </MenuItem>
  );
});

export { PrintPackingSlipButton, PrintPackingSlipActionField };
