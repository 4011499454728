import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#dfffe6',
    color: '#24c746',
  },
  voided: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
    color: '#69696B',
  },
  refund: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#F6ECF8',
    color: '#BB24C7',
  },
}));

const RateStatusField = (props) => {
  const { record } = props;
  const classes = useStyles();
  if (record.voidedLabel) {
    return (
      <Chip
        className={classNames(record.voidedLabel === 'Voided' ? classes.voided : classes.refund)}
        label={record.voidedLabel}
      />
    );
  }
  return (
    <Chip className={classes.chip} label={record.isShipped ? 'Shipped' : 'Awaiting Shipment'} />
  );
};

export default RateStatusField;
