import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Swap = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 15.5C14.9477 15.5 14.5 15.0523 14.5 14.5L14.5 6.91421L13.2071 8.20711C12.8166 8.59763 12.1834 8.59763 11.7929 8.20711C11.4024 7.81658 11.4024 7.18342 11.7929 6.79289L14.7929 3.79289C14.9789 3.60689 15.2316 3.5 15.5 3.5C15.635 3.5 15.767 3.52702 15.8821 3.57577C16.0006 3.62369 16.1129 3.69867 16.2071 3.79289L19.2071 6.79289C19.5976 7.18342 19.5976 7.81658 19.2071 8.20711C18.8166 8.59763 18.1834 8.59763 17.7929 8.20711L16.5 6.91421L16.5 14.5C16.5 15.0523 16.0523 15.5 15.5 15.5ZM7.5 20.5C7.23161 20.5 6.97889 20.3931 6.79289 20.2071L3.79289 17.2071C3.40237 16.8166 3.40237 16.1834 3.79289 15.7929C4.18342 15.4024 4.81658 15.4024 5.20711 15.7929L6.5 17.0858L6.5 8.5C6.5 7.94772 6.94771 7.5 7.5 7.5C8.05229 7.5 8.5 7.94772 8.5 8.5L8.5 17.0858L9.79289 15.7929C10.1834 15.4024 10.8166 15.4024 11.2071 15.7929C11.5976 16.1834 11.5976 16.8166 11.2071 17.2071L8.20711 20.2071C8.11289 20.3013 8.00059 20.3763 7.8894 20.4212C7.767 20.473 7.63495 20.5 7.5 20.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Swap;
