import { SvgIcon } from '@material-ui/core';
import React from 'react';

const Company = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 21C4.44772 21 4 20.5523 4 20V6C4 5.44772 4.44772 5 5 5H13C13.5523 5 14 5.44772 14 6V11H19C19.5523 11 20 11.4477 20 12V20C20 20.5523 19.5523 21 19 21H5ZM13 6H5V20H13V6ZM19 12H14V20H19V12ZM17.5 17C17.7761 17 18 17.2239 18 17.5C18 17.7761 17.7761 18 17.5 18H16.5C16.2239 18 16 17.7761 16 17.5C16 17.2239 16.2239 17 16.5 17H17.5ZM17.5 14C17.7761 14 18 14.2239 18 14.5C18 14.7761 17.7761 15 17.5 15H16.5C16.2239 15 16 14.7761 16 14.5C16 14.2239 16.2239 14 16.5 14H17.5ZM14.5 17C14.7761 17 15 17.2239 15 17.5C15 17.7761 14.7761 18 14.5 18H14V17H14.5ZM14.5 14C14.7761 14 15 14.2239 15 14.5C15 14.7761 14.7761 15 14.5 15H14V14H14.5ZM7.5 8C7.77614 8 8 8.22386 8 8.5C8 8.77614 7.77614 9 7.5 9H6.5C6.22386 9 6 8.77614 6 8.5C6 8.22386 6.22386 8 6.5 8H7.5ZM11.5 8C11.7761 8 12 8.22386 12 8.5C12 8.77614 11.7761 9 11.5 9H10.5C10.2239 9 10 8.77614 10 8.5C10 8.22386 10.2239 8 10.5 8H11.5ZM7.5 11C7.77614 11 8 11.2239 8 11.5C8 11.7761 7.77614 12 7.5 12H6.5C6.22386 12 6 11.7761 6 11.5C6 11.2239 6.22386 11 6.5 11H7.5ZM11.5 11C11.7761 11 12 11.2239 12 11.5C12 11.7761 11.7761 12 11.5 12H10.5C10.2239 12 10 11.7761 10 11.5C10 11.2239 10.2239 11 10.5 11H11.5ZM7.5 14C7.77614 14 8 14.2239 8 14.5C8 14.7761 7.77614 15 7.5 15H6.5C6.22386 15 6 14.7761 6 14.5C6 14.2239 6.22386 14 6.5 14H7.5ZM11.5 14C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H10.5C10.2239 15 10 14.7761 10 14.5C10 14.2239 10.2239 14 10.5 14H11.5ZM7.5 17C7.77614 17 8 17.2239 8 17.5C8 17.7761 7.77614 18 7.5 18H6.5C6.22386 18 6 17.7761 6 17.5C6 17.2239 6.22386 17 6.5 17H7.5ZM11.5 17C11.7761 17 12 17.2239 12 17.5C12 17.7761 11.7761 18 11.5 18H10.5C10.2239 18 10 17.7761 10 17.5C10 17.2239 10.2239 17 10.5 17H11.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Company;
