const isInternationalShipment = (shippingData, returnLabel) => {
  if (!returnLabel) {
    const {
      from: { countryCode: fromCountryCode },
      to: {
        address: { countryCode: toCountryCode },
      },
    } = shippingData;
    return (
      !!fromCountryCode && !!toCountryCode && (fromCountryCode !== 'US' || toCountryCode !== 'US')
    );
  }
  const {
    from: {
      address: { countryCode: fromCountryCode },
    },
    to: { countryCode: toCountryCode },
  } = shippingData;

  return (
    !!fromCountryCode && !!toCountryCode && (fromCountryCode !== 'US' || toCountryCode !== 'US')
  );
};

export default isInternationalShipment;
