import React, { memo, useState } from 'react';
import { Dialog } from 'components/common';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';

const ReprintAlertDialog = (props) => {
  const { open, printLabel, setOpenAlert } = props;

  const [hideAlert, setHideAlert] = useState<boolean>(false);

  const onCancel = () => {
    setOpenAlert(false);
  };

  const onConfirm = () => {
    if (hideAlert) localStorage.setItem('hideReprintAlert', JSON.stringify(hideAlert));
    onCancel();
    printLabel();
  };

  return (
    <Dialog open={open} title="Info" onCancel={onCancel} confirmText="Ok" onConfirm={onConfirm}>
      <Typography>
        Shipping labels should only be used once! Using the same shipping label on multiple packages
        will result in additional charges and penalties by the carriers.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            onClick={() => setHideAlert((prev) => !prev)}
            checked={hideAlert}
          />
        }
        label="Hide this notice for future re-prints"
      />
    </Dialog>
  );
};

export default memo(ReprintAlertDialog);
