import { useDispatch } from 'react-redux';
import { closeRefill, openRefill } from 'store/actions/refillActions';

const useRefillDialog = () => {
  const dispatch = useDispatch();

  return (openSingleTab?: boolean) =>
    new Promise((resolve, reject) => {
      dispatch(
        openRefill((err, res) => {
          dispatch(closeRefill());

          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        }, openSingleTab)
      );
    });
};

export default useRefillDialog;
