import { CREATE } from 'react-admin';

export default {
  [CREATE]: ({ response, url, options, httpClient, params, apiUrl }) => ({
    ...response,
    json: {
      rates: response.json,
    },
  }),
};
