import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ToLocation = (props) => (
  <SvgIcon {...props}>
    <path
      d="M11.955 20.537c0 .001.003.004.008.006.03.018.073.008.082-.006l5.348-8.246a5.492 5.492 0 00.899-3.008c0-3.21-2.803-5.838-6.292-5.838S5.708 6.074 5.708 9.283c0 1.076.314 2.106.9 3.008zM12 7.445c1.119 0 2.042.886 2.042 2 0 1.114-.923 2-2.042 2-1.119 0-2.042-.886-2.042-2 0-1.114.923-2 2.042-2z"
      fillRule="nonzero"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
    />
  </SvgIcon>
);

export default ToLocation;
