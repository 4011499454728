import React from 'react';
import { useCreateController } from 'ra-core';
import { useDataProvider } from 'react-admin';
import { Dialog } from '@material-ui/core';

import WarehousePresetForm from './WarehousePresetForm';

const WarehousePresetCreateDialog = (props) => {
  const { open, onSubmitted, onClose } = props;

  const dataProvider = useDataProvider();

  const controllerProps = useCreateController({
    ...props,
    resource: 'warehousePresets',
    undoable: false,
  });

  const handleSaveWarehouse = async (data) => {
    return await dataProvider.create('warehousePresets', { data });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <WarehousePresetForm
        {...props}
        {...controllerProps}
        redirect={null}
        onSubmitted={onSubmitted}
        xs={12}
        onBackClick={onClose}
        save={handleSaveWarehouse}
      />
    </Dialog>
  );
};

export default WarehousePresetCreateDialog;
