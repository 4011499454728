import React from 'react';
import { DateField as RADateField } from 'react-admin';
import { get } from 'lodash';
import { TwoLinesLabel } from '../common';

const DateField = ({ twoLines = false, dateOnly = false, ...props }) => {
  const { record, source, locales } = props;

  if (!record) {
    return null;
  }

  if (twoLines) {
    const value = get(record, source);

    if (value == null) {
      return null;
    }

    const date = value instanceof Date ? value : new Date(value);

    return (
      <TwoLinesLabel
        primary={date.toLocaleDateString(locales, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
        secondary={
          dateOnly
            ? ''
            : date.toLocaleTimeString(locales, { hour: 'numeric', minute: 'numeric', hour12: true })
        }
      />
    );
  }

  return (
    <RADateField
      variant="body1"
      options={
        dateOnly
          ? { year: 'numeric', month: 'short', day: 'numeric' }
          : {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            }
      }
      {...props}
    />
  );
};

export default DateField;
