import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ShipDate = (props) => (
  <SvgIcon {...props}>
    <path
      d="M17,15 C18.1045695,15 19,15.8954305 19,17 L20,17 C21.1045695,17 22,17.8954305 22,19 L22,20.5 C22,20.7761424 21.7761424,21 21.5,21 L20.9143985,21.000703 C20.7082819,21.5829319 20.1528467,22 19.5,22 C18.8471533,22 18.2917181,21.5829319 18.0856015,21.000703 L14.9143985,21.000703 C14.7082819,21.5829319 14.1528467,22 13.5,22 C12.8471533,22 12.2917181,21.5829319 12.0856015,21.000703 L11.5,21 C11.2238576,21 11,20.7761424 11,20.5 L11,15.5 C11,15.2238576 11.2238576,15 11.5,15 L17,15 Z M12,2 C17.5228475,2 22,6.4771525 22,12 C22,12.6852782 21.9310698,13.3544568 21.7997646,14.0009807 L19.7478729,14.0003581 C19.9124613,13.3610104 20,12.6907281 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,15.7279108 6.54986283,18.8602692 10.000644,19.7481308 L10.0000191,21.7999676 C5.43552894,20.8734344 2,16.8379226 2,12 C2,6.4771525 6.4771525,2 12,2 Z M19.5,20 C19.2238576,20 19,20.2238576 19,20.5 C19,20.7761424 19.2238576,21 19.5,21 C19.7761424,21 20,20.7761424 20,20.5 C20,20.2238576 19.7761424,20 19.5,20 Z M13.5,20 C13.2238576,20 13,20.2238576 13,20.5 C13,20.7454599 13.1768752,20.9496084 13.4101244,20.9919443 L13.5,21 C13.7761424,21 14,20.7761424 14,20.5 C14,20.2545401 13.8231248,20.0503916 13.5898756,20.0080557 L13.5,20 Z M16,17 L14,17 C13.4477153,17 13,17.4477153 13,18 C13,18.5128358 13.3860402,18.9355072 13.8833789,18.9932723 L14,19 L16,19 C16.5522847,19 17,18.5522847 17,18 C17,17.4477153 16.5522847,17 16,17 Z M16.2426407,7.34314575 C16.633165,7.73367004 16.633165,8.36683502 16.2426407,8.75735931 L12.2928932,12.7071068 C12.1053568,12.8946432 11.8510029,13 11.5857864,13 L8,13 C7.44771525,13 7,12.5522847 7,12 C7,11.4477153 7.44771525,11 8,11 L10.7573593,11 C11.0225758,11 11.2769297,10.8946432 11.4644661,10.7071068 L14.8284271,7.34314575 C15.2189514,6.95262146 15.8521164,6.95262146 16.2426407,7.34314575 Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default ShipDate;
