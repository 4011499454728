import React from 'react';
import { SvgIcon } from '@material-ui/core';

const FromLocation = (props) => (
  <SvgIcon {...props}>
    <g id="icon/from">
      <g id="icon/from_2">
        <mask id="path-1-inside-1_3660_69112" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12ZM5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12Z"
          />
        </mask>
        <path
          d="M12 15C10.3431 15 9 13.6569 9 12H5C5 15.866 8.13401 19 12 19V15ZM15 12C15 13.6569 13.6569 15 12 15V19C15.866 19 19 15.866 19 12H15ZM12 9C13.6569 9 15 10.3431 15 12H19C19 8.13401 15.866 5 12 5V9ZM9 12C9 10.3431 10.3431 9 12 9V5C8.13401 5 5 8.13401 5 12H9ZM12 17C9.23858 17 7 14.7614 7 12H3C3 16.9706 7.02944 21 12 21V17ZM17 12C17 14.7614 14.7614 17 12 17V21C16.9706 21 21 16.9706 21 12H17ZM12 7C14.7614 7 17 9.23858 17 12H21C21 7.02944 16.9706 3 12 3V7ZM7 12C7 9.23858 9.23858 7 12 7V3C7.02944 3 3 7.02944 3 12H7Z"
          fill="#24C746"
          mask="url(#path-1-inside-1_3660_69112)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default FromLocation;
