import React from 'react';
import { createStyles, withStyles, Grid, Paper, IconButton } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Link, useLocation } from 'react-router-dom';

const styles = (theme) =>
  createStyles({
    header: {
      padding: theme.spacing(2),
    },

    title: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 'bold',
    },

    actions: {
      textAlign: 'right',
    },

    content: {
      padding: theme.spacing(0, 4, 4),
    },

    note: {
      padding: theme.spacing(2, 4, 4),
      fontSize: 14,
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },

    root: {},
  });

const FormCard = (props) => {
  const {
    classes,
    basePath,
    title,
    note,
    actions,
    children,
    xs = 6,
    onBackClick,
    redirect,
    isLink = true,
  } = props;

  const location = useLocation();
  const isEdit =
    location.state && location.state.isEdit && location.pathname !== '/sellingChannels/edit';

  return (
    <Grid container justify="center">
      <Grid item xs={xs}>
        <Paper className={classes.root}>
          <Grid container justify="space-between" alignItems="center" className={classes.header}>
            <Grid item xs={3}>
              {!isLink && onBackClick ? (
                <IconButton onClick={onBackClick}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={onBackClick}
                  component={Link}
                  to={
                    !!redirect
                      ? redirect
                      : !isEdit
                      ? basePath
                      : {
                          pathname: `${basePath}/edit`,
                          state: location.state,
                        }
                  }>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={6} className={classes.title}>
              {title}
            </Grid>
            <Grid item xs={3} className={classes.actions}>
              {actions}
            </Grid>
          </Grid>
          <div className={classes.content}>{children}</div>
          {note && <div className={classes.note}>{note}</div>}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FormCard);
