import React from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';

const TypeField = (props) => {
  const { record } = props;

  if (!record) {
    return null;
  }

  const name = get(record, 'name');

  return <Typography variant="body2">{name}</Typography>;
};

export default TypeField;
