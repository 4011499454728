import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Calendar = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.59251 3C7.01927 3 7.36523 3.34596 7.36523 3.77273V4.88867H16.6362V3.77273C16.6362 3.34596 16.9821 3 17.4089 3C17.8357 3 18.1816 3.34596 18.1816 3.77273V4.88867H18.9545C19.8081 4.88867 20.5 5.5806 20.5 6.43413V19.3986C20.5 20.2521 19.8081 20.944 18.9545 20.944H5.04545C4.19192 20.944 3.5 20.2521 3.5 19.3986V6.43413C3.5 5.5806 4.19192 4.88867 5.04545 4.88867H5.81978V3.77273C5.81978 3.34596 6.16574 3 6.59251 3ZM5.04545 6.43413H18.9545V9.61066H5.04545V6.43413ZM5.04545 11.1561V19.3986H18.9545V11.1561H5.04545Z"
    />
  </SvgIcon>
);

export default Calendar;
