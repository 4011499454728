import React, { memo, useEffect, useState, forwardRef, useMemo, Fragment } from 'react';
import {
  ReferenceArrayInput,
  ReferenceField,
  useDataProvider,
  useRedirect,
  useVersion,
} from 'react-admin';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, Grid, makeStyles, Button, Card, MenuItem } from '@material-ui/core';
import { keyBy, debounce, isEqual } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import StorefrontIcon from '@material-ui/icons/Storefront';
import classNames from 'classnames';

import { Datagrid } from 'components/list';
import FilterChips from 'components/list/FilterChips';
import BulkActions from '../../../../components/list/BulkActions';
import {
  ActionField,
  CustomerField,
  DateField,
  OrderIdField,
  PriceField,
  StatusField,
  StoreField,
} from 'components/fields';
import ChipsArrayInput from 'components/inputs/ChipsArrayInput';
import FilterDateInput from 'components/inputs/FilterDateInput';
import ColumnsConfigButton from 'components/layout/ColumnsConfigButton';
import { PrintPackingSlipActionField } from '../PackingSlip';
import { DeleteOrderActionField } from '../DeleteOrders';
import { MarkAsShippedActionField } from 'pages/orders/components/MarkAsShipped';
import BackActionButton from 'components/buttons/BackActionButton';
import { useSilentControllerProps } from 'hooks/useSilentControllerProps';
import OrderItems from 'icons/OrderItems';
import { BulkPrintPackingSlipsButton } from './BulkPrintPackingSlipsButton';
import useCustomNotify from 'hooks/useCustomNotify';
import { openVerification } from 'store/actions/verificationActions';

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    paddingLeft: theme.spacing(6.8),
    marginBottom: theme.spacing(1),
  },
  quantity: {
    fontSize: 14,
    lineHeight: '20px',
    fontStyle: 'normal',
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  sku: {
    fontSize: 14,
    lineHeight: '20px',
    marginLeft: theme.spacing(1.5),
  },
  name: {
    fontSize: 14,
    lineHeight: '20px',
    marginLeft: theme.spacing(2),
  },
  labelButton: {
    padding: theme.spacing(1, 2.5),
    lineHeight: '16px',
    border: '1px solid #C6C9E0',
    borderRadius: 22,
    fontSize: 12,
  },
  main: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  content: {
    marginTop: 0,
    transition: theme.transitions.create('margin-top'),
    position: 'relative',
    overflow: 'visible',
    flex: '1 1 auto',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    },
  },
  multipleButton: {
    padding: theme.spacing(1, 2.5),
    lineHeight: '16px',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  titleToolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  itemIcon: {
    width: 16,
    height: 16,
    paddingTop: 2,
  },
  pageTitle: {
    fontWeight: 'bold',
  },
  titleHidden: {
    margin: '12px 0 12px 54px',
  },
}));

const CreateLabelButtonField = ({ record }: { record? }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();

    const phoneVerified = JSON.parse(sessionStorage.getItem('phoneVerified') as string);
    if (!phoneVerified && typeof phoneVerified === 'boolean') {
      dispatch(openVerification());
      return;
    }

    redirect('list', `/orders/${record.id}/calculate`);
  };

  return (
    <Button
      className={classes.labelButton}
      color="secondary"
      variant="contained"
      size="small"
      onClick={handleClick}>
      Create&nbsp;label
    </Button>
  );
};

const EditActionField = forwardRef((props: any, ref: any) => {
  const { record, label = 'Show' } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push(`/orders/${record.id}/show`, { isFromGrouped: true });
  };

  return (
    <MenuItem ref={ref} onClick={handleClick}>
      {label}
    </MenuItem>
  );
});

export const GroupInfo = (props) => {
  const { group, title, resetShippingRates, basePath, hidden, to } = props;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.titleToolbar}>
        {!hidden &&
          (!!resetShippingRates ? (
            <BackActionButton onClick={resetShippingRates} to="list" basePath={basePath} />
          ) : (
            <BackActionButton basePath={basePath} to={to} id={group.id} />
          ))}
        <Typography className={classNames(classes.pageTitle, hidden && classes.titleHidden)}>
          {title}
        </Typography>
      </div>
      <Grid container className={classes.itemsContainer} alignItems="center">
        {group?.items?.map((item) => (
          <Fragment key={item.sku}>
            <Grid item>
              <OrderItems color="primary" className={classes.itemIcon} />
            </Grid>
            <Grid item style={{ borderRight: '1px solid #C6C9E0' }}>
              <Typography className={classes.quantity}>QTY: {item.quantity}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.sku} color="textSecondary">
                {item.sku}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.name}>{item.name}</Typography>
            </Grid>
            <Grid item style={{ width: '100%' }} />
          </Fragment>
        ))}
      </Grid>
    </div>
  );
};

const CreateMultipleLabels = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { selectedIds, currentId, orders } = props;

  const handleClick = () => {
    const phoneVerified = JSON.parse(sessionStorage.getItem('phoneVerified') as string);

    if (!phoneVerified && typeof phoneVerified === 'boolean') {
      dispatch(openVerification());
      return;
    }

    history.push({
      pathname: `/orders/groups/${currentId}/calculate`,
      state: {
        fromOrders: true,
      },
    });

    const selectedOrders = orders.filter((order) => selectedIds.includes(order.id));
    sessionStorage.setItem('selectedOrders', JSON.stringify(selectedOrders));
    sessionStorage.setItem('selectedIds', JSON.stringify(selectedIds));
  };
  return (
    <Button
      className={classes.multipleButton}
      {...props}
      color="primary"
      variant="contained"
      size="small"
      onClick={handleClick}>
      Create&nbsp;multiple&nbsp;labels
    </Button>
  );
};

const GroupedOrders = (props) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const version = useVersion();
  const history = useHistory();
  const notify = useCustomNotify();

  const perPage = 25;
  const { id } = useParams();

  const filterValues = useSelector(
    ({ filters }) => ({
      ...filters['orders'],
    }),
    shallowEqual
  );

  const controllerProps = useSilentControllerProps('orders');
  const { setFilters } = controllerProps;
  const { market, date } = filterValues;

  const withFilters = useMemo(() => market || date, []); // eslint-disable-line

  const [orders, setOrders] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [sort, setSort] = useState<{ field: string; order: string }>({
    field: 'purchaseDate',
    order: 'DESC',
  });
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [group, setGroup] = useState<any>({});
  const [groupIds, setGroupIds] = useState<any>([]);

  useEffect(() => {
    dataProvider
      .getNest('groupOrdersInfo', { id })
      .then(({ data }) => {
        setGroup(data);
      })
      .catch((error) => notify(error.message));
  }, [dataProvider, id, notify]);

  useEffect(() => {
    dataProvider
      .getIds('groupedOrdersIds', {
        id,
        'filter.market': market?.join(','),
        'filter.date.from': date?.from,
        'filter.date.to': date?.to,
      })
      .then(({ data }) => {
        setGroupIds(data);
        setSelectedIds(data);
      })
      .catch((error) => notify(error.message));
  }, [dataProvider, id, version, market, date, notify]);

  window.onscroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 400 &&
      page * perPage < currentTotal
    ) {
      setIsScrolled(true);
      setPage((prev) => prev + 1);
    }
  }, 500);

  useEffect(() => {
    dataProvider
      .getList('groups', {
        id,
        sort,
        pagination: {
          page,
          perPage,
        },
        filter: {
          market,
          date,
        },
      })
      .then(({ data, total }) => {
        if (total === 0) history.goBack();
        isScrolled ? setOrders((prev) => [...prev, ...data]) : setOrders(data);
        setIsScrolled(false);
        setCurrentTotal(total);
      })
      .catch((error) => notify(error.message));
  }, [page, sort, dataProvider, id, version, history, market, date]); //eslint-disable-line

  const filters = React.Children.toArray(
    <>
      <FilterDateInput icon={AccessTimeIcon} label="Date" source="date" allowEmpty />
      <ReferenceArrayInput
        icon={StorefrontIcon}
        basePath={props.basePath}
        label="Store"
        source="market"
        reference="sellingChannels"
        perPage={999}
        allowEmpty>
        <ChipsArrayInput
          optionValue="id"
          optionAvatar="provider.smallLogoUrl"
          optionLabel="number"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        icon={CheckCircleOutlineIcon}
        basePath={props.basePath}
        label="Status"
        source="status"
        reference="orderStatuses"
        allowEmpty>
        <ChipsArrayInput optionValue="status" optionLabel="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        icon={CheckCircleOutlineIcon}
        basePath={props.basePath}
        label="Shipping Service"
        source="shipping-service"
        reference="userServices"
        allowEmpty>
        <ChipsArrayInput optionValue="id" optionLabel="name" />
      </ReferenceArrayInput>
    </>
  )[0].props.children;

  return (
    <>
      <GroupInfo
        group={group}
        title="Grouped Orders"
        basePath="/orders/groups"
        to={!history.location?.state?.fromGroup || withFilters ? 'list' : ''}
      />
      <FilterChips
        resource="orders"
        filterValues={filterValues}
        filters={filters}
        setFilters={setFilters}
      />
      <div className={classes.main}>
        <Card key={props.version} className={classes.content}>
          <BulkActions {...props} selectedIds={selectedIds}>
            <>
              <BulkPrintPackingSlipsButton primary={false} ids={selectedIds} type="orders" />
              <CreateMultipleLabels
                {...props}
                selectedIds={selectedIds}
                currentId={id}
                orders={orders}
              />
            </>
          </BulkActions>
          <Datagrid
            {...props}
            data={keyBy(orders, 'id')}
            ids={orders.map(({ id }) => id)}
            basePath="orders"
            currentSort={sort}
            setSort={(field, order) => {
              setSort({ field, order });
              setPage(1);
            }}
            resource="orders"
            selectedIds={selectedIds}
            hasBulkActions
            onToggleItem={(id) => {
              selectedIds.includes(id)
                ? setSelectedIds((prev) => prev.filter((item) => item !== id))
                : setSelectedIds([...selectedIds, id]);
            }}
            onSelect={() =>
              isEqual(groupIds, selectedIds) ? setSelectedIds([]) : setSelectedIds(groupIds)
            }>
            <OrderIdField label="Order No." source="marketplaceOrderId" />
            <StoreField
              label="Store"
              source="store"
              tooltipSource="marketAccount.alias"
              logoSource="marketAccount.provider.smallLogoUrl"
              sortBy="marketAccount.provider"
            />
            <DateField label="Date" source="purchaseDate" />
            <CustomerField
              source="shippingAddress"
              sortBy={'shippingAddress.name'}
              addressSource="shippingAddress"
              label="Customer"
              autoSize
            />
            <PriceField label="Total" source="total" textAlign="right" />
            <ReferenceField
              label="Status"
              source="orderStatus"
              reference="orderStatuses"
              link={false}
              sortable={false}>
              <StatusField />
            </ReferenceField>
            <CreateLabelButtonField />
            <ActionField header={ColumnsConfigButton} textAlign="center">
              <EditActionField label="Edit" />
              <PrintPackingSlipActionField />
              <MarkAsShippedActionField />
              <DeleteOrderActionField grouped={true} />
            </ActionField>
          </Datagrid>
        </Card>
      </div>
    </>
  );
};

export default memo(GroupedOrders);
