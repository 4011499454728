import { LIGHTBOX_CLOSE, LIGHTBOX_CLOSE_ALL, LIGHTBOX_OPEN } from 'store/actions/lightboxActions';

interface State {
  docs: Document[];
}

interface BaseDocument {
  id: string;
  name: string;
}

interface BlobDocument extends BaseDocument {
  blob: Blob;
}

interface UrlDocument extends BaseDocument {
  url: string;
}

type Document = BlobDocument | UrlDocument;

const INITIAL_STATE: State = {
  docs: [],
};

const lightboxReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LIGHTBOX_OPEN:
      return {
        ...state,
        docs: [...state.docs, payload],
      };

    case LIGHTBOX_CLOSE:
      return {
        ...state,
        docs: state.docs.filter((doc) => doc.id !== payload.id),
      };

    case LIGHTBOX_CLOSE_ALL:
      return {
        ...state,
        docs: [],
      };

    default:
      return state;
  }
};

export default lightboxReducer;
