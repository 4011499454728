import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { createStyles, FormControl, FormHelperText, useTheme, withStyles } from '@material-ui/core';
// import {CardElement} from "react-stripe-elements";
import { CardElement } from '@stripe/react-stripe-js';

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },

    label: {
      color: theme.palette.secondary.contrastText,
    },

    element: {
      padding: theme.spacing(3, 0, 1.5),
      borderBottom: `1px solid #E0E0E0`,
    },

    elementError: {
      borderBottomColor: theme.palette.error.main,
    },
  });

const Stripe = ({ classes, name, validate }) => {
  const theme = useTheme();

  const stripeStyle = {
    base: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '16px',
      color: theme.palette.text.primary,

      '::placeholder': {
        color: theme.palette.secondary.contrastText,
      },
    },
  };

  return (
    <Field name={name} validate={validate}>
      {({ input: { onChange }, meta: { touched, error } }) => {
        const isError = !!(touched && error);

        return (
          <FormControl fullWidth className={classes.root}>
            <CardElement
              className={classNames(classes.element, isError && classes.elementError)}
              // style={stripeStyle}
              options={{
                hidePostalCode: true,
                style: stripeStyle,
              }}
              onChange={onChange}
            />
            {isError && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};

export default withStyles(styles)(Stripe);
