import React from 'react';
import classNames from 'classnames';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { required, maxValue, minValue, composeValidators } from 'components/form/validationRules';
import { Typography, makeStyles } from '@material-ui/core';
import PaymentAmountField from './PaymentAmountField';

const useStyles = makeStyles((theme) => ({
  root: {},
  option: {
    borderRadius: 24,
    border: '1px solid #969AB2',
    color: '#969AB2',
    maxHeight: 40,
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 2.5),
    cursor: 'pointer',
    transition: '0.2s all ease-out',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      transition: '0.2s all ease-out',
    },
  },
  optionList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1.5, 3, 3, 3),
  },
  selectedOption: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
    backgroundColor: '#fafafc',
    padding: theme.spacing(1.5, 3),
    borderRadius: 16,
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
    marginRight: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
  },
}));

const changeProxy = (value: string) =>
  value
    .replace(/[^\d.]/g, '')
    .replace(/\.(?=.*\.)/g, '')
    .replace(/^(\d+\.\d{0,2})\d*$/, '$1');

const SinglePayAmount = ({
  options,
  form,
  paymentLimits,
  values,
  handleChangeAmount,
  ...props
}: any) => {
  const classes = useStyles();

  const handleOptionClick = (option) => {
    form.batch(() => {
      form.change('paymentOption', option.value);
      form.change('customAmount', option.value.baseAmount);
    });
  };

  return (
    <div className={classNames(classes.root)}>
      <div className={classes.header}>
        <MonetizationOnOutlinedIcon className={classes.icon} />
        <Typography component="div" className={classes.label}>
          AMOUNT TO ADD
        </Typography>
      </div>
      <PaymentAmountField
        name="customAmount"
        validate={composeValidators(
          required,
          maxValue(paymentLimits?.max),
          minValue(paymentLimits?.min)
        )}
        label="Select or enter amount"
        handleChange={handleChangeAmount}
        changeProxy={changeProxy}
        inputProps={{
          helperText:
            values.customAmount && values.customAmount < 50
              ? 'The more you fund, the more you save on payment percentage fee.'
              : null,
        }}
        placeholder="0"
      />
      <div className={classes.optionList}>
        {options?.map((option) => (
          <div
            className={classNames(
              classes.option,
              option.value.baseAmount === values.customAmount && classes.selectedOption
            )}
            onClick={() => handleOptionClick(option)}
            key={option.label}>
            <Typography>{option.label}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SinglePayAmount;
