import React from 'react';
import { createStyles, withStyles, Switch as MUISwitch } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      width: 52,
      height: 32,
      padding: 0,
    },
    switchBase: {
      color: theme.palette.common.white,
      padding: 2,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.primary.main,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 28,
      height: 28,
      boxShadow: theme.shadows[1],
    },
    track: {
      borderRadius: 32 / 2,
      backgroundColor: '#d7d9e0',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  });

const Switch = ({ classes, ...props }) => (
  <MUISwitch
    // focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
);

export default withStyles(styles)(Switch);
