import React, { forwardRef } from 'react';
import clipboardCopy from 'clipboard-copy';
import { IconButton, MenuItem } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import useCustomNotify from 'hooks/useCustomNotify';

const CopyTrackNumberActionField = forwardRef((props: any, ref: any) => {
  const notify = useCustomNotify();

  if (!props.record.trackNumber) {
    return null;
  }

  const handleClick = () => {
    clipboardCopy(props.record.trackNumber)
      .then(() => {
        notify('Track number is copied');
      })
      .catch((err) => {
        notify(err.message, 'error');
      });
  };

  return (
    <MenuItem ref={ref} onClick={handleClick}>
      Copy tracking number
    </MenuItem>
  );
});

const CopyTrackNumberButtonField = ({ record, ...props }) => {
  const notify = useCustomNotify();

  const handleClick = () => {
    clipboardCopy(record.trackNumber)
      .then(() => {
        notify('Track number is copied');
      })
      .catch((err) => {
        notify(err.message, 'error');
      });
  };

  return (
    <IconButton {...props} onClick={handleClick}>
      <FileCopyOutlined />
    </IconButton>
  );
};

export { CopyTrackNumberActionField, CopyTrackNumberButtonField };
