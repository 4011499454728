import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, withStyles, Grid, Paper, Grow } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    title: {
      padding: theme.spacing(3, 0, 6),
    },

    item: {
      position: 'relative',
      boxSizing: 'border-box',
      paddingBottom: '66%',
      cursor: 'pointer',
    },

    image: {
      position: 'absolute',
      width: `calc(100% - ${theme.spacing(8)}px)`,
      height: `calc(100% - ${theme.spacing(8)}px)`,
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: theme.spacing(25),
      maxHeight: theme.spacing(8),
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    caption: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
  });

const ImagePaper = (props) => {
  const { classes, backgroundImage, caption, onClick } = props;
  return (
    <Paper className={classes.item} onClick={onClick}>
      <div className={classes.image} style={{ backgroundImage: `url(${backgroundImage})` }} />
      {caption && <span className={classes.caption}>{caption}</span>}
    </Paper>
  );
};

const ImageLinkList = ({ classes, title, items }) => (
  <div className={classes.container}>
    <div className={classes.title}>{title}</div>
    <Grid container spacing={4} justify="center">
      {items.map((item, i) => (
        <Grow key={item.path} in timeout={300 * i}>
          <Grid item xs={3}>
            {item.onClick ? (
              <ImagePaper
                onClick={item.onClick}
                classes={classes}
                backgroundImage={item.image}
                caption={item.caption}
              />
            ) : (
              <Link to={item.path}>
                <ImagePaper classes={classes} backgroundImage={item.image} caption={item.caption} />
              </Link>
            )}
          </Grid>
        </Grow>
      ))}
    </Grid>
  </div>
);

export default withStyles(styles)(ImageLinkList);
