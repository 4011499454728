import React, { ReactNode, useMemo, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IRecipient } from 'services/data/interfaces';
import Option from './Option';
import OptionList from './OptionList';
import { makeStyles } from '@material-ui/styles';
import { isDigitalZipCode } from '../../utils/isDigitalZipCode';

type OptionType =
  | IRecipient
  | {
      name?: string;
      postalCode: string;
      city?: string;
      dummy?: boolean;
      elementToRender?: string | ReactNode;
    };

const useStyles = makeStyles((theme) => ({
  dummyOption: {
    backgroundColor: 'transparent !important',
    cursor: 'inherit',
  },
}));

const RecipientAutocomplete = ({
  className,
  value,
  options,
  renderInput,
  onChange,
  isRecipientsLoading,
  isRecipientsError,
  onChangeInputValue,
  isDestinationAddress,
  setChosenRecipientID,
  currentSearchString,
  notUS = false,
  zipCodeRequired,
}: {
  className?: string;
  value: { postalCode: string; id: string; city: string };
  options: IRecipient[];
  renderInput: (params) => React.ReactNode;
  onChange: (event, option) => void;
  onChangeInputValue: (value) => void;
  isRecipientsLoading: boolean;
  isRecipientsError: boolean;
  isDestinationAddress: boolean;
  setChosenRecipientID: (value) => void;
  currentSearchString?: string;
  notUS?: boolean;
  zipCodeRequired: boolean;
}) => {
  const classes = useStyles();

  const zipValue = useMemo(
    () => options && options.find((wp) => wp.id === value.id),
    [options, value]
  );

  const [inputValue, setInputValue] = useState(zipValue?.postalCode ?? '');

  const optionsExist = options.length > 0;
  const finalOptions = optionsExist
    ? options
    : (currentSearchString ?? '')?.length < 2 || isDigitalZipCode(currentSearchString ?? '')
    ? []
    : [
        {
          postalCode: inputValue,
          dummy: true,
          elementToRender: isRecipientsLoading
            ? 'Loading...'
            : isRecipientsError
            ? 'Unable to retrieve recipients'
            : 'No options found',
        },
      ];

  const renderOption = (option) => {
    if (option.dummy) {
      return <div>{option?.elementToRender ?? ''}</div>;
    }

    return <Option option={option} checked={option.id === value.id} />;
  };

  const handleInputChange = (event, value) => {
    onChangeInputValue(value);
    setInputValue(value);
    onChange(
      event,
      isDigitalZipCode(value) || (notUS && zipCodeRequired)
        ? { postalCode: value, id: null }
        : { city: value, id: null }
    );
  };

  const handleChange = (event, option) => {
    if (option.dummy) {
      return;
    }

    setChosenRecipientID(option.id);

    if (typeof option === 'string') {
      onChange(
        event,
        isDigitalZipCode(option) || (notUS && zipCodeRequired)
          ? { postalCode: option, id: null }
          : { city: option, id: null }
      );
      setTimeout(() => {
        onChangeInputValue(option);
        setInputValue(option);
      }, 30);
    } else {
      onChange(event, option);
    }
  };

  return (
    <Autocomplete<OptionType>
      className={className}
      classes={{ option: optionsExist ? undefined : classes.dummyOption }}
      disableClearable
      forcePopupIcon={false}
      options={finalOptions}
      value={
        zipValue?.postalCode
          ? zipValue
          : {
              postalCode: zipCodeRequired ? inputValue ?? '' : undefined,
              id: null,
              city: !zipCodeRequired ? inputValue ?? '' : undefined,
            }
      }
      inputValue={inputValue}
      getOptionLabel={(option) => option.name ?? option.postalCode ?? option.city ?? ''}
      filterOptions={(options) => options}
      PaperComponent={({ children, ...props }) => (
        <OptionList {...props} isDestinationAddress={isDestinationAddress}>
          {children}
        </OptionList>
      )}
      renderOption={renderOption}
      renderInput={renderInput}
      onInputChange={handleInputChange}
      onChange={handleChange}
      freeSolo
    />
  );
};

export default RecipientAutocomplete;
