export const REFILL_OPEN = 'Rollo/REFILL_OPEN';
export const REFILL_CLOSE = 'Rollo/REFILL_CLOSE';
export const PAYMENT_AMOUNT_SET = 'Rollo/PAYMENT_AMOUNT_SET';
export const PAYMENT_AMOUNT_REMOVE = 'Rollo/PAYMENT_AMOUNT_REMOVE';

export const openRefill = (callback: (err, res) => void, openSingleTab?: boolean) => {
  return {
    type: REFILL_OPEN,
    payload: {
      callback,
      openSingleTab,
    },
  };
};

export const closeRefill = () => {
  return {
    type: REFILL_CLOSE,
  };
};

export const setPaymentAmount = (amount) => {
  return {
    type: PAYMENT_AMOUNT_SET,
    payload: {
      amount,
    },
  };
};

export const removePaymentAmount = () => {
  return {
    type: PAYMENT_AMOUNT_REMOVE,
  };
};
