import useProfile from 'hooks/useProfile';
import { useEffect } from 'react';

const activateFreshChat = (user) => {
  if (!process.env.REACT_APP_FRESHCHAT_ID) {
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.id = process.env.REACT_APP_FRESHCHAT_ID as string;
  script.src = process.env.REACT_APP_FRESHCHAT_SCRIPT as string;

  script.onload = () => {
    const { fcWidget } = window as any;

    if (!fcWidget) {
      return;
    }

    fcWidget.init({
      token: process.env.REACT_APP_FRESHCHAT_TOKEN,
      host: process.env.REACT_APP_FRESHCHAT_HOST,
      externalId: user.email,
    });

    fcWidget.user.setProperties({
      firstName: user.given_name,
      lastName: user.family_name,
      email: user.email,
      phone: user.phone_number,
      plan: 'Pro',
      status: 'Active',
    });
  };

  document.head.appendChild(script);
};

const activateFreshMarketer = (user) => {
  if (!process.env.REACT_APP_FRESHMARKETER_SCRIPT) {
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.src = process.env.REACT_APP_FRESHMARKETER_SCRIPT as string;

  script.onload = () => {
    const { FM } = window as any;

    if (!FM) {
      return;
    }

    FM.associateVisitor(user.email);
  };

  document.head.appendChild(script);
};

const Freshworks = () => {
  const { profile } = useProfile();

  useEffect(() => {
    if (!profile) return;

    activateFreshChat(profile);
    activateFreshMarketer(profile);

    return () => {
      const { fcWidget } = window as any;

      if (fcWidget) {
        fcWidget.destroy();
      }
    };
  }, [profile]); //eslint-disable-line

  return null;
};

export default Freshworks;
