import React, { forwardRef, useState } from 'react';
import { useDataProvider, useRecordSelection, useRedirect, useRefresh } from 'react-admin';
import { get } from 'lodash';
import { Button, MenuItem } from '@material-ui/core';
import { Confirmation } from 'components/common';
import ActionButton from 'components/buttons/ActionButton';
import { Delete } from 'icons';
import useCustomNotify from 'hooks/useCustomNotify';

const useDeleteOrders = (ids, onComplete): [() => any, { loading: boolean }] => {
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();
  const [loading, setLoading] = useState(false);

  const deleteOrders = () => {
    setLoading(true);

    return Promise.all<{ deleted: boolean }>(
      ids.map((id) =>
        dataProvider
          .delete('orders', { id })
          .then(() => {
            return { deleted: true };
          })
          .catch((err) => {
            notify(err.message, 'error');
            return { deleted: false };
          })
      )
    ).then((res) => {
      const deleted = res.reduce((count, item) => {
        if (item.deleted) {
          return count + 1;
        }
        return count;
      }, 0);
      notify(`${deleted} order(s) deleted`);

      setLoading(false);
      return onComplete;
    });
  };

  return [deleteOrders, { loading }];
};

const DeleteOrderButton = ({ data, basePath }) => {
  const redirect = useRedirect();
  const [deleteOrder, { loading }] = useDeleteOrders([get(data, 'id')], () => redirect(basePath));

  return (
    <Confirmation title="Delete Order" content="Are you sure you want to delete this order?">
      <ActionButton data-test="delete-button" disabled={loading} onClick={deleteOrder}>
        Delete order
      </ActionButton>
    </Confirmation>
  );
};

const DeleteOrderActionField = forwardRef((props: any, ref: any) => {
  const refresh = useRefresh();
  const { grouped } = props;
  const [, { clearSelection }] = useRecordSelection(props.resource);
  const [deleteOrder] = useDeleteOrders([get(props.record, 'id')], () => {
    clearSelection();
    refresh();
  });

  return (
    <Confirmation
      title="Delete Order"
      content={`Are you sure you want to delete this order?${
        grouped
          ? ' This action cannot be undone. If you want to remove it from the group, simply de-select it.'
          : ''
      }`}>
      <MenuItem ref={ref} onClick={deleteOrder}>
        Delete
      </MenuItem>
    </Confirmation>
  );
});

const DeleteOrdersBulkActionButton = ({ selectedIds, onUnselectItems }) => {
  const refresh = useRefresh();
  const [deleteOrders, { loading }] = useDeleteOrders(selectedIds, () => {
    onUnselectItems();
    refresh();
  });

  return (
    <Confirmation
      title="Delete Orders"
      content={`Are you sure you want to delete ${selectedIds.length} orders?`}>
      <Button
        data-test="delete-selected-button"
        disabled={loading}
        size="small"
        color="default"
        variant="outlined"
        startIcon={<Delete />}
        onClick={deleteOrders}>
        Delete
      </Button>
    </Confirmation>
  );
};

export { DeleteOrderButton, DeleteOrderActionField, DeleteOrdersBulkActionButton };
