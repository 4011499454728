import React, { ReactNode, useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { makeStyles, Typography, IconButton, CircularProgress } from '@material-ui/core';
import useCustomNotify from 'hooks/useCustomNotify';

import { ReactComponent as Plus } from 'assets/claims/plus.svg';
import { required } from 'components/form/validationRules';
import { Delete } from 'icons';

import { PhotoType, ClaimType } from '../types';

interface StylesProps {
  value: File;
  error?: boolean;
}

const useStyles = makeStyles((theme) => ({
  dropzone: {
    height: 170,
    width: '100%',
    minWidth: 235,
    boxSizing: 'border-box',
    border: (props: StylesProps) =>
      !!props.value
        ? 'none'
        : props.error
        ? `1px dashed ${theme.palette.error.main}`
        : '1px dashed #969AB2',
    borderRadius: 16,
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2.5, 3.5),
    justifyContent: 'space-between',
    height: '100%',
    boxSizing: 'border-box',
  },
  image: {
    width: '100%',
    height: '100%',
    maxWidth: 235,
    maxHeight: 170,
    borderRadius: 16,
    objectFit: 'contain',
  },
  deleteButton: {
    height: 32,
    width: 32,
    backgroundColor: 'transparent',
    border: '1px solid #E1E1E1',
    borderRadius: 45,
    boxSizing: 'border-box',
    padding: 0,
    minWidth: 32,
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
  imageText: {
    textAlign: 'center',
  },
}));

interface Props {
  icon: ReactNode;
  itemIndex?: number;
  text: string | ReactNode;
  uploadLink?: string;
  name: string;
  photoType: PhotoType;
  sectionType: 'package' | 'item';
  claimType: ClaimType;
  isRequired?: boolean;
}

const FILE_SIZE_ERROR = 'file-too-large';
const FILE_TYPE_ERROR = 'file-invalid-type';
const TOO_MANY_FILES_ERROR = 'too-many-files';

export const ImageInputField = ({
  icon,
  text,
  name,
  photoType,
  sectionType,
  claimType,
  itemIndex,
  isRequired = true,
}: Props) => {
  const notify = useCustomNotify();
  const { id } = useParams();
  const dataProvider = useDataProvider();

  const {
    input: { value, onChange },
    meta: { error, touched },
  } = useField(name, { validate: isRequired ? required : undefined });

  const classes = useStyles({ value, error: !!(error && touched) });

  const [imageSrc, setImageSrc] = useState<string>('');
  const [uploading, setUploading] = useState<boolean>(false);

  const handleFileSelect = async (acceptedFiles: File[]) => {
    try {
      setUploading(true);
      const file = acceptedFiles[0];

      if (!file) return;

      const { data: uploadLink } = await dataProvider.getNest(
        sectionType === 'package' ? 'claimPackagePhoto' : 'claimItemPhotos',
        {
          id,
          claimType,
          photoType,
          extension: file.type.replace('image/', ''),
          ...(sectionType === 'item' && { itemIndex: String(itemIndex) }),
        }
      );

      await fetch(uploadLink, { method: 'PUT', body: file });

      setImageSrc(window.URL.createObjectURL(file));

      onChange(file);
    } catch (error: any) {
      if (error?.message) {
        notify(error?.message, 'error');
      } else {
        notify('Something went wrong', 'error');
      }
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (typeof value !== 'string') {
      setImageSrc(window.URL.createObjectURL(value));
    }
  }, [value]);

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    open: openFilesSelection,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    multiple: false,
    maxSize: 5242880,
    maxFiles: 1,
    onDropAccepted: handleFileSelect,
  });

  useEffect(() => {
    fileRejections.forEach((file) => {
      file.errors.forEach((error) => {
        if (error.code === FILE_SIZE_ERROR) {
          notify('Image size must not exceed 5MB');
          return;
        }

        if (error.code === FILE_TYPE_ERROR) {
          notify('Wrong image file format');
          return;
        }

        if (error.code === TOO_MANY_FILES_ERROR) {
          notify("You can't upload more than 1 image");
          return;
        }

        return;
      });
    });
  }, [fileRejections, notify]);

  const handleDelete = async (e) => {
    e.stopPropagation();

    try {
      await dataProvider.delete(
        sectionType === 'package' ? 'deleteClaimPackagePhoto' : 'deleteClaimItemPhoto',
        {
          nested: {
            shipmentId: id,
            photoType,
            ...(sectionType === 'item' && { index: String(itemIndex) }),
          },
        }
      );

      onChange(null);
    } catch (error: any) {
      if (error?.message) {
        notify(error?.message, 'error');
      } else {
        notify('Something went wrong', 'error');
      }
    }
  };

  return (
    <div {...getRootProps()} className={classes.dropzone} onClick={openFilesSelection}>
      <input {...getInputProps()} />

      {uploading && <CircularProgress />}

      {!uploading && (
        <>
          {!!value && (
            <>
              <img src={imageSrc} alt="product" className={classes.image} />

              <IconButton
                classes={{
                  root: classes.deleteButton,
                }}
                onClick={handleDelete}>
                <Delete />
              </IconButton>
            </>
          )}

          {!value && (
            <div className={classes.emptyContainer}>
              {icon}
              <Typography className={classes.imageText}>{text}</Typography>
              <Plus />
            </div>
          )}
        </>
      )}
    </div>
  );
};
