import React from 'react';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { Refill } from 'icons';
import { Switch } from 'components/common';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fafafc',
    padding: theme.spacing(1.5, 3),
    borderRadius: 16,
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(0, -3),
    borderRadius: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
  },
  switch: {
    marginLeft: 'auto',
  },
}));

const AutoRefillSwitch = ({ checked, onChange, ...props }: any) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root)}>
      <Refill className={classNames(classes.icon)} />
      <Typography component="div" className={classNames(classes.label)}>
        ENABLE AUTO-REFILL
      </Typography>
      <Switch className={classNames(classes.switch)} checked={checked} onChange={onChange} />
    </div>
  );
};

export default AutoRefillSwitch;
