import React, { ReactNode } from 'react';
import {
  createStyles,
  withStyles,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import SubmitButton from 'components/buttons/SubmitButton';

const styles = (theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      minWidth: 0,
      right: theme.spacing(2.5),
      top: theme.spacing(3.25),
      color: theme.palette.secondary.contrastText,
    },

    title: {
      padding: theme.spacing(4),
    },

    content: {
      padding: theme.spacing(0, 4),
      minWidth: theme.spacing(50),
      minHeight: theme.spacing(8),
      overflowX: 'hidden',
    },

    error: {
      padding: theme.spacing(2, 4, 0),
      color: theme.palette.error.main,
    },

    fixError: {
      marginLeft: theme.spacing(2),
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,

      '&:hover': {
        borderColor: theme.palette.error.dark,
      },
    },

    actions: {
      padding: theme.spacing(4),

      '& .MuiButton-containedSecondary': {
        backgroundColor: theme.palette.secondary.light,

        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },

    note: {
      padding: theme.spacing(0, 4, 4),
      fontSize: 14,
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
  });

const NotificationDialog = ({
  classes,
  open,
  title,
  confirmText = 'Ok',
  confirmDisabled = false,
  note = null,
  children,
  submitting,
  error,
  onCancel,
  onConfirm,
  onFixError,
  dialogProps,
}: {
  classes: any;
  open: boolean;
  title: ReactNode;
  confirmText?: string;
  confirmDisabled?: boolean;
  cancelText?: string;
  note?: ReactNode;
  children: ReactNode;
  submitting?: boolean;
  error?: string;
  onCancel: () => void;
  onConfirm?: (event) => void;
  onFixError?: () => void;
  dialogProps?;
}) => (
  <MUIDialog open={open} onClose={onCancel} {...dialogProps}>
    <Button className={classes.closeButton} onClick={onCancel}>
      <CloseOutlined />
    </Button>
    <DialogTitle className={classes.title}>{title}</DialogTitle>
    <DialogContent className={classes.content}>{children}</DialogContent>
    {error && (
      <div className={classes.error}>
        {error}
        {onFixError && (
          <Button
            classes={{ root: classes.fixError }}
            variant="outlined"
            size="small"
            color="secondary"
            onClick={onFixError}>
            Fix it
          </Button>
        )}
      </div>
    )}
    <DialogActions className={classes.actions}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SubmitButton
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            label={confirmText}
            disabled={submitting || confirmDisabled}
            submitting={submitting}
            onClick={onConfirm}
          />
        </Grid>
      </Grid>
    </DialogActions>
    {note && <div className={classes.note}>{note}</div>}
  </MUIDialog>
);

export default withStyles(styles)(NotificationDialog);
