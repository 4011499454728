import React, { useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Dialog } from 'components/common';
import UpsAgreements from './UpsAgreements';
import useCustomNotify from 'hooks/useCustomNotify';
import { Typography } from '@material-ui/core';

interface Props {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const GroundSaverActivation = ({ open, onConfirm, onCancel }: Props) => {
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();
  const [submitting, setSubmitting] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const onUpsEnablingConfirm = async () => {
    try {
      setSubmitting(true);
      await dataProvider.put('upsAgreemets', { data: {} });

      setOpenConfirmationDialog(true);
    } catch (error: any) {
      notify(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleConfirm = () => {
    setOpenConfirmationDialog(false);
    onConfirm();
  };
  return (
    <>
      <Dialog
        title="Congratulations!"
        open={!!openConfirmationDialog}
        onCancel={handleConfirm}
        submitting={submitting}
        cancelSize={12}
        cancelText="OK">
        <Typography>
          UPS<sup>&reg;</sup> Ground Saver has been successfully activated. You may now purchase
          your label
        </Typography>
      </Dialog>
      <Dialog
        title={
          <>
            Activate UPS<sup>&reg;</sup> Ground Saver
          </>
        }
        open={!!open}
        confirmText="I Accept"
        onConfirm={onUpsEnablingConfirm}
        onCancel={onCancel}
        submitting={submitting}>
        <UpsAgreements />
      </Dialog>
    </>
  );
};
export default GroundSaverActivation;
