import React from 'react';
import classNames from 'classnames';
import { Typography, FormControl, Select, MenuItem, CircularProgress } from '@material-ui/core';
import formatPrice from 'services/helpers/formatPrice';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-admin';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 16px',

      '@media (max-width: 600px)': {
        display: 'block',
      },
    },
    labelAndValue: {
      display: 'flex',
      alignItems: 'center',
    },
    select: {
      margin: theme.spacing(1),
      minWidth: 60,
    },
    number: { color: '#6C13F9' },
  });

const withClasses = withStyles(styles);

const AutoRefillSetting = withClasses(
  ({ classes, autoRefillAmount, changeAutoRefillAmount }: any) => {
    const { data: autoRefillOptions } = useQuery({
      type: 'getList',
      resource: 'autoRefillOptions',
      payload: {},
    });
    const { data: thresholdOptions, loading } = useQuery({
      type: 'getList',
      resource: 'thresholdOptions',
      payload: {},
    });

    if (loading) return <CircularProgress />;

    return (
      <div className={classNames(classes.root)}>
        <div className={classNames(classes.labelAndValue)}>
          <Typography component="div">Auto-refill my balance to</Typography>
          <FormControl className={classNames(classes.select)}>
            {autoRefillOptions ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={autoRefillAmount}
                onChange={changeAutoRefillAmount}
                className={classes.number}>
                {autoRefillOptions.map((e) => (
                  <MenuItem key={e.option} value={e.value}>
                    {formatPrice(e.value, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <CircularProgress />
            )}
          </FormControl>
        </div>
        <Typography component="div">
          {' '}
          when it falls below <span className={classes.number}>${thresholdOptions[0].value}</span>
        </Typography>
      </div>
    );
  }
);

export default AutoRefillSetting;
