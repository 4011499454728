import { useState, useLayoutEffect } from 'react';

const useWindowSize = (ref) => {
  const [size, setSize] = useState(0);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (ref.current) {
        setSize(ref.current.offsetWidth);
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []); //eslint-disable-line

  return size;
};

export default useWindowSize;
