import { useMemo } from 'react';
import { useQueryWithStore } from 'react-admin';

interface IStatus {
  bgColor: string;
  color: string;
  name: string;
  status: string;
}

const usePickupStatuses = ({ returnAsObject }) => {
  const { data, loaded, loading } = useQueryWithStore({
    type: 'getList',
    resource: 'singlePickupStatuses',
  });

  const statuses = useMemo(() => {
    const statusesObj = {};
    if (!loaded) return statusesObj;
    data.forEach((s: IStatus) => {
      statusesObj[s.status] = s;
    });
    return statusesObj;
  }, [data, loaded]);

  const returnedStatuses = returnAsObject ? statuses : data;

  return [returnedStatuses, { loaded, loading }];
};

export default usePickupStatuses;
