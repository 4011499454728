import React, {
  cloneElement,
  createElement,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Checkbox, Fade, TableCell, TableRow } from '@material-ui/core';
import { linkToRecord } from 'react-admin';
import ExpandRowButton from 'ra-ui-materialui/lib/list/ExpandRowButton';
import { useHistory } from 'react-router';
import { RadioButtonUnchecked } from '@material-ui/icons';
import { ReactComponent as CheckIcon } from 'assets/general/icons/CheckIcon.svg';

const defaultClasses = {};

const computeNbColumns = (expand, children, hasBulkActions) =>
  expand
    ? 1 + // show expand button
      (hasBulkActions ? 1 : 0) + // checkbox column
      React.Children.toArray(children).filter((child) => !!child).length // non-null children
    : 0; // we don't need to compute columns if there is no expand panel;

const DatagridRow = (props) => {
  const {
    record,
    resource,
    id,
    onToggleItem,
    children,
    classes = defaultClasses,
    selected,
    basePath,
    hasBulkActions,
    className,
    expand,
    hover = true,
    rowClick,
    style,
    filterValues,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const [nbColumns, setNbColumns] = useState(computeNbColumns(expand, children, hasBulkActions));
  useEffect(() => {
    // Fields can be hidden dynamically based on permissions;
    // The expand panel must span over the remaining columns
    // So we must recompute the number of columns to span on
    const newNbColumns = computeNbColumns(expand, children, hasBulkActions);
    if (newNbColumns !== nbColumns) {
      setNbColumns(newNbColumns);
    }
  }, [expand, nbColumns, children, hasBulkActions]);

  const handleToggleExpand = useCallback(
    (event) => {
      setExpanded(!expanded);
      event.stopPropagation();
    },
    [expanded]
  );
  const handleToggleSelection = useCallback(
    (event) => {
      onToggleItem(id);
      event.stopPropagation();
    },
    [id, onToggleItem]
  );

  const handleClick = useCallback(
    async (event) => {
      if (!rowClick) return;
      const effect =
        typeof rowClick === 'function' ? await rowClick(id, basePath, record) : rowClick;
      switch (effect) {
        case 'edit':
          history.push(linkToRecord(basePath, id));
          return;
        case 'show':
          history.push(linkToRecord(basePath, id, 'show'));
          return;
        case 'expand':
          handleToggleExpand(event);
          return;
        case 'toggleSelection':
          handleToggleSelection(event);
          return;
        default:
          if (effect) history.push(effect);
          return;
      }
    },
    [basePath, history, handleToggleExpand, handleToggleSelection, id, record, rowClick]
  );

  return (
    <Fade in>
      <>
        <TableRow
          key={id}
          onClick={handleClick}
          hover={hover}
          style={style}
          className={className}
          data-test="datagrid-row">
          {expand && (
            <TableCell padding="none" className={classes.expandIconCell}>
              <ExpandRowButton
                classes={classes}
                expanded={expanded}
                onClick={handleToggleExpand}
                expandContentId={`${id}-expand`}
              />
            </TableCell>
          )}
          {hasBulkActions && (
            <TableCell>
              <Checkbox
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckIcon />}
                checked={selected}
                onClick={handleToggleSelection}
                disableTouchRipple
                disableRipple
              />
            </TableCell>
          )}
          {React.Children.map(children, (field) => (
            <TableCell
              key={`${id}-${field.props.source}`}
              align={field.props.textAlign}
              data-test="datagrid-cell"
              data-column={field.props.source}>
              {React.cloneElement(field, {
                record,
                basePath,
                resource,
              })}
            </TableCell>
          ))}
        </TableRow>
        {expand && expanded && (
          <TableRow
            className={classes.expandRoot}
            key={`${id}-expand`}
            id={`${id}-expand`}
            data-test="expand-root">
            <TableCell className={classes.expandCell} colSpan={nbColumns}>
              {isValidElement(expand)
                ? cloneElement(expand, {
                    // @ts-ignore
                    record,
                    basePath,
                    resource,
                    id: String(id),
                    filterValues,
                  })
                : createElement(expand, {
                    record,
                    basePath,
                    resource,
                    id: String(id),
                    filterValues,
                  })}
            </TableCell>
          </TableRow>
        )}
      </>
    </Fade>
  );
};

export default DatagridRow;
