import { pick } from 'lodash';

export const presetToValidationRequest = (values) => ({
  ...pick(values, ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'postalCode']),
  countryCode: values.stateOrProvince.country.code,
  residential: true,
  stateProvinceCode: values.stateOrProvince.code,
});

export const suggestionToPresetRequest = (suggestion) => ({
  ...pick(suggestion, ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'postalCode']),
  stateOrProvinceId: suggestion.stateProvince.id,
});
