import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'react-admin';
import { openDoc } from 'store/actions/lightboxActions';
import useCustomNotify from 'hooks/useCustomNotify';

const usePackingSlip = (type: string, id: string) => {
  const [openingDocument, setOpeningDocument] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();
  const dispatch = useDispatch();

  const openDocument = () => {
    if (openingDocument) {
      return;
    }

    setOpeningDocument(true);

    dataProvider
      .getNest('packingSlip', { type, id, headers: { Accept: 'application/pdf' } })
      .then(({ data }) => {
        dispatch(openDoc({ name: 'Packing Slip', blob: data }));
      })
      .catch((err) => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setOpeningDocument(false);
      });
  };

  return { openingDocument, openDocument };
};

export default usePackingSlip;
