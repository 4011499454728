import { Grid, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from 'components/layout/Layout';
import privacyPolicy from 'pages/privacyPolicy';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { ConfirmSignUp, CreateNewPassword } from 'pages/auth';

export const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  imageContainer: {
    paddingBottom: theme.spacing(2.5),
  },
  signInButton: {
    marginTop: theme.spacing(4),
    textTransform: 'none',
  },
  signUp: {
    paddingTop: theme.spacing(4),
  },
  forgotPass: {
    paddingTop: theme.spacing(3),
  },
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    flexDirection: 'column',
  },
  minWidthOneThird: {
    minWidth: 'calc(100vw / 3)',
  },
  cardContent: {
    justifyContent: 'center',
    padding: theme.spacing(4),
    maxWidth: 600,
  },
  logo: {
    width: 90,
    margin: theme.spacing(4, 4, 8),
  },
  signinLink: {
    padding: theme.spacing(4, 0),
  },
  badgeLink: {
    textAlign: 'center',
    width: 288,
    height: 83,
  },
  badge: {
    width: '100%',
    height: 83,
  },
  badgesContainer: {
    display: 'flex',
    height: 120,
    justifyContent: 'space-between',
    width: 600,
    alignItems: 'center',
  },
  benefitsContainer: {
    minWidth: 490,
  },
  pageWithBenefitsLayout: {
    flexWrap: 'nowrap',
  },
});

const AuthLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Switch>
            <Route exact path="/privacy-policy">
              <Link className={classes.signinLink} to="/">
                Go to sign in
              </Link>
              {React.createElement(privacyPolicy.show)}
            </Route>
            <Route>
              <Switch>
                <Route exact path="/confirm-signup">
                  <ConfirmSignUp notLoggedIn />
                </Route>
                <Route exact path="/reset-password">
                  <CreateNewPassword />
                </Route>
                <Route>{children}</Route>
              </Switch>
            </Route>
          </Switch>
        </Grid>
      </ThemeProvider>
    </Router>
  );
};

export default AuthLayout;
