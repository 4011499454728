import React, { ComponentType, FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { registerResource, unregisterResource, ReduxState, WithPermissions } from 'react-admin';
import { Route } from 'react-router-dom';

interface ICustomResourceProps {
  intent?: 'route' | 'registration';
  name: string;
  component: ComponentType<any>;
  icon?: ComponentType<any>;
  path: string;
  options?: object;
}

const CustomResourceRegister: FunctionComponent<ICustomResourceProps> = ({
  name,
  options,
  icon,
  component,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      registerResource({
        name,
        options,
        icon,
      })
    );
    return () => dispatch(unregisterResource(name));
  }, [dispatch, name, options, icon, component]);
  return null;
};

const CustomResourceRoutes: FunctionComponent<ICustomResourceProps> = ({
  name,
  path,
  options,
  component,
}) => {
  const isRegistered = useSelector(
    (state: ReduxState) => !!state.admin.resources[name],
    shallowEqual
  );

  return useMemo(() => {
    if (!isRegistered) {
      return null;
    }

    const props = {
      resource: name,
      options,
    };

    return (
      <Route
        path={path}
        render={(routeProps) => (
          <WithPermissions component={component} basePath={path} {...routeProps} {...props} />
        )}
      />
    );
  }, [name, path, options, component, isRegistered]);
};

const CustomResource: FunctionComponent<ICustomResourceProps> = ({ intent = 'route', ...props }) =>
  intent === 'registration' ? (
    <CustomResourceRegister {...props} />
  ) : (
    <CustomResourceRoutes {...props} />
  );
export default CustomResource;
