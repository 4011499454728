import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDataProvider } from 'react-admin';
import { Field, FieldProps } from 'react-final-form';
import {
  FormLabel,
  Popover,
  CircularProgress,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  makeStyles,
  Button,
} from '@material-ui/core';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { GroupTitle } from 'components/common/Group';
import {
  getDefaultShipmentDate,
  setDefaultShipmentDate,
} from 'services/helpers/defaultShipmentDateHelpers';
import { getBuyLabelAddressData } from 'services/helpers/addressMappers';

const useStyles = makeStyles((theme) => ({
  button: {
    height: theme.spacing(4),
    borderColor: theme.palette.primary.light,
    transition: theme.transitions.create(['background-color', 'box-shadow', 'border', 'color']),
    textTransform: 'none',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    fontSize: 14,
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.dark,
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listHeader: {},
  listItem: {
    borderBottom: '1px solid #efefef',
  },
  listItemIcon: {
    minWidth: '0px',
  },
  radio: {
    padding: '0px',
  },
  paymentIcon: {
    padding: '5px',
    width: '30px',
    border: '1px solid #efefef',
    borderRadius: '5px',
  },
  listItemText: {
    paddingLeft: '10px',
    fontSize: '16px',
  },
  fullWidth: {
    width: '100%',
  },
}));

const ShipDateField = (props) => {
  const { warehouse, switched } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [shipmentDate, setShipmentDate] = useState<any>(null);
  const [dates, setDates] = useState<any>(null);
  const [defaultDate, setDefaultDate] = useState<any>(null);
  const [datesLoading, setDatesLoading] = useState<boolean>(false);
  const [datesError, setDatesError] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!warehouse) return;
    setDatesLoading(true);
    dataProvider
      .post('shipmentDatesCalendar', {
        data: {
          timezoneId: moment().format('Z'),
          warehouse: {
            ...getBuyLabelAddressData(switched ? warehouse?.address : warehouse),
            phone: warehouse?.phone?.number ?? warehouse?.phone,
            residential: true,
            warehouseName: warehouse.warehouseName ?? warehouse.name,
          },
          warehouseId: warehouse?.id,
        },
      })
      .then(({ data }) => {
        setDates(data);
        setDefaultDate(data.find((date) => date.defaultDate));
      })
      .catch((error) => setDatesError(error.message))
      .finally(() => setDatesLoading(false));
  }, [dataProvider, warehouse?.id]); //eslint-disable-line

  useEffect(() => {
    if (defaultDate && !props.isAdjustForm) {
      setShipmentDate(getDefaultShipmentDate(defaultDate.shipmentDate));
    }
  }, [defaultDate, props.isAdjustForm]);

  useEffect(() => {
    if (shipmentDate) {
      const today = moment().format('YYYY-MM-DD');
      const isShipDateBeforeToday = moment(shipmentDate).isBefore(today);

      props.onChange(isShipDateBeforeToday ? today : shipmentDate);
    }
  }, [shipmentDate]); //eslint-disable-line

  if (datesError) {
    return null;
  }

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (shipmentDate) => {
    setDefaultShipmentDate(shipmentDate);
    props.onChange(shipmentDate);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (datesLoading) {
    return <CircularProgress />;
  }

  const isToday = moment().format('YYYY-MM-DD') === props.value;

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="primary"
        className={`${classes.button} ${!!props.fullWidth ? classes.fullWidth : ''}`}
        endIcon={<ArrowDropDownOutlinedIcon className={classes.icon} />}>
        {isToday ? 'Today' : moment(props.value, 'YYYY-MM-DD').format('dddd, MMM DD')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {datesLoading && <CircularProgress />}
        <List
          className={classes.list}
          subheader={
            <ListSubheader>
              <GroupTitle> Select date</GroupTitle>
            </ListSubheader>
          }>
          {dates?.map(({ shipmentDate: date }) => {
            const labelId = `checkbox-list-label-${date}`;

            return (
              <ListItem
                className={classes.listItem}
                key={date}
                role={undefined}
                dense
                button
                onClick={() => handleClick(date)}>
                <ListItemIcon className={classes.listItemIcon}>
                  <>
                    <Radio
                      className={classes.radio}
                      color="primary"
                      checked={props.value ? date === props.value : date === shipmentDate}
                      onChange={() => handleClick(date)}
                      value={date}
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  </>
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  id={labelId}
                  primary={
                    date === moment().format('YYYY-MM-DD')
                      ? 'Today'
                      : moment(date, 'YYYY-MM-DD').format('dddd, MMM DD')
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};

const ShipDateInput = ({
  fullWidth,
  className,
  name,
  ...rest
}: {
  className?: string;
  name: string;
} & FieldProps<any, any>) => {
  return (
    <Field
      {...rest}
      name={name}
      render={({ input: { value, onChange }, meta: { touched, error } }) => {
        return (
          <div className={className}>
            <ShipDateField
              error={touched && error}
              onChange={onChange}
              value={value}
              fullWidth={fullWidth}
              {...rest}
            />
            {touched && error && <FormLabel error>{error}</FormLabel>}
          </div>
        );
      }}
    />
  );
};

export default ShipDateInput;
