import { Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Error } from '../../assets/general/icons/Error.svg';
import { setVerificationClosed, openVerification } from 'store/actions/verificationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FEDACD',
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  icon: {
    marginRight: theme.spacing(0.75),
  },
}));

const PhoneVerificationBanner = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showBanner = useSelector((store) => store.verification.verificationClosed);
  const header = () => JSON.parse(sessionStorage.getItem('phoneVerified') ?? 'true');

  useEffect(() => {
    const savedState = localStorage.getItem('verificationClosed');

    if (savedState === null) {
      localStorage.setItem('verificationClosed', JSON.stringify(false));
      dispatch(setVerificationClosed(false));
    } else {
      dispatch(setVerificationClosed(JSON.parse(savedState)));
    }
  }, [dispatch]);

  const handleClickBanner = () => {
    dispatch(openVerification());
  };

  if (!showBanner || header()) return null;

  return (
    <div className={classes.root} onClick={handleClickBanner}>
      <Error className={classes.icon} />
      <Typography>
        Protecting your account is our highest priority. Please verify your phone number to gain
        full access to your account.
      </Typography>
    </div>
  );
};

export default PhoneVerificationBanner;
