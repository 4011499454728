import Cookies from 'js-cookie';

const OAuthRedirect = async (props) => {
  // Get name of window which was set by the parent to be the unique request key
  const requestKey = window.name;
  const requestUrl = window.location.href;
  // Update corresponding entry with the redirected url which should contain either access token or failure reason in the query parameter / hash
  Cookies.set(requestKey, requestUrl);
  window.close();
};

export default OAuthRedirect;
