import React, { ReactNode } from 'react';
import { Button, Link, Typography, Grid, CircularProgress } from '@material-ui/core';
import { useStyles } from 'components/layout/AuthLayout';
import Footer from 'components/layout/Footer';
import logo from 'logo-black.svg';

const BaseMessageView = ({
  title,
  image,
  message,
  action,
  processing,
  onAction,
  onSignIn,
  widthLimit,
}: {
  title: ReactNode;
  image?: string;
  message: ReactNode;
  action: ReactNode;
  processing: boolean;
  onAction: (e) => void;
  onSignIn?: (e) => void;
  widthLimit?: number;
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container alignItems="center">
      <div className={classes.minWidthOneThird}>
        <div className={classes.cardContent}>
          <div style={widthLimit ? { maxWidth: `${widthLimit}px`, width: '100%' } : {}}>
            <Grid container justify="center">
              <img className={classes.logo} src={logo} alt={'logo'} />
            </Grid>
            {image && (
              <Grid container justify="center" className={classes.imageContainer}>
                <img src={image} alt={''} />
              </Grid>
            )}
            <Typography variant="h4" align="center" gutterBottom>
              {title}
            </Typography>
            <Grid item container justify="center" alignItems="center" className={classes.signUp}>
              {processing ? <CircularProgress /> : <Typography align="left">{message}</Typography>}
            </Grid>
            <Button
              className={classes.signInButton}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              disabled={processing}
              onClick={onAction}>
              {action}
            </Button>
            {onSignIn && (
              <Typography align="center" className={classes.signUp}>
                <Link href="" onClick={onSignIn}>
                  Back to Sign In
                </Link>
              </Typography>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </Grid>
  );
};

export default BaseMessageView;
