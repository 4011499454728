import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useField, useFormState } from 'react-final-form';

let timerId;

const WhenFieldChangesImpl = ({
  field,
  when,
  target,
  to,
  debounce,
}: {
  field: string;
  when: (value: any) => boolean;
  target: string;
  to: any;
  debounce?: number;
}) => {
  const { values } = useFormState();
  const origField = useField(field);
  const targetField = useField(target);

  const value = debounce ? get(values, origField.input.name) : origField.input.value;

  useEffect(() => {
    if (when(value)) {
      const toValue = to instanceof Function ? to(values) : to;

      if (debounce) {
        if (timerId) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          targetField.input.onChange(toValue);
        }, debounce);
      } else {
        targetField.input.onChange(toValue);
      }
    }
  }, [value]); //eslint-disable-line

  return null;
};

const WhenFieldChanges = (
  props: {
    field: string;
    set: string | string[];
    to: any;
    debounce?: number;
  } & ({ becomes: any } | { when: (value: any) => boolean })
) => {
  const when = 'when' in props ? props.when : (value) => value === props.becomes;

  const targets = Array.isArray(props.set) ? props.set : [props.set];

  return (
    <>
      {targets.map((target) => (
        <WhenFieldChangesImpl
          key={target}
          field={props.field}
          when={when}
          target={target}
          to={props.to}
          debounce={props.debounce}
        />
      ))}
    </>
  );
};

export default WhenFieldChanges;
