import { GET_LIST, GET_MANY } from 'react-admin';

const getStatuses = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.map((item) => ({
    ...item,
    id: item.status,
  })),
});

export default {
  [GET_LIST]: getStatuses,
  [GET_MANY]: getStatuses,
};
