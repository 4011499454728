import React from 'react';
import classNames from 'classnames';
import { Tab, createStyles, withStyles, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import DropdownTab from './DropdownTab';

const styles = (theme) =>
  createStyles({
    tab: {
      padding: theme.spacing(2.75, 0),
      fontSize: 14,
      lineHeight: '16px',

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  });

const AppBarTab = ({ classes, submenu, ...rest }) => {
  if (submenu) {
    return (
      <DropdownTab {...rest} className={classNames(classes.tab, rest.className)}>
        {submenu.map((item) => (
          <MenuItem component={NavLink} to={item.path} key={item.path}>
            {item.label}
          </MenuItem>
        ))}
      </DropdownTab>
    );
  }

  return (
    <Tab {...rest} className={classNames(classes.tab, rest.className)} data-test="menu-item" />
  );
};

export default withStyles(styles)(AppBarTab);
