import React from 'react';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { TextField, ReferenceArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import BackActionButton from 'components/buttons/BackActionButton';
import DateTimeField from './components/DateTimeField';
import TypeField from './components/TypeField';
import Filter from 'components/list/Filter';
import FilterDateInput from 'components/inputs/FilterDateInput';
import ChipsArrayInput from 'components/inputs/ChipsArrayInput';
import { Datagrid, List } from 'components/list';
import { ProviderField, ChipWithListField } from 'components/fields';
import { ActionToolbar } from 'components/common';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';

import usePickupStatuses from 'hooks/usePickupsStatuses';

const datePickerFormat = 'YYYY-MM-DD';
const dateFieldOptions = [
  {
    label: 'All time',
    value: {
      to: moment().endOf('day').format(datePickerFormat),
    },
  },
  {
    label: 'Today',
    value: {
      from: moment().startOf('day').format(datePickerFormat),
      to: moment().endOf('day').format(datePickerFormat),
    },
  },
  {
    label: 'Last 3 days',
    value: {
      from: moment().subtract(2, 'days').startOf('day').format(datePickerFormat),
      to: moment().endOf('day').format(datePickerFormat),
    },
  },
];

const WrapperStatusInput = (props) => {
  const acceptedStatuses = ['SHIPPED', 'CANCELLED'];
  const { choices, ...restProps } = props;

  const acceptedChoices = choices.filter((option) => acceptedStatuses.includes(option.status));
  return (
    <ChipsArrayInput
      optionValue="status"
      optionLabel="name"
      {...restProps}
      choices={acceptedChoices}
    />
  );
};

export const useStyles = makeStyles((theme) => ({
  toolbarRoot: {
    alignItems: 'center',
    paddingBottom: 0,
  },
  toolbarContent: {
    padding: theme.spacing(0, 4),
  },
}));

const PickupHistoryFilter = ({ ...props }) => {
  const classes = useStyles();

  return (
    <ActionToolbar
      classes={{
        root: classes.toolbarRoot,
        content: classes.toolbarContent,
      }}
      back={<BackActionButton to="/schedule" />}
      title="Pickup History">
      <Filter {...props} style={{ width: '100%' }}>
        <FilterDateInput
          options={dateFieldOptions}
          datePickerFormat={datePickerFormat}
          icon={AccessTimeIcon}
          label="Date"
          source="date"
          allowEmpty
        />
        <ReferenceArrayInput
          icon={LocalShippingOutlinedIcon}
          basePath={props.basePath}
          label="Shipping"
          source="provider"
          reference="deliveryProviders"
          filter={{ marketingOnly: false }}
          perPage={999}
          allowEmpty>
          <ChipsArrayInput optionValue="code" optionAvatar="logoUrl" optionLabel="shortName" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          icon={CheckCircleOutlineIcon}
          basePath={props.basePath}
          label="Status"
          source="status"
          reference="singlePickupStatuses"
          allowEmpty>
          <WrapperStatusInput />
        </ReferenceArrayInput>
      </Filter>
    </ActionToolbar>
  );
};

const PickupHistory = (props) => {
  const defaultFilters = useSelector(
    ({ filters }) => ({
      ...filters['pickupHistory'],
    }),
    shallowEqual
  );

  const [statuses, { loaded }] = usePickupStatuses({ returnAsObject: true });
  if (!loaded) return null;
  return (
    <>
      <List
        {...props}
        resource="pickupHistory"
        basePath={props.location.pathname}
        filters={<PickupHistoryFilter />}
        filter={{
          ...{
            ...(defaultFilters ? { ...defaultFilters } : {}),
            marketingOnly: false,
          },
        }}
        sort={{ field: 'scheduledDate', order: 'DESC' }}
        bulkActionButtons>
        <Datagrid>
          <DateTimeField label="Date" />
          <ProviderField label="Provider" imageSource="account.provider.logoUrl" />
          <TypeField label="Type" source="name" sortable={false} />
          <TextField label="From" source="warehouse.name" sortable={false} />
          <TextField label="Confirmation number" source="providerPickupId" sortable={false} />
          <ChipWithListField chips={statuses} source="status" sortable={false} />
        </Datagrid>
      </List>
    </>
  );
};

export default PickupHistory;
