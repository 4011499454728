import React from 'react';
import {
  Typography,
  Button,
  Box,
  InputAdornment,
  makeStyles,
  Link,
  Card,
  FormControl,
  Popover,
  InputLabel,
} from '@material-ui/core';
import { useField } from 'react-final-form';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import { Input } from 'components/form';
import NumberInputFieldWithActions from 'components/inputs/NumberInputFieldWithActions';
import Radio from 'components/form/Radio';
import RadioGroup from 'components/form/RadioGroup';
import { DateInputField } from 'components/inputs/DateInput';

import { ReactComponent as InfoIcon } from 'assets/shipments/infoIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/shipments/deleteIcon.svg';

import { composeValidators, positive, required } from 'components/form/validationRules';
import { ImageInputField } from './ImageInputField';
import { ReactComponent as ProofOfValue } from 'assets/claims/proof-of-value.svg';
import { ClaimType, PhotoType } from '../types';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4, 4, 4),
    gap: '24px',
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    margin: theme.spacing(0, -3),
    padding: '12px',
    borderRadius: '16px',
    backgroundColor: '#FAFAFC',
  },
  title: {
    display: 'flex',
    gap: '16px',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  descriptionLink: {
    display: 'flex',
    gap: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  inputsContainer: {
    display: 'flex',
    gap: '24px',
  },
  date: {
    width: '50%',
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
  currencyIcon: {
    fontSize: 24,
    paddingBottom: 5,
  },
  link: {
    color: '#6C13F9',
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtext: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#969AB2',
  },
  addButton: {
    width: '100%',
  },
  deleteButton: {
    cursor: 'pointer',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    width: '256px',
  },
  popoverText: {
    fontSize: '13px',
    lineHeight: '16px',
  },
  radioLabel: {
    color: theme.palette.common.black,
    '&.Mui-focused': {
      color: theme.palette.common.black,
    },
  },
  replacementLabel: {
    fontSize: 14,
    color: '#969AB2',
    marginTop: '16px',
  },
  itemIcon: {
    color: '#969AB2',
  },
}));

interface Props {
  isUPS?: boolean;
}
const LostItemSection = ({ isUPS }: Props) => {
  const classes = useStyles();

  const {
    input: { value: items, onChange },
  } = useField('items');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAddClick = () => {
    onChange([
      ...items,
      {
        description: '',
        quantity: '',
        unitValue: '',
        replaceable: 'false',
        itemsMissing: 'false',
        dangerousContents: 'NONE',
        sealedSafe: 'false',
        index: Math.max(...items.map((item) => item.index)) + 1,

        ...(!isUPS && {
          amount: '',
          purchaseDate: '',
        }),
      },
    ]);
  };

  const handleDelete = (index: number) => {
    const newItems = items.filter((_item, i) => i !== index);
    onChange(newItems);
  };

  return (
    <Card title="Items" className={classes.card}>
      {items.map((_item, index) => (
        <Box key={index} className={classes.container}>
          <Box className={classes.cardTitle}>
            <Box className={classes.title}>
              <FormatListBulletedIcon className={classes.itemIcon} />
              <Typography>ITEM {items?.length > 1 ? index + 1 : ''}</Typography>
            </Box>
            {items.length > 1 && (
              <DeleteIcon className={classes.deleteButton} onClick={() => handleDelete(index)} />
            )}
          </Box>
          <Box className={classes.description}>
            <>
              <Input
                name={`items[${index}].description`}
                label="Description"
                fullWidth
                validate={required}
                multiline
                inputProps={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}>
                        <InfoIcon />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <Popover
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus>
                <Typography className={classes.popoverText}>
                  Detailed item descriptions are needed for all UPS claims to expedite processing.
                  Be as specific as possible (product name, brand name, serial number, size, color,
                  etc.) Additional items must be added separately.
                </Typography>
              </Popover>
            </>

            <Typography className={classes.subtext}>
              Example: Old Navy men's classic crew neck jumper, hunter green, size M, 22231 BL.
            </Typography>

            <Box className={classes.descriptionLink}>
              <Typography className={classes.subtext}>
                Be specific as possible: part #, serial #, model, color, size.
              </Typography>
              {isUPS && (
                <Link
                  className={classes.link}
                  href="https://www.ups.com/us/en/support/file-a-claim/supporting-documents.page"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="always">
                  UPS Merchandise description
                </Link>
              )}
            </Box>
          </Box>

          <Box className={classes.inputsContainer}>
            {!isUPS && (
              <NumberInputFieldWithActions
                name={`items[${index}].amountRequested`}
                label="Amount requested"
                actionButtons
                validate={required}
                isInteger
              />
            )}

            <NumberInputFieldWithActions
              name={`items[${index}].quantity`}
              label="Quantity of items missing"
              actionButtons
              validate={composeValidators(required, positive)}
              isInteger
            />

            <NumberInputFieldWithActions
              name={`items[${index}].unitValue`}
              label="Unit value"
              startAdornment={<span className={classes.currencyIcon}>$</span>}
              validate={required}
            />
          </Box>

          {isUPS && (
            <div>
              <InputLabel className={classes.replacementLabel}>
                If a replacement shipment has been sent, please provide the tracking number
              </InputLabel>
              <Input
                name={`items[${index}].replacementTrackingNumber`}
                label="Replacement tracking number"
                fullWidth
              />
            </div>
          )}

          {isUPS && (
            <RadioGroup
              name={`items[${index}].replaceable`}
              label="Could this merchandise replaced for your customer?"
              labelClass={classes.radioLabel}>
              <Radio label="Yes" name={`items[${index}].replaceable`} value={'true'} />
              <Radio label="No" name={`items[${index}].replaceable`} value={'false'} />
            </RadioGroup>
          )}

          {!isUPS && (
            <Box className={classes.date}>
              <FormControl fullWidth>
                <DateInputField
                  label="Purchase Date"
                  name={`items[${index}].purchaseDate`}
                  validate={required}
                />
              </FormControl>
            </Box>
          )}
        </Box>
      ))}

      <Button
        variant="outlined"
        size="large"
        className={classes.addButton}
        onClick={handleAddClick}>
        Add Item
      </Button>

      {isUPS && (
        <ImageInputField
          name="lost.POV_INVOICE_OR_BOS"
          text="Original copy of invoice"
          icon={<ProofOfValue />}
          photoType={PhotoType.PovInvoiceOrBos}
          sectionType="package"
          claimType={ClaimType.Lost}
        />
      )}
    </Card>
  );
};

export default LostItemSection;
