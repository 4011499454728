export const isCompleteDigitalZipCode = (code: string | undefined): boolean => {
  return typeof code === 'string' && /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/.test(code);
};

export const isDigitalZipCode = (code: string | undefined): boolean => {
  return (
    typeof code === 'string' &&
    (/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/.test(code) || !isNaN(Number(code)))
  );
};
