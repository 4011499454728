import React, { memo, useState, useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';
import {
  Grid,
  Tooltip,
  Select,
  ListSubheader,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { get, isEqual } from 'lodash';
import { useDataProvider } from 'react-admin';
import classNames from 'classnames';

import { Provider } from 'icons';
import { InfoOutlined } from '@material-ui/icons';
import { GroupTitle } from 'components/common/Group';
import { Input, NumberInput, PackageTypeSelect, Switch } from 'components/form';
import {
  composeValidators,
  required,
  requiredIfEmpty,
  positive,
  alwaysCorrect,
} from 'components/form/validationRules';
import { LogoImage } from 'components/common';
import ShippingPresetChooser from 'pages/calculator/components/ShippingPresetChooser';
import Selected from 'icons/Selected';
import useCustomNotify from 'hooks/useCustomNotify';
import { lbWeightConversion, ozWeightConversion } from 'services/helpers/convertWeight';

const PACKAGE_CODES_WITH_EDITABLE_DIMENSIONS = ['LETTER', 'LEGAL_LETTER'];

const PACKAGE_CODES_NO_HEIGHTS = ['SOFT_PACK', 'LETTER', 'LEGAL_LETTER'];

const useStyles = makeStyles((theme) => ({
  leftColumn: {
    paddingRight: theme.spacing(4),
  },
  withAdornment: {
    position: 'relative',
    paddingRight: theme.spacing(5),

    '&:last-child': {
      paddingRight: theme.spacing(3),

      '& $adornment': {
        right: 0,
      },
    },
  },
  serviceSelect: {
    width: '100%',
    marginTop: 20,
  },
  packageForm: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.primary.light,
  },
  adornment: {
    position: 'absolute',
    top: theme.spacing(4.75),
    right: theme.spacing(2),
  },
  carrierLogoContainer: {
    borderRadius: 4,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 90,
    maxWidth: 120,
  },
  active: {
    borderColor: '#6C13F9',
    justifyContent: 'flex-end',
  },
  nonActive: {
    borderColor: theme.palette.divider,
  },
  carrierSelect: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  carriersLabel: {
    marginRight: 50,
  },
  select: {
    '&:before': {
      borderBottomColor: '#E0E0E0',
    },

    '&:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.primary.main,
    },

    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
      padding: '8px 0 13px',
    },

    '& .MuiSelect-icon': {
      top: theme.spacing(0.5),
      color: theme.palette.primary.light,
    },
  },
  switchLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    marginLeft: 3,
  },
  tooltip: {
    maxWidth: '430px',
    padding: 10,
    borderRadius: '16px',
    lineHeight: 1.2,
    zIndex: 3,
    fontSize: '16px',
    marginLeft: 5,
  },
  emptyServices: {
    padding: theme.spacing(2),
  },
  carrierLogoImage: {
    margin: 0,
    border: 'none',
    alignSelf: 'center',
  },
  carrierSelectedCheckmark: {
    width: 24,
    height: 18,
    alignSelf: 'flex-end',
    marginBottom: 4,
    marginRight: 4,
  },
  carriersContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dimensionsAlert: {
    color: theme.palette.secondary.contrastText,
    fontSize: '14px',
    fontWeight: 700,
    marginTop: '24px',
  },
}));

const FormLeftColumn = (props) => {
  const {
    carriers,
    form,
    values,
    packageType,
    onTooltipClick,
    isAdjustForm,
    showGroupSwitch,
    groupSettings,
    updatedGroupRule,
    deliveryTimes,
  } = props;

  const [prevPackageType, setPrevPackageType] = useState(undefined);
  const [prevPresetId, setPrevPresetId] = useState(undefined);
  const [serviceLoading, setServiceLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [serviceError, setServiceError] = useState<boolean>(false);
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useCustomNotify();

  const getAvailableServices = (packageInfo, form) => {
    const requestedCarriers = carriers?.filter(
      (carrier) => values.selectedCarriers?.includes(carrier.provider.code)
    );

    if (!requestedCarriers?.length) return Promise.reject();

    let newAvailableServices: any = [];

    return Promise.all(
      requestedCarriers.map((carrier) => {
        return dataProvider
          .get('deliveryServices', {
            account: carrier.id,
            type: packageInfo?.type || 'PACKAGE',
            'weight.lb': packageInfo?.weight?.lb || 0,
            'weight.oz': packageInfo?.weight?.oz || 0,
            'size.length': packageInfo?.size?.length || 0,
            'size.height': packageInfo?.size?.height || 0,
            'size.width': packageInfo?.size?.width || 0,
            filter: 'DOMESTIC',
          })
          .then(({ data }) => {
            const mappedResponse = data.map((service) => ({
              ...service,
              carrier: carrier.provider.code,
            }));
            newAvailableServices = [...newAvailableServices, ...mappedResponse];
          })
          .catch((error) => notify(error.message));
      })
    )
      .then(() => {
        form.change('availableServices', [...deliveryTimes, ...newAvailableServices]);
      })
      .catch((error) => notify(error.message));
  };

  const isDeliveryTimeSelected = useMemo(
    () => deliveryTimes.some((time) => time.code === values.selectedService),
    [deliveryTimes, values.selectedService]
  );

  const isPackageInfoChanged = useMemo(
    () =>
      !!(updatedGroupRule
        ? !isEqual(updatedGroupRule?.packageInfo[0]?.type, values?.packageInfo[0]?.type) ||
          !isEqual(updatedGroupRule?.packageInfo[0]?.size, values?.packageInfo[0]?.size) ||
          !isEqual(updatedGroupRule?.packageInfo[0]?.weight, values?.packageInfo[0]?.weight)
        : !isEqual(groupSettings?.packageInfo?.weight, values?.packageInfo[0]?.weight) ||
          !isEqual(groupSettings?.packageInfo?.size, values?.packageInfo[0]?.size) ||
          !isEqual(groupSettings?.packageInfo?.type, values?.packageInfo[0]?.type)),
    [groupSettings?.packageInfo, updatedGroupRule, values?.packageInfo]
  ); // eslint-disable-line

  useEffect(() => {
    if (isPackageInfoChanged && !isDeliveryTimeSelected) {
      form.change('selectedService', '');
    }
  }, [isPackageInfoChanged, values.packageInfo]); // eslint-disable-line

  const handleCarrierSelect = (form, carrier, isCurrentCarrierSelected) => {
    const selectedPackageProvider = values.packageInfo[0].provider;

    const selectedCarriers = isCurrentCarrierSelected
      ? values.selectedCarriers?.filter((item) => item !== carrier.provider.code)
      : [...values.selectedCarriers, carrier.provider.code];

    form.batch(() => {
      form.change('selectedCarriers', selectedCarriers);

      if (!selectedCarriers.includes(selectedPackageProvider) && selectedPackageProvider !== null) {
        // Reset package info fields
        form.change('packageInfo[0].type', '');
        form.change('packageInfo[0].size.width', '');
        form.change('packageInfo[0].size.height', '');
        form.change('packageInfo[0].size.length', '');
        form.change('packageInfo[0].weight.lb', '');
        form.change('packageInfo[0].weight.oz', '');
        form.change('packageInfo[0].provider', null);
      }

      if (!isDeliveryTimeSelected) form.change('selectedService', '');
    });
  };

  const handleChange = (event) => {
    const currentServiceCarrier = values.availableServices?.find(
      (service) => service.code === event.target.value
    )?.carrier;

    form.batch(() => {
      form.change('selectedService', event.target.value);
      form.change('requestCarrier', currentServiceCarrier ? [currentServiceCarrier] : []);
    });
  };

  const handleServiceOpen = () => {
    const { packageInfo } = values;

    if (
      (!packageInfo[0]?.weight?.lb && !packageInfo[0]?.weight?.oz) ||
      !packageInfo[0]?.size?.width ||
      !packageInfo[0]?.size?.height ||
      !packageInfo[0]?.size?.length ||
      !packageInfo[0]?.type
    ) {
      setServiceError(true);
      return;
    }

    setIsOpen(true);
    setServiceLoading(true);
    setServiceError(false);

    getAvailableServices(packageInfo[0], form)
      .then(() => setServiceLoading(false))
      .catch(() => {
        setServiceLoading(false);
      });
  };

  const presetId = get(values, 'packageInfo[0].id');
  const isFlat = !!packageType?.flatRate;
  const isNoHeight = !!PACKAGE_CODES_NO_HEIGHTS.includes(packageType?.code);

  if (packageType && packageType?.code !== prevPackageType && presetId === prevPresetId) {
    form.change('packageInfo[0].id', null);
    form.change('packageInfo[0].size.length', packageType.length ?? null);
    form.change('packageInfo[0].size.width', packageType.width ?? null);
    form.change('packageInfo[0].size.height', packageType.height ?? null);
    form.change('packageInfo[0].weight.lb', isFlat ? packageType?.maxWeightLb ?? 0 : null);
    form.change('packageInfo[0].weight.oz', isFlat ? packageType?.maxWeightOz ?? 0 : null);
    form.change('packageInfo[0].provider', packageType?.provider ?? null);
  }

  if (packageType?.code !== prevPackageType) {
    setPrevPackageType(packageType?.code);
  }

  if (presetId !== prevPresetId) setPrevPresetId(presetId);

  return (
    <Grid item xs={isAdjustForm ? 12 : 6} className={classes.leftColumn}>
      <GroupTitle icon={<Provider />}>Shipping</GroupTitle>
      <Grid container className={classes.packageForm}>
        <Grid item xs={12} className={classes.carrierSelect}>
          <Field
            name="selectedCarriers"
            validate={required}
            render={({ meta: { touched, error } }) => {
              const isError = !!(touched && error);
              return (
                <>
                  <div>
                    <Typography color="textPrimary" className={classes.carriersLabel}>
                      Carrier
                    </Typography>
                    {isError && <FormHelperText error>{error}</FormHelperText>}
                  </div>
                  <Grid container spacing={2} className={classes.carriersContainer}>
                    {carriers?.map((carrier) => {
                      const isCurrentCarrierSelected = values.selectedCarriers?.includes(
                        carrier.provider.code
                      );
                      return (
                        <Grid item xs={3} key={carrier.id}>
                          <div
                            onClick={() =>
                              handleCarrierSelect(form, carrier, isCurrentCarrierSelected)
                            }
                            className={classNames(
                              classes.carrierLogoContainer,
                              isCurrentCarrierSelected ? classes.active : classes.nonActive
                            )}>
                            <LogoImage
                              className={classes.carrierLogoImage}
                              src={carrier.provider.logoUrl}
                            />
                            {isCurrentCarrierSelected && (
                              <Selected className={classes.carrierSelectedCheckmark} />
                            )}
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.dimensionsAlert}>
            Enter package dimensions and weight accurately to receive a valid quote and avoid
            penalties.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ShippingPresetChooser name="packageInfo[0]" />
        </Grid>
        <Grid item xs={12}>
          <PackageTypeSelect
            name="packageInfo[0].type"
            label="Type"
            validate={required}
            carriers={values.selectedCarriers}
          />
        </Grid>
        <Grid container>
          <Grid item xs={isNoHeight ? 6 : 4} className={classes.withAdornment}>
            <NumberInput
              name="packageInfo[0].size.width"
              label="Width"
              validate={composeValidators(required, positive)}
              disabled={
                !!packageType &&
                !!packageType.width &&
                !PACKAGE_CODES_WITH_EDITABLE_DIMENSIONS.includes(packageType.code)
              }
            />
            <span className={classes.adornment}>x</span>
          </Grid>
          {!isNoHeight && (
            <Grid item xs={isNoHeight ? 6 : 4} className={classes.withAdornment}>
              <NumberInput
                name="packageInfo[0].size.height"
                label="Height"
                validate={composeValidators(required, positive)}
                disabled={!!packageType && !!packageType.height}
              />
              <span className={classes.adornment}>x</span>
            </Grid>
          )}
          <Grid item xs={isNoHeight ? 6 : 4} className={classes.withAdornment}>
            <NumberInput
              name="packageInfo[0].size.length"
              label="Length"
              validate={composeValidators(required, positive)}
              disabled={
                !!packageType &&
                !!packageType.length &&
                !PACKAGE_CODES_WITH_EDITABLE_DIMENSIONS.includes(packageType.code)
              }
            />
            <span className={classes.adornment}>in</span>
          </Grid>
        </Grid>
        {!isFlat && (
          <>
            <Grid item xs={6} className={classes.withAdornment}>
              <NumberInput
                key={`weightLb_${values.packageType}`}
                name="packageInfo[0].weight.lb"
                label="Weight"
                disabled={isFlat}
                validate={composeValidators(
                  isFlat ? alwaysCorrect() : requiredIfEmpty('packageInfo[0].weight.oz')
                )}
                onChange={lbWeightConversion(
                  form,
                  'packageInfo[0].weight.lb',
                  'packageInfo[0].weight.oz'
                )}
              />
              <span className={classes.adornment}>lb</span>
            </Grid>
            <Grid item xs={6} className={classes.withAdornment}>
              <NumberInput
                key={`weightOz_${values.packageType}`}
                name="packageInfo[0].weight.oz"
                label=" "
                disabled={isFlat}
                validate={composeValidators(
                  isFlat ? alwaysCorrect() : requiredIfEmpty('packageInfo[0].weight.lb')
                )}
                onChange={ozWeightConversion(
                  form,
                  'packageInfo[0].weight.lb',
                  'packageInfo[0].weight.oz'
                )}
              />
              <span className={classes.adornment}>oz</span>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Field
            name="selectedService"
            validate={required}
            render={({ meta: { touched, error } }) => {
              const isError = !!(touched && error);
              return (
                <FormControl className={classes.serviceSelect}>
                  <InputLabel>Choose Delivery Time or Service</InputLabel>
                  <Select
                    className={classes.select}
                    label="Choose Service"
                    error={isError || serviceError}
                    disabled={!values.packageInfo[0].type}
                    value={values.selectedService}
                    onChange={handleChange}
                    onOpen={handleServiceOpen}
                    onClose={() => setIsOpen(false)}
                    renderValue={() =>
                      values.availableServices?.find(
                        (service) => service.code === values.selectedService
                      )?.name
                    }
                    fullWidth={true}
                    open={isOpen}>
                    <ListSubheader>Delivery Time</ListSubheader>
                    {deliveryTimes.map((time) => (
                      <MenuItem key={time.code} value={time.code}>
                        {time.name}
                      </MenuItem>
                    ))}
                    {serviceLoading ? (
                      <CircularProgress />
                    ) : values.availableServices?.some(
                        (service) => values.selectedCarriers?.includes(service.carrier)
                      ) ? (
                      carriers
                        .filter(
                          (carrier) =>
                            values.availableServices?.some(
                              (service) => service.carrier === carrier.provider.code
                            )
                        )
                        .map((carrier) => [
                          <ListSubheader>{carrier.provider.code}</ListSubheader>,
                          values.availableServices
                            ?.filter(
                              (service) =>
                                service.carrier === carrier.provider.code && service.enabled
                            )
                            .map((service) => (
                              <MenuItem key={service.code} value={service.code}>
                                {service.name}
                              </MenuItem>
                            )),
                        ])
                    ) : (
                      <Typography style={{ padding: 15 }}>No options found</Typography>
                    )}
                  </Select>
                  {isError && <FormHelperText error>{error}</FormHelperText>}
                  {serviceError && (
                    <FormHelperText error={serviceError}>
                      Please, fill in parameters above
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Switch
              name="isPackageSave"
              fullWidth={false}
              label={
                <span className={classes.switchLabel}>
                  Save as New Package Preset
                  <Tooltip
                    title="Save this package information (type, dimensions, and weight) as a Preset to quickly ship similar packages in the future."
                    placement={'bottom-start'}
                    enterDelay={300}
                    classes={{ tooltip: classes.tooltip }}
                    onClick={onTooltipClick}>
                    <InfoOutlined fontSize="small" className={classes.infoIcon} />
                  </Tooltip>
                </span>
              }
            />
          </Grid>
          {values.isPackageSave && (
            <Grid item xs={6}>
              <Input
                name="packageName"
                label="Package Preset Name"
                inputProps={{ inputProps: { maxLength: 50 } }}
                validate={required}
              />
            </Grid>
          )}
        </Grid>
        {!isAdjustForm && showGroupSwitch && (
          <Grid item xs={12}>
            <Switch
              name="isGroupSave"
              fullWidth={false}
              label={
                <span className={classes.switchLabel}>
                  Save as New Shipment Rule
                  <Tooltip
                    title="Save the current selection as a shipment rule for future orders that match the same items and quantities."
                    placement={'bottom-start'}
                    enterDelay={300}
                    classes={{ tooltip: classes.tooltip }}
                    onClick={onTooltipClick}>
                    <InfoOutlined fontSize="small" className={classes.infoIcon} />
                  </Tooltip>
                </span>
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(FormLeftColumn);
