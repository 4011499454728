import React from 'react';
import { makeStyles, FormHelperText } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { Field } from 'react-final-form';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2),
    '& .MuiInput-underline:before': {
      borderColor: '#E0E0E0',
    },
    '& .MuiInput-underline:hover:before': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.secondary.contrastText,
    },
  },
}));

const DateInput = (props) => {
  const handleChange = (value) => {
    const {
      input: { onChange },
      datePickerFormat = null,
    } = props;
    onChange(
      value
        ? datePickerFormat
          ? moment(value).format(datePickerFormat)
          : moment(value).toISOString()
        : null
    );
  };

  const classes = useStyles();

  const {
    label,
    input: { value },
    meta: { touched, error },
  } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        <DatePicker
          className={classes.input}
          autoOk
          label={label}
          clearable
          views={['year', 'month', 'date']}
          format="LL"
          value={value ? value : null}
          onChange={handleChange}
        />
        {touched && error && <FormHelperText error>{error}</FormHelperText>}
      </>
    </MuiPickersUtilsProvider>
  );
};

export const DateInputField = ({ name, label, ...restProps }) => (
  <Field name={name} label={label} component={DateInput} {...restProps} />
);

export default DateInput;
