import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Snackbar, Portal } from '@material-ui/core';

import ShippingProviderNotification from 'components/layout/ShippingProviderNotification';
import CommonNotification from 'components/layout/CommonNotification';

import { hideNotification } from 'store/actions/notificationActions';

const getNotifications = (state) => state.notifications;

const SnackbarSwitcher = (props) => {
  switch (props.notification.type) {
    case 'provider':
      return <ShippingProviderNotification {...props} />;
    default:
      return <CommonNotification {...props} />;
  }
};

const CustomNotification = () => {
  const [open, setOpen] = useState(false);
  const notifications = useSelector(getNotifications, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(notifications.length > 0);
  }, [notifications]);

  const onClose = useCallback(() => {
    dispatch(hideNotification('all'));
    setOpen(false);
  }, [dispatch]);

  return (
    <Portal>
      <Snackbar open={open} autoHideDuration={10000} onClose={onClose}>
        <div>
          {notifications.length > 0 &&
            notifications.map((notification) => {
              return <SnackbarSwitcher key={notification.id} notification={notification} />;
            })}
        </div>
      </Snackbar>
    </Portal>
  );
};

export default CustomNotification;
