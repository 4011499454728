import React, { useCallback } from 'react';
import { get } from 'lodash';
import { makeStyles, Chip } from '@material-ui/core';
import { useRedirect } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 25,
    fontSize: 14,
    color: theme.palette.primary.main,
    backgroundColor: '#f3e8fe',
    marginTop: theme.spacing(1),
  },
}));

const cutId = (id: string) => (id.length > 20 ? `...${id.substring(id.length - 17)}` : id);

const OrderIdField = (props) => {
  const { record, baseSource, className, source, isSearched, resource } = props;

  const classes = useStyles();
  const redirect = useRedirect();

  const sellerSource = `${baseSource ? `${baseSource}.` : ''}sellerOrderId`;
  const marketplaceSource = `${baseSource ? `${baseSource}.` : ''}marketplaceOrderId`;

  const id = get(record, sellerSource) || get(record, marketplaceSource) || get(record, source);
  const groupId: string = get(record, 'groupId');

  const handleOpenGroup = useCallback(
    (e) => {
      e.stopPropagation();
      redirect(`/${resource}/groups/${groupId}/show`);
    },
    [groupId, redirect, resource]
  );

  if (!id) {
    return null;
  }

  return (
    <>
      <div className={className} data-test="orderId">
        {cutId(id)}
      </div>
      {!!groupId && isSearched && (
        <Chip {...props} className={classes.chip} onClick={handleOpenGroup} label="Grouped" />
      )}
    </>
  );
};

export default OrderIdField;
