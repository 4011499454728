import React, { forwardRef } from 'react';
import { get } from 'lodash';
import { MenuItem } from '@material-ui/core';
import usePackingSlip from 'hooks/usePackingSlip';
import ActionButton from 'components/buttons/ActionButton';

const PrintPackingSlipButton = ({ data }) => {
  const { openingDocument, openDocument } = usePackingSlip('shipments', get(data, 'id'));

  if (!data.order) {
    return null;
  }

  return (
    <ActionButton data-test="packing-slip-button" disabled={openingDocument} onClick={openDocument}>
      Print Packing Slip
    </ActionButton>
  );
};

const PrintPackingSlipActionField = forwardRef((props: any, ref: any) => {
  const { openDocument } = usePackingSlip('shipments', get(props.record, 'id'));

  if (!props.record.order || props.record.type !== 'FORWARD') {
    return null;
  }

  return (
    <MenuItem ref={ref} onClick={openDocument}>
      Print packing slip
    </MenuItem>
  );
});

export { PrintPackingSlipButton, PrintPackingSlipActionField };
