import React, { memo } from 'react';
import { SvgIcon } from '@material-ui/core';

const OrderItems = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      id="icon/order-contents"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4C0 1.79086 1.79086 0 4 0H12ZM12 2H4C2.94564 2 2.08183 2.81588 2.00549 3.85074L2 4V12C2 13.0544 2.81588 13.9182 3.85074 13.9945L4 14H12C13.0544 14 13.9182 13.1841 13.9945 12.1493L14 12V4C14 2.94564 13.1841 2.08183 12.1493 2.00549L12 2ZM4 6C4 6.55228 4.44772 7 5 7C5.55228 7 6 6.55228 6 6C6 5.44772 5.55228 5 5 5C4.44772 5 4 5.44772 4 6ZM4 10C4 10.5523 4.44772 11 5 11C5.55228 11 6 10.5523 6 10C6 9.44771 5.55228 9 5 9C4.44772 9 4 9.44771 4 10ZM11 5H9C8.44771 5 8 5.44772 8 6C8 6.55228 8.44771 7 9 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5ZM11 9H9C8.44771 9 8 9.44771 8 10C8 10.5523 8.44771 11 9 11H11C11.5523 11 12 10.5523 12 10C12 9.44771 11.5523 9 11 9Z"
      fill="#6C13F9"
    />
  </SvgIcon>
);

export default memo(OrderItems);
