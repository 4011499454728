import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Auth from '@aws-amplify/auth';
import { MenuItem, makeStyles } from '@material-ui/core';
import { AccountCircleOutlined, ExitToAppOutlined, PersonOutline } from '@material-ui/icons';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import { Billing } from 'icons';
import DropdownTab from './DropdownTab';
import UserName from './UserName';

import { setFilters as actionSetFilters } from 'store/actions/filtersActions';

const storage: Storage = window.localStorage;

const useStyles = makeStyles((theme) => ({
  tab: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(4),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

// Reset sorting before open the contacts page
const contactsDefaultSearchParams = new URLSearchParams({
  page: '1',
  perPage: '10',
  sort: 'fullName',
  order: 'ASC',
  filter: '',
}).toString();

const UserMenu = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleLogout = async () => {
    storage.removeItem('shipmentDate');
    localStorage.removeItem('verificationClosed');
    await Auth.signOut();
    window.location.href = '/';
  };

  // Reset sorting before open the contacts page
  const handleResetContactsSearchFilter = () => {
    dispatch(actionSetFilters({ resource: 'contacts', filters: { q: '' } }));
  };

  return (
    <DropdownTab className={classes.tab} label={<UserName />}>
      <MenuItem component={NavLink} to="/profile">
        <AccountCircleOutlined className={classes.icon} /> Profile
      </MenuItem>
      <MenuItem component={NavLink} to="/billing">
        <Billing className={classes.icon} /> Billing
      </MenuItem>
      <MenuItem component={NavLink} to="/shippingPresets">
        <SettingsOutlinedIcon className={classes.icon} /> Settings
      </MenuItem>
      <MenuItem
        onClick={handleResetContactsSearchFilter}
        component={Link}
        to={{
          pathname: '/contacts',
          search: contactsDefaultSearchParams,
        }}>
        <PersonOutline className={classes.icon} /> Contacts
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ExitToAppOutlined className={classes.icon} /> Logout
      </MenuItem>
    </DropdownTab>
  );
};

export default UserMenu;
