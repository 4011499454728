import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TablePagination, Toolbar } from '@material-ui/core';
import { useTranslate, sanitizeListRestProps } from 'ra-core';

import PaginationActions from './PaginationActions';
import PaginationLimit from './PaginationLimit';

const Pagination = ({
  loading,
  page,
  perPage,
  rowsPerPageOptions,
  total,
  setPage,
  setPerPage,
  ...rest
}) => {
  useEffect(() => {
    if (page < 1 || isNaN(page)) {
      setPage(1);
    }
  }, [page, setPage]);
  const translate = useTranslate();

  const getNbPages = () => Math.ceil(total / perPage) || 1;

  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation();
      if (page < 0 || page > getNbPages() - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: page + 1,
          })
        );
      }
      setPage(page + 1);
    },
    [total, perPage, setPage, translate] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage]
  );

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      translate('ra.navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [translate]
  );

  if (total === 0) {
    return loading ? <Toolbar variant="dense" /> : <PaginationLimit />;
  }

  // @ts-ignore
  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      ActionsComponent={PaginationActions}
      component="span"
      labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={rowsPerPageOptions}
      {...sanitizeListRestProps(rest)}
    />
  );
};

Pagination.propTypes = {
  ids: PropTypes.array,
  loading: PropTypes.bool,
  page: PropTypes.number,
  perPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  total: PropTypes.number,
};

Pagination.defaultProps = {
  rowsPerPageOptions: [5, 10, 25],
};

export default React.memo(Pagination);
