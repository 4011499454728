import { Chip, createStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { get } from 'lodash';
import lottie from 'lottie-web';
import lottieScale from 'lottie-scale/dist';

import TrackingLoading from '../../assets/general/animations/TrackingLoading.json';

const styles = (theme) =>
  createStyles({
    chip: {
      height: 18,
      fontSize: 12,
      fontWeight: 'bold',
    },
    loadingChip: {
      '& .MuiChip-label': {
        padding: 0,
      },
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  });

const StatusField = ({
  classes,
  className = '',
  record = {},
  basePath = '',
  translateChoice,
  resource,
  markedAsShipped = false,
  trackingLoading = false,
  ...props
}) => {
  const element = document.getElementById('lottieAnimation');

  const animation = useMemo(
    () =>
      lottie.loadAnimation({
        loop: true,
        autoplay: true,
        animationData: TrackingLoading,
        container: element,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin meet',
        },
      }),
    [element]
  );

  if (trackingLoading) {
    animation.show();
  } else {
    animation.hide();
  }

  animation.addEventListener('DOMLoaded', () => {
    lottieScale(20, element);
  });

  return (
    <Chip
      className={classNames(classes.chip, className, trackingLoading && classes.loadingChip)}
      style={{
        backgroundColor: get(record, 'bgColor'),
        color: get(record, 'color'),
      }}
      label={
        <div className={classes.labelContainer}>
          <div
            id="lottieAnimation"
            style={{
              display: !trackingLoading ? 'none' : 'block',
            }}></div>
          {!trackingLoading && (!markedAsShipped ? get(record, 'name') : 'Marked As Shipped')}
        </div>
      }
      {...props}
    />
  );
};

export default withStyles(styles)(StatusField);
