import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'react-admin';
import { openDoc } from 'store/actions/lightboxActions';
import useCustomNotify from 'hooks/useCustomNotify';

const useGroupPackingSlips = (
  type: string,
  id: string | null,
  ids: string[] | null,
  isGroup: boolean
) => {
  const [openingDocument, setOpeningDocument] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();
  const dispatch = useDispatch();

  const openDocument = async () => {
    try {
      if (openingDocument) {
        return;
      }

      setOpeningDocument(true);

      const {
        data: { url },
      } = await (isGroup
        ? dataProvider.getNest('groupPackingSlips', { type, id })
        : dataProvider.create('multiplePackingSlips', { type, data: ids }));

      dispatch(openDoc({ name: 'Packing Slip', url }));
    } catch (error) {
      notify(error.message, 'error');
    } finally {
      setOpeningDocument(false);
    }
  };

  return { openingDocument, openDocument };
};

export default useGroupPackingSlips;
