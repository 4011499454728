import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Selected = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="12" fill="#6C13F9" />
    <path
      d="M10.2929 15.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L11 13.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L11.7071 15.7071C11.5118 15.9024 11.2559 16 11 16C10.7441 16 10.4882 15.9024 10.2929 15.7071Z"
      fill="white"
    />
  </SvgIcon>
);

export default Selected;
