import {
  CircularProgress,
  createStyles,
  IconButton,
  InputAdornment,
  TextField as TextInput,
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { withStyles } from '@material-ui/styles';
import FilterChips from 'components/list/FilterChips';
import React from 'react';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginRight: theme.spacing(2),
    },
    textInput: {
      borderRadius: theme.spacing(3.5),
      backgroundColor: 'white',
      boxShadow: theme.shadows[1],

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderRadius: theme.spacing(3.5),
      },

      '& .MuiOutlinedInput-input': {
        padding: '12.5px 14px',
      },
    },
    startAdornment: {
      marginLeft: theme.spacing(-1.5),
      marginRight: theme.spacing(2),
    },
    endAdornment: {
      marginRight: theme.spacing(-1),
    },
    filterButton: {
      color: 'white',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      borderRadius: 0,
      borderBottomLeftRadius: theme.spacing(3.5),
      borderTopLeftRadius: theme.spacing(3.5),
      backgroundColor: '#C6C9E0',
      padding: theme.spacing(1.25, 2),
    },
  });

const SearchInput = ({ input, label, classes, filterButton, onSearchKeyUp, loading, ...props }) => (
  <div className={classes.root}>
    <TextInput
      fullWidth
      className={classes.textInput}
      {...input}
      onKeyDown={onSearchKeyUp || null}
      variant="outlined"
      placeholder={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className={classes.startAdornment}>
            {React.cloneElement(filterButton, {
              ...filterButton.props,
              className: classes.filterButton,
            })}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start" className={classes.endAdornment}>
            {loading ? (
              <CircularProgress size={25} style={{ marginRight: 10 }} />
            ) : (
              <IconButton type="submit">
                <SearchRoundedIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
    <FilterChips {...props} />
  </div>
);

export default withStyles(styles)(SearchInput);
