import React from 'react';
import { Collapse, createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.drawer,
    },

    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1.5, 2),
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(2, 2, 0, 0),
      backgroundColor: theme.palette.background.paper,
    },

    caption: {
      fontSize: 14,
      color: theme.palette.primary.light,
    },

    actions: {
      display: 'flex',

      '& > *': {
        marginLeft: theme.spacing(4),
      },
    },
  });

const BulkActions = ({
  classes,
  children,
  basePath,
  filterValues,
  resource,
  selectedIds,
  onUnselectItems,
}) => {
  return (
    <div className={classes.root}>
      <Collapse in={!!selectedIds.length}>
        <div className={classes.container}>
          <div className={classes.caption}>
            <b>{selectedIds.length}</b> selected
          </div>
          <div className={classes.actions}>
            {React.cloneElement(children, {
              basePath,
              filterValues,
              resource,
              selectedIds,
              onUnselectItems,
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(BulkActions);
