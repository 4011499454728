export const SET_DIALOG_STATE = 'Rollo/MarkOrderShipped/SET_DIALOG_STATE';

export interface SET_DIALOG_STATE_ACTION {
  readonly type: typeof SET_DIALOG_STATE;
  readonly payload: any;
}

export const showMarkOrderShippedDialog = (orderId) => ({
  type: SET_DIALOG_STATE,
  payload: {
    open: true,
    orderId,
  },
});

export const hideMarkOrderShippedDialog = () => ({
  type: SET_DIALOG_STATE,
  payload: {
    open: false,
    orderId: undefined,
  },
});
