import common from './common';
import lightbox from './lightbox';
import messagebox from './messagebox';
import notifications from './notifications';
import markOrderShipped from './markOrderShipped';
import refill from './refill';
import filters from './filters';
import navMenu from './navMenu';
import orders from './orders';
import duplicatingShipment from './duplicatingShipment';
import rolloDelivery from './rolloDelivery';
import verification from './verification';

export default {
  common,
  lightbox,
  messagebox,
  notifications,
  markOrderShipped,
  filters,
  refill,
  navMenu,
  orders,
  duplicatingShipment,
  rolloDelivery,
  verification,
};
