import React from 'react';
import { get } from 'lodash';
import { createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });

const EmailField = (props) => {
  const { classes, record, source, basePath, ...rest } = props;

  const value = get(record, source);

  return (
    <a data-test="email" className={classes.root} href={`mailto:${value}`} {...rest}>
      {value}
    </a>
  );
};

export default withStyles(styles)(EmailField);
