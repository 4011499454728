import { Chip, createStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { get } from 'lodash';

const styles = (theme) =>
  createStyles({
    chip: {
      height: 18,
      fontSize: 12,
      fontWeight: 'bold',
    },
  });

const ChipWithListField = ({ classes, className = '', chips = {}, record, source, ...props }) => {
  const value = get(record, source) || '';
  const chip = get(chips, value) || null;

  return (
    <Chip
      className={classNames(classes.chip, className)}
      style={{
        backgroundColor: get(chip, 'bgColor'),
        color: get(chip, 'color'),
      }}
      label={get(chip, 'name')}
      {...props}
    />
  );
};

export default withStyles(styles)(ChipWithListField);
