import React from 'react';
import { get } from 'lodash';
import { TwoLinesLabel } from 'components/common';
import addressToString from 'services/helpers/addressToString';

const CustomerField = ({
  record = {},
  source,
  nameSource = `${source}.name`,
  addressSource = source,
  basePath = '',
  ...rest
}) => {
  const name = get(record, nameSource);

  return (
    <TwoLinesLabel
      {...rest}
      primary={name}
      secondary={addressToString(get(record, addressSource))}
    />
  );
};

export default CustomerField;
