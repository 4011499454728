import { uniqueId } from 'lodash';

export const LIGHTBOX_OPEN = 'Rollo/LIGHTBOX_OPEN';
export const LIGHTBOX_CLOSE = 'Rollo/LIGHTBOX_CLOSE';
export const LIGHTBOX_CLOSE_ALL = 'Rollo/LIGHTBOX_CLOSE_ALL';

export const openDoc = (doc) => {
  return {
    type: LIGHTBOX_OPEN,
    payload: {
      id: uniqueId('doc_'),
      ...doc,
    },
  };
};

export const closeDoc = (id) => {
  return {
    type: LIGHTBOX_CLOSE,
    payload: {
      id,
    },
  };
};

export const closeAll = () => {
  return {
    type: LIGHTBOX_CLOSE_ALL,
  };
};
