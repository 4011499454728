import React from 'react';
import { useDispatch } from 'react-redux';
import { ProviderField } from 'components/fields';
import { ErrorOutlined, WarningOutlined, CloseOutlined } from '@material-ui/icons';
import { createStyles, IconButton, makeStyles, SnackbarContent } from '@material-ui/core';

import { hideNotification } from 'store/actions/notificationActions';

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'flex-start',
    },

    provider: {
      marginRight: theme.spacing(4.75),
      flexShrink: 0,
    },

    providerLogo: {
      backgroundColor: theme.palette.common.white,
    },

    providerPrimary: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },

    providerSecondary: {
      color: theme.palette.common.white,
    },

    item: {
      display: 'flex',

      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },

    itemDescription: {
      marginLeft: theme.spacing(2),
      overflowWrap: 'anywhere',
    },

    action: {
      color: theme.palette.common.white,
    },
  })
);

const useStylesSnackbarContent = makeStyles((theme) =>
  createStyles({
    root: {
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      backgroundColor: '#6C13F9',
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(1),
      fontSize: 16,
      width: '555px',
    },

    message: {
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      backgroundColor: '#6C13F9',
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },

    action: {
      position: 'relative',
      '&>button': {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '6px',
        right: '0px',
      },
    },
  })
);

const ShippingProviderNotification = ({ notification, handleRequestClose }) => {
  const classes = useStyles();
  const classesSnackbarContent = useStylesSnackbarContent();

  const dispatch = useDispatch();

  const handleNotificationClose = (id: string) => () => {
    dispatch(hideNotification(id));
  };

  if (!notification?.content?.alerts?.length && !notification?.content?.messages?.length) {
    return null;
  }

  const { content } = notification;

  return (
    <SnackbarContent
      key={content.accountInfo.id}
      className={classesSnackbarContent.message}
      classes={{
        root: classesSnackbarContent.root,
      }}
      message={
        <div className={classes.row}>
          <ProviderField
            classes={{
              root: classes.provider,
              logo: classes.providerLogo,
              primary: classes.providerPrimary,
              secondary: classes.providerSecondary,
            }}
            record={content}
            imageSource="accountInfo.provider.logoUrl"
            source="accountInfo.provider.code"
            secondarySource="accountInfo.alias"
          />
          <div>
            {content.alerts &&
              content.alerts.map((item, j) => (
                <div className={classes.item} key={`error_${j}`}>
                  <ErrorOutlined />
                  <div className={classes.itemDescription}>{item.description}</div>
                </div>
              ))}
            {content.messages &&
              content.messages.map((item, j) => (
                <div className={classes.item} key={`warning_${j}`}>
                  <WarningOutlined />
                  <div className={classes.itemDescription}>{item.description}</div>
                </div>
              ))}
          </div>
        </div>
      }
      action={
        <IconButton
          className={classes.action}
          size="small"
          onClick={handleNotificationClose(notification.id)}>
          <CloseOutlined />
        </IconButton>
      }
    />
  );
};

export default ShippingProviderNotification;
