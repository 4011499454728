import countries from './countries';
import packageTypesFull from 'services/data/responses/packageTypesFull';
import rates from './rates';
import stores from './stores';
import orders from 'services/data/responses/orders';
import orderStatuses from 'services/data/responses/orderStatuses';
import labelInfo from 'services/data/responses/labelInfo';
import shipments from 'services/data/responses/shipments';
import shipmentStatuses from 'services/data/responses/shipmentStatuses';
import deliveryProviders from 'services/data/responses/deliveryProviders';
import marketProviders from 'services/data/responses/marketProviders';
import warehousePresets from 'services/data/responses/warehousePresets';
import etsyShops from 'services/data/responses/etsyShops';
import paypalRedirectUrl from 'services/data/responses/paypalRedirectUrl';
import paymentOptions from 'services/data/responses/paymentOptions';
import scanForms from 'services/data/responses/scanForms';
import singlePickupStatuses from 'services/data/responses/singlePickupStatuses';
import scanFormsWarehouses from 'services/data/responses/scanFormsWarehouses';
import userServices from 'services/data/responses/userServices';

export default {
  countries,
  deliveryProviders,
  etsyShops,
  labelInfo,
  marketProviders,
  orders,
  orderStatuses,
  packageTypesFull,
  paymentOptions,
  paypalRedirectUrl,
  rates,
  scanForms,
  shipments,
  shipmentStatuses,
  stores,
  warehousePresets,
  singlePickupStatuses,
  scanFormsWarehouses,
  userServices,
};
