import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Refill = (props) => (
  <SvgIcon {...props}>
    <path
      d="M8.062 8a8.079 8.079 0 000 2.001L3 10v2l5.582.001a8.03 8.03 0 003.543 4L3 16v4h14l.001-3.062a7.947 7.947 0 002-.52L19 20c0 1.11-.89 2-2 2H3c-1.11 0-2-.89-2-2l.01-10c0-1.11.88-2 1.99-2zM17 15a7 7 0 110-14 7 7 0 010 14zm0-2a5 5 0 100-10 5 5 0 000 10zm1.293-7.207L16 8.085l-.793-.792a1 1 0 00-1.414 1.414l1.5 1.5a.998.998 0 001.414 0l3-3a1 1 0 10-1.414-1.414z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Refill;
