export const MESSAGEBOX_OPEN = 'Rollo/MESSAGEBOX_OPEN';
export const MESSAGEBOX_CLOSE = 'Rollo/MESSAGEBOX_CLOSE';

export interface Message {
  title: string;
  text: string;
  confirmText: string | null;
  cancelText: string;
}

export const openMessageBox = (message: Message, callback) => {
  return {
    type: MESSAGEBOX_OPEN,
    payload: {
      message,
      callback,
    },
  };
};

export const closeMessageBox = () => {
  return {
    type: MESSAGEBOX_CLOSE,
  };
};
