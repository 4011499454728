import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Declaration = (props) => (
  <SvgIcon {...props}>
    <path
      d="M9 16h4a1 1 0 110 2H9a1 1 0 110-2zm0-4h6a1 1 0 110 2H9a1 1 0 110-2zm0-4h6a1 1 0 110 2H9a1 1 0 110-2zm9-4h-3.18C14.4 2.84 13.3 2 12 2c-1.3 0-2.4.84-2.82 2H6c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h12c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-6-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM18 20H6V6h12z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Declaration;
