import { LogoField } from 'components/fields/index';
import React from 'react';
import { get } from 'lodash';
import { createStyles, Tooltip, withStyles, Avatar } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 0,
    },
  });

interface IStoreFieldProps {
  record?;
  logoSource?;
  labelSource?;
  source?;
  sortBy?;
  label?;
  classes?;
  tooltipSource?;
}

const StoreField = ({
  record,
  logoSource,
  labelSource,
  classes,
  tooltipSource,
}: IStoreFieldProps) => (
  <Tooltip
    title={tooltipSource ? get(record, tooltipSource) ?? 'Quick Shipment' : ''}
    placement={'bottom-start'}
    enterDelay={300}>
    <div className={classes.root} data-test="store">
      {!!get(record, logoSource) ? (
        <LogoField record={record} source={logoSource} />
      ) : (
        <Avatar
          src={`${process.env.REACT_APP_MEDIA_PROVIDER_URL}/market-service-providers/RolloApp/quick-shipment.png`}
          style={{ maxHeight: 30, maxWidth: 30 }}
        />
      )}
      {get(record, labelSource)}
    </div>
  </Tooltip>
);

export default withStyles(styles)(StoreField);
