export const TOGGLE_WELCOME_PAGE = 'Rollo/TOGGLE_WELCOME_PAGE';
export const HOME_PAGE_CREATE_SHIPMENT = 'Rollo/HOME_PAGE_CREATE_SHIPMENT';

export const toggleWelcomePage = (isOpen: boolean) => ({
  type: TOGGLE_WELCOME_PAGE,
  payload: isOpen,
});

export const homePageCreateShipment = (payload: any) => ({
  type: HOME_PAGE_CREATE_SHIPMENT,
  payload,
});
