import React from 'react';
import { createStyles, withStyles, MenuItem, Select } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      width: theme.spacing(20),
      flexShrink: 0,
      padding: theme.spacing(0, 2),
      margin: theme.spacing(2, 0),
      borderLeft: `1px solid ${theme.palette.divider}`,

      '&:before, &:after': {
        display: 'none',
      },

      '& .MuiSelect-select': {
        backgroundColor: 'transparent',
      },

      '& .MuiSelect-icon': {
        right: theme.spacing(2),
        color: theme.palette.primary.light,
      },
    },
  });

const CountrySelect = ({ classes, countries, value, onChange, countryDisabled = false }) => {
  if (!countries) {
    return null;
  }

  return (
    <Select className={classes.root} value={value} onChange={onChange} disabled={countryDisabled}>
      {countries.map((country) => (
        <MenuItem key={country.code} value={country.code}>
          {country.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default withStyles(styles)(CountrySelect);
