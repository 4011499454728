import React from 'react';
import classNames from 'classnames';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(0)} !important`,
  },
}));

const NameOptionsList = (props) => {
  const classes = useStyles();
  //console.log('Props:', props);

  if (!props.children) {
    return null;
  }

  return (
    <Paper {...props} className={classNames(classes.root, props.className)} elevation={8}>
      {props.children}
    </Paper>
  );
};

export default NameOptionsList;
