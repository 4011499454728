import React from 'react';
import { DatagridBody as RADatagridBody } from 'react-admin';
import DatagridRow from './DatagridRow';

const DatagridBody = (props) => {
  const {
    basePath,
    children,
    classes,
    className,
    data,
    expand,
    hasBulkActions,
    hover,
    ids,
    onToggleItem,
    resource,
    row,
    rowClick,
    rowStyle,
    selectedIds,
    styles,
    version,
    ...rest
  } = props;

  const datagridBodyProps = {
    basePath,
    children,
    classes,
    className,
    data,
    expand,
    hasBulkActions,
    hover,
    ids,
    onToggleItem,
    resource,
    row,
    rowClick,
    rowStyle,
    selectedIds,
    styles,
    version,
  };

  return <RADatagridBody {...datagridBodyProps} row={<DatagridRow {...rest} />} />;
};

export default DatagridBody;
