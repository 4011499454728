import { debounce } from 'lodash';
import { useCallback } from 'react';
import { changeListParams } from 'react-admin';
import { useDispatch } from 'react-redux';

export const useSilentControllerProps = (resource) => {
  const dispatch = useDispatch();

  const debouncedSetFilters = debounce((filter = {}) => {
    const query = { filter };

    dispatch(changeListParams(resource, query));
  }, 500);

  const setFilters = useCallback(debouncedSetFilters, []); //eslint-disable-line

  const setPage = useCallback((page = 1) => {
    const query = { page };
    dispatch(changeListParams(resource, query));
  }, []); //eslint-disable-line

  const setPerPage = useCallback((perPage = 10) => {
    const query = { perPage };
    dispatch(changeListParams(resource, query));
  }, []); //eslint-disable-line

  const setSort = useCallback((sort = {}) => {
    const query = { sort };
    dispatch(changeListParams(resource, query));
  }, []); //eslint-disable-line

  return { setFilters, setPage, setPerPage, setSort };
};
