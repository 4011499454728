import React, { forwardRef, useMemo } from 'react';
import { Button, MenuItem } from '@material-ui/core';
import useGroupPackingSlips from 'hooks/useGroupPackingSlips';

export const BulkPrintPackingSlipsButton = (props) => {
  const { type, ids, primary, isLoading } = props;
  const { openingDocument, openDocument } = useGroupPackingSlips(type, null, ids, false);
  const isDisabled = useMemo(() => isLoading || openingDocument, [isLoading, openingDocument]);
  return (
    <Button
      size="small"
      color={!!primary ? 'primary' : 'default'}
      variant={!!primary ? 'contained' : 'outlined'}
      disabled={isDisabled}
      onClick={openDocument}>
      Print Packing Slips
    </Button>
  );
};

export const BulkPrintPackingSlipsActionField = forwardRef((props: any, ref: any) => {
  const { type, id } = props;
  const { openDocument } = useGroupPackingSlips(type, id, null, true);
  return (
    <MenuItem ref={ref} onClick={openDocument}>
      Print Packing slips
    </MenuItem>
  );
});
