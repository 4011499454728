import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'react-admin';
import { openDoc } from 'store/actions/lightboxActions';
import useCustomNotify from 'hooks/useCustomNotify';

const usePrintLabel = ({
  id,
  isReprint = false,
  returnLabel = false,
}: {
  id?: string;
  isReprint?: boolean;
  returnLabel?: boolean;
}) => {
  const [printingLabel, setPrintingLabel] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useCustomNotify();
  const dispatch = useDispatch();

  const getPreferences = async () => {
    const { data: preferences } = await dataProvider.get('preferencesSettings');
    return preferences;
  };

  const onPrintLabel = async (printLabelAndPackingSlipTogether, localId) => {
    await dataProvider
      .getNest(
        printLabelAndPackingSlipTogether && !isReprint && !returnLabel
          ? 'labelInfoAndPackingSlip'
          : 'labelInfo',
        { id: id || localId }
      )
      .then(({ data: { url, noticeHtml } }) => {
        dispatch(openDoc({ name: 'Shipment Label', url, noticeHtml }));
      })
      .catch((err) => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setPrintingLabel(false);
      });
  };

  const printLabel = async (localId?: any) => {
    if (printingLabel) {
      return;
    }

    setPrintingLabel(true);

    try {
      const { printLabelAndPackingSlipTogether } = await getPreferences();
      await onPrintLabel(printLabelAndPackingSlipTogether, localId);
    } catch (err) {
      notify(err.message, 'error');
    }
  };

  return { printingLabel, printLabel };
};

export default usePrintLabel;
