import React, { useMemo } from 'react';
import { GET_LIST, useQueryWithStore } from 'react-admin';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import SelectBase from 'components/form/SelectBase';

const useStyles = makeStyles((theme) => ({
  countryIcon: {
    maxWidth: 22,
    maxHeight: 22,
    marginRight: theme.spacing(1),
  },
}));

const CountriesSelect = ({ name, label, ...rest }) => {
  const classes = useStyles();
  const { data, loading, error } = useQueryWithStore({
    type: GET_LIST,
    resource: 'countries',
    payload: { pagination: { perPage: 999 } },
  });

  const options = useMemo(
    () =>
      data?.map((country) => ({
        value: country.code,
        label: country.name,
        icon: country.iconUrl,
      })),
    [data]
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <p>error</p>;
  }

  return (
    <SelectBase label={label} name={name} {...rest} inputProps={{ readOnly: !!!options.length }}>
      {options.map((option) => (
        <MenuItem key={option.value.id || option.value} value={option.value}>
          <img src={option.icon} className={classes.countryIcon} alt="country" />
          {option.label}
        </MenuItem>
      ))}
    </SelectBase>
  );
};

export default CountriesSelect;
