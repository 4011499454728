import { SET_ROLLO_DELIVERY_AVAILABILITY } from 'store/actions/rolloDelivery';

interface State {
  rolloAccountId: string | null;
}

const INITIAL_STATE: State = {
  rolloAccountId: null,
};

const rolloDeliveryReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_ROLLO_DELIVERY_AVAILABILITY:
      return { ...state, rolloAccountId: payload };
    default:
      return state;
  }
};

export default rolloDeliveryReducer;
