export const SAVE_CURRENT_GROUP = 'Rollo/SAVE_CURRENT_GROUP';
export const SET_IS_GROUPED = 'Rollo/SET_IS_GROUPED';

export const saveCurrentGroup = (data) => ({
  type: SAVE_CURRENT_GROUP,
  payload: data,
});

export const setIsGrouped = (data) => ({
  type: SET_IS_GROUPED,
  payload: data,
});
