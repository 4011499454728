import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import { TwoLinesLabel } from 'components/common';

const DeliveryTimeField = ({
  record = {},
  source = '',
  dateSource = '',
  timeSource = '',
  ...rest
}) => {
  const value: number = get(record, source) ?? '';
  const arrivalDate: string = get(record, dateSource) ?? '';
  const arrivalTime: string = get(record, timeSource) ?? '';

  const deliveryDays = value ? `${value} ${value === 1 ? 'day' : 'days'}` : '-';

  return !arrivalDate ? (
    <Typography variant="body1">{deliveryDays}</Typography>
  ) : (
    <TwoLinesLabel
      primary={deliveryDays}
      secondary={
        !!arrivalTime
          ? `${moment(`${arrivalDate} ${arrivalTime}`).format('MMMM Do [by] h:mm a')}`
          : `${moment(arrivalDate).format('MMMM Do')}`
      }
    />
  );
};

export default memo(DeliveryTimeField);
