import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, Grid, Typography, Divider, Button, withStyles } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import formatPrice from 'services/helpers/formatPrice';

const styles = (theme) =>
  createStyles({
    label: {
      lineHeight: '20px',
      color: theme.palette.text.secondary,
    },

    price: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 'bold',
    },

    button: {
      padding: 0,
      'min-width': '32px',
    },

    root: {
      marginBottom: theme.spacing(1.5),
    },

    divider: {
      margin: theme.spacing(2, 0, 1.5),
      '&:last-child': {
        display: 'none',
      },
    },
  });

const AdjustmentDetails = ({
  classes,
  transaction,
}: {
  classes: any;
  transaction: {
    [key: string]: any;
  };
}) => {
  const { details, amount, description } = transaction;

  if (!amount) {
    return null;
  }

  return (
    <>
      <Grid
        className={classes.root}
        container
        justify="space-between"
        alignItems="center"
        data-test="labeled-field">
        <Typography variant="overline">{details.trackNumber}</Typography>
        <Button
          className={classes.button}
          size="small"
          variant="outlined"
          component={Link}
          to={`/shipments/${details.shipmentId}/show`}>
          <NavigateNext fontSize="small" />
        </Button>
      </Grid>
      {amount && (
        <Grid container justify="space-between" alignItems="center" data-test="labeled-field">
          <Typography className={classes.label} variant="overline">
            adjustment amount
          </Typography>
          <Typography className={classes.price} variant="overline">
            {formatPrice(amount.amount)}
          </Typography>
        </Grid>
      )}
      <Grid container justify="space-between" alignItems="center" data-test="labeled-field">
        <Typography variant="body2">{description}</Typography>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

export default withStyles(styles)(AdjustmentDetails);
