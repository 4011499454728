import React from 'react';
import { Form } from 'react-final-form';
import { Typography } from '@material-ui/core';

import { Input } from 'components/form';
import { required } from 'components/form/validationRules';
import Dialog from 'components/common/Dialog';

const FromPhoneModal = (props) => {
  const { setShowFixModal, showFixModal, handlePhoneSubmit } = props;

  const handleSubmit = (data) => {
    const { fromPhoneOverride } = data;
    setShowFixModal(false);
    handlePhoneSubmit(fromPhoneOverride);
  };

  const handleCancel = () => {
    setShowFixModal(false);
  };
  return (
    <Form
      initialValues={{}}
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting, form, values, ...renderProps }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Dialog
              open={showFixModal}
              confirmText={'Proceed'}
              title="Sender phone required"
              onCancel={handleCancel}
              onConfirm={handleSubmit}>
              <Typography>Some shipments require a sender phone number to be provided</Typography>
              <Input name="fromPhoneOverride" label="Sender's phone" validate={required} />
            </Dialog>
          </form>
        );
      }}
    />
  );
};

export default FromPhoneModal;
