import { makeStyles, TextField, FormHelperText, InputLabel } from '@material-ui/core';
import React from 'react';
import { useField } from 'react-final-form';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiInput-root.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.primary.main,
    },
  },
  input: {
    fontSize: 30,
    fontWeight: 700,
    '&.MuiInput-input': {
      padding: theme.spacing(1, 0),
    },
    '&:focus::placeholder': {
      color: 'transparent',
    },
  },
  root: {
    padding: theme.spacing(0, 3),
    boxSizing: 'border-box',
  },
  label: {
    fontSize: 12,
    marginTop: theme.spacing(3),
  },
  adornment: {
    fontSize: 30,
    color: theme.palette.secondary.contrastText,
  },
  inputRoot: {
    alignItems: 'baseline',
  },
  adornmentWithValue: {
    color: theme.palette.common.black,
  },
}));

interface PaymentAmountFieldProps {
  label: string;
  name: string;
  placeholder: string;
  handleChange: (value: string) => void;
  changeProxy: (value: string) => string;
  inputProps?: Record<string, any>;
  validate: (value: any, values: any) => any;
}

const PaymentAmountField = (props: PaymentAmountFieldProps) => {
  const classes = useStyles();

  const { label, name, handleChange, placeholder, validate, changeProxy, inputProps } = props;

  const {
    input: { value },
    meta: { touched, error },
  } = useField(name, { validate });

  const onChange = (event) => {
    handleChange(changeProxy(event.target.value));
  };

  return (
    <div className={classes.root}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <TextField
        className={classes.textField}
        variant="standard"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        type="text"
        InputProps={{
          classes: {
            input: classes.input,
            root: classes.inputRoot,
          },
          startAdornment: (
            <span className={classNames(classes.adornment, value && classes.adornmentWithValue)}>
              $
            </span>
          ),
        }}
        fullWidth
        {...inputProps}
      />
      {!!(touched && error) && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};

export default PaymentAmountField;
