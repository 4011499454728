import { Message, MESSAGEBOX_CLOSE, MESSAGEBOX_OPEN } from '../actions/messageboxActions';

interface State {
  message?: Message;
  callback?: (result: boolean) => void;
}

const INITIAL_STATE = {};

const MessageboxReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MESSAGEBOX_OPEN:
      return payload;

    case MESSAGEBOX_CLOSE:
      return {};

    default:
      return state;
  }
};

export default MessageboxReducer;
