import React, { useCallback, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { ReferenceInput, SelectInput, TextInput, useDataProvider, useRedirect } from 'react-admin';

import { Dialog } from 'components/common';
import ActionButton from 'components/buttons/ActionButton';
import FormErrorLabel from 'components/form/FormErrorLabel';
import { required } from 'components/form/validationRules';

const useMarkAsShipped = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>();

  const handleOpen = (id: string) => {
    setOpen(true);
    setOrderId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setOrderId(undefined);
  };

  return { open, onOpen: handleOpen, onClose: handleClose, orderId };
};

type MarkAsShippedDialogProps = {
  open: boolean;
  onClose: () => void;
  orderId?: string;
};

export const MarkAsShippedDialog = ({ open, onClose, orderId }: MarkAsShippedDialogProps) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const submit = useCallback(
    (data) =>
      dataProvider
        .put('markOrderShipped', { id: orderId, data })
        .then(({ data: { id } }) => {
          onClose();
          redirect('show', '/shipments', id);
          return;
        })
        .catch((err) => ({ [FORM_ERROR]: err.message })),
    [dataProvider, onClose, orderId, redirect]
  );

  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit, form: { reset }, submitting, ...renderProps }) => {
        // @ts-ignore
        const _handleSubmit = (event) => handleSubmit(event).then(reset);
        return (
          <Dialog
            title="Mark Order as Shipped"
            open={open}
            confirmDisabled={submitting}
            onConfirm={_handleSubmit}
            onCancel={() => onClose()}>
            <form onSubmit={_handleSubmit}>
              <ReferenceInput
                basePath="/orders"
                label="Provider"
                source="provider"
                reference="deliveryProviders"
                perPage={999}
                fullWidth
                variant="standard">
                <SelectInput optionText="shortName" optionValue="code" validate={required} />
              </ReferenceInput>
              <TextInput
                source="trackingNumber"
                variant="standard"
                label="Tracking Number"
                fullWidth
              />
              <FormErrorLabel {...renderProps} />
            </form>
          </Dialog>
        );
      }}
    />
  );
};

export const MarkAsShippedActionField = (props) => {
  const {
    record: { id },
  } = props;

  const { open, orderId, onOpen, onClose } = useMarkAsShipped();

  return (
    <>
      <MenuItem onClick={() => onOpen(id)}>Mark as Shipped</MenuItem>
      <MarkAsShippedDialog open={open} orderId={orderId} onClose={onClose} />
    </>
  );
};

export const MarkAsShippedActionButton = ({ id }) => {
  const { open, orderId, onOpen, onClose } = useMarkAsShipped();

  return (
    <>
      <ActionButton onClick={() => onOpen(id)}>Mark as Shipped</ActionButton>
      <MarkAsShippedDialog open={open} orderId={orderId} onClose={onClose} />
    </>
  );
};
