const mapRateEmailWarning = (rate, recipientTrackingEmail) => {
  if (rate.price?.messages) {
    const noEmailWarningCode = 'NO_EMAIL';

    const emailWarningIndex = rate.price?.messages?.findIndex(
      (message) => message?.code === noEmailWarningCode
    );

    if (recipientTrackingEmail && !rate?.orderEmail && emailWarningIndex === -1) {
      rate.price.messages.push({
        code: noEmailWarningCode,
        description: 'Recipient does not have email address on file',
      });
    } else if (!recipientTrackingEmail && emailWarningIndex !== -1) {
      delete rate.price?.messages[emailWarningIndex];
    }
  }

  return rate;
};

export default mapRateEmailWarning;
