import { GET_LIST, GET_ONE } from 'react-admin';

export default {
  [GET_LIST]: ({ response }) => ({
    ...response,
    json: response.json.map((item) => ({
      ...item,
      stateOrProvinceId: item.stateOrProvince.id,
    })),
  }),

  [GET_ONE]: ({ response }) => ({
    ...response,
    json: {
      ...response.json,
      stateOrProvinceId: response.json.stateOrProvince.id,
    },
  }),
};
