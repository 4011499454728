import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { useDispatch } from 'react-redux';

import { toggleClearShippingRates } from '../../store/actions/navMenuAction';

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.7, 3),
      borderRadius: '22px',
      border: '1px solid #C6C9E0',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
  });

const CalculatorButton = ({ classes, ...props }) => {
  const dispatch = useDispatch();

  const clearShippingRates = () => {
    dispatch(toggleClearShippingRates(true));
  };

  return (
    <div className={classes.root} color="secondary" onClick={clearShippingRates} {...props}>
      <AddOutlinedIcon className={classes.icon} />
      CREATE QUICK SHIPMENT
    </div>
  );
};

export default withStyles(styles)(CalculatorButton);
