import React from 'react';
import { List, ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { useQuery } from 'react-admin';
import { GET } from 'services/data/rest';

const styles = (theme) => {
  return createStyles({
    title: {
      margin: '0',
    },
    listItem: {
      color: theme.palette.primary.main,
      padding: '0rem 0',
    },
  });
};

const agreementTitle = {
  discount: 'UPS Terms and Conditions',
  prohibitedItems: 'List of Prohibited Articles for Shipping',
  uta: 'UPS Technology Agreement',
};

const UpsAgreements = (props) => {
  const { classes } = props;
  const {
    data: agreements,
    loading,
    error,
  } = useQuery({ type: GET, resource: 'upsAgreemets', payload: {} });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <p>Oops... something was going wrong</p>;
  }

  return (
    <>
      <p className={classes.title}>
        <strong>I have read and agree to the following Terms:</strong>
      </p>
      <List>
        {Object.keys(agreements).map((key, index) => (
          <ListItem
            key={key}
            className={classes.listItem}
            component="a"
            target="_blank"
            href={agreements[key]}>
            <ListItemText color="primary" primary={agreementTitle[key]} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default withStyles(styles)(UpsAgreements);
