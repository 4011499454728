import React from 'react';
import classNames from 'classnames';
import { useField } from 'react-final-form';

import { makeStyles, Divider, Typography, Link } from '@material-ui/core';

import SectionCard from './SectionCard';
import { LogoImage } from 'components/common';
import { Skeleton } from 'components/layout/Skeleton';
import NumberInputFieldWithActions from 'components/inputs/NumberInputFieldWithActions';

import addressToString from 'services/helpers/addressToString';
import formatPrice from 'services/helpers/formatPrice';

import { ReactComponent as FromIcon } from 'assets/shipments/fromIcon.svg';
import { ReactComponent as ToIcon } from 'assets/shipments/toIcon.svg';
import { ReactComponent as SummaryIcon } from 'assets/shipments/summaryIcon.svg';
import { required } from 'components/form/validationRules';

import { ClaimReason, Item } from '../types';

const useStyles = makeStyles((theme) => ({
  container: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  metaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  metaTitle: {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  metaValue: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
  },
  trackLink: {
    color: '#6C13F9',
    fontSize: '16px',
    lineHeight: '20px',
  },
  shippingService: {
    display: 'flex',
    alignItems: 'center',
  },
  providerLogo: {
    width: '24px !important',
    height: '24px !important',
    padding: 0,
    marginRight: 0,
  },
  locationContainer: {
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  locationInnerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
  },
  locationName: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  locationAddressContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  subtext: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#969AB2',
  },
  locationDeviderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  locationDevider: {
    width: '1px',
    flexGrow: 1,
    backgroundColor: '#bdbdbd',
    height: '30px',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  itemDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  itemTotalContainer: {},
  numberInput: {
    '& input': {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 700,
      padding: 5,
      paddingTop: 10,
    },
  },
  currencyIcon: {
    fontSize: 18,
    paddingBottom: 4,
    paddingTop: 10,
  },
}));

interface Props {
  loading?: boolean;
  reason: ClaimReason;
  trackingNumber?: string;
  trackLink?: string;
  shippingService?: string;
  logoUrl?: string;
  warehouse?: Rollo.Warehouse;
  customer?: Rollo.ShipmentContactDTO;
  items: Item[];
  packageInfo?: Rollo.PackageInfoDTO[];
  isUPS?: boolean;
}

const initialPackageInfo: Rollo.PackageInfoDTO = {
  type: '',
  size: { length: 0, width: 0, height: 0 },
  weight: {
    lb: 0,
    oz: 0,
    weightInOz: 0,
  },
};

const SummarySection = ({
  loading,
  reason,
  trackingNumber,
  trackLink,
  shippingService,
  logoUrl,
  warehouse,
  customer,
  items,
  packageInfo = [initialPackageInfo],
  isUPS,
}: Props) => {
  const classes = useStyles();

  const {
    input: { value: insuranceFeesPaidValue },
  } = useField('insuranceFeesPaid');

  const claimReason = reason.includes('LOST') ? 'Lost' : 'Damaged';

  const packageSize = packageInfo
    ? `${packageInfo[0].size.length} x ${packageInfo[0].size.width} x ${
        packageInfo[0].size.height
      } in  ${packageInfo[0].weight.lb ?? 0} lb  ${packageInfo[0].weight.oz ?? 0} oz`
    : '';

  const total = items.reduce((acc, item) => {
    return acc + Number(item.unitValue) * Number(item.quantity);
  }, 0);

  return (
    <SectionCard title="Summary" icon={<SummaryIcon />}>
      <div className={classes.container}>
        <div className={classes.metaContainer}>
          <Typography className={classes.metaTitle}>Tracking number</Typography>
          {loading ? (
            <Skeleton variant="rect" width={250} height={16} />
          ) : (
            <Link
              className={classes.trackLink}
              href={trackLink}
              target="_blank"
              rel="noopener noreferrer"
              underline="always">
              {trackingNumber}
            </Link>
          )}
        </div>

        <div className={classes.metaContainer}>
          <Typography className={classes.metaTitle}>Reason of claim</Typography>
          {loading ? (
            <Skeleton variant="rect" width={50} height={16} />
          ) : (
            <Typography className={classes.metaValue}>{claimReason}</Typography>
          )}
        </div>

        <div className={classes.metaContainer}>
          <Typography className={classes.metaTitle}>shipping service</Typography>
          {loading ? (
            <Skeleton variant="rect" width={150} height={16} />
          ) : (
            <div className={classes.shippingService}>
              <LogoImage src={logoUrl} className={classes.providerLogo} small />
              <Typography className={classes.metaValue}>{shippingService}</Typography>
            </div>
          )}
        </div>

        <div className={classes.locationContainer}>
          <div className={classes.locationInnerContainer}>
            <div className={classes.locationDeviderContainer}>
              <FromIcon />
              <span className={classes.locationDevider} id="TEST" />
            </div>

            {loading ? (
              <div className={classes.locationAddressContainer}>
                <Skeleton variant="rect" width={150} height={16} />
                <Skeleton variant="rect" width={250} height={16} />
                <Skeleton variant="rect" width={250} height={16} />
              </div>
            ) : (
              <div className={classes.locationAddressContainer}>
                <Typography className={classes.locationName}>{warehouse?.warehouseName}</Typography>
                <Typography className={classes.subtext}>{addressToString(warehouse)}</Typography>
                <Typography className={classes.subtext}>
                  {warehouse?.phone} {warehouse?.emailAddress}
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.locationInnerContainer}>
            <ToIcon />
            {loading ? (
              <div className={classes.locationAddressContainer}>
                <Skeleton variant="rect" width={150} height={16} />
                <Skeleton variant="rect" width={250} height={16} />
                <Skeleton variant="rect" width={250} height={16} />
              </div>
            ) : (
              <div className={classes.locationAddressContainer}>
                <Typography className={classes.locationName}>{customer?.name}</Typography>
                <Typography className={classes.subtext}>
                  {addressToString(customer?.address)}
                </Typography>
                <Typography className={classes.subtext}>
                  {customer?.phoneNumber} {customer?.emailAddress}
                </Typography>
              </div>
            )}
          </div>

          {!isUPS && (
            <div className={classes.numberInput}>
              <NumberInputFieldWithActions
                readOnly
                name="insuranceFeesPaid"
                label="Insurance Fees Paid"
                startAdornment={<span className={classes.currencyIcon}>$</span>}
                validate={required}
              />
            </div>
          )}

          <div className={classes.itemsContainer}>
            {items.map((item, index) => {
              return (
                <div key={index} className={classes.itemContainer}>
                  <div className={classes.metaContainer}>
                    <Typography className={classes.metaTitle}>{`${
                      Number(item.quantity) > 0 ? item.quantity : ''
                    } ${Number(item.quantity) > 1 ? 'ITEMS' : 'ITEM'}`}</Typography>
                    <Typography className={classes.metaValue}>
                      {formatPrice(item.unitValue)}
                    </Typography>
                  </div>
                  <Typography className={classNames(classes.subtext, classes.itemDescription)}>
                    {item.description}
                  </Typography>
                </div>
              );
            })}
            <Divider />
            <div className={classes.metaContainer}>
              <Typography>Package parameters</Typography>
              <Typography className={classes.subtext}>{packageSize}</Typography>
            </div>
            <Divider />
            <div className={classes.metaContainer}>
              <Typography className={classes.metaTitle}>TOTAL</Typography>
              <Typography className={classes.metaValue}>
                {formatPrice(total + Number(insuranceFeesPaidValue))}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </SectionCard>
  );
};

export default SummarySection;
