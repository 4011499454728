import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { CheckOutlined, ErrorOutlineOutlined, UpdateOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  success: {
    color: '#24C746',
  },

  errorContainer: {},
  error: {
    color: theme.palette.error.main,
  },

  errorDetails: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },

  updating: {
    color: theme.palette.text.secondary,
  },

  icon: {
    fontSize: 14,
    verticalAlign: 'middle',
  },

  link: {
    textDecoration: 'none',
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
  },
}));

interface RedirectToEditSCProps {
  market: any;
  content: string;
}

const RedirectToEditSC = ({ market, content }: RedirectToEditSCProps) => {
  const classes = useStyles();

  const providerCode = get(market, 'provider.code');
  const accountId = get(market, 'id');
  return (
    <Link
      to={{
        pathname: '/sellingChannels/edit',
        state: {
          code: providerCode,
          id: accountId,
          resource: 'sellingChannels',
          record: market,
          isEdit: true,
        },
      }}
      className={classes.link}>
      {content}
    </Link>
  );
};

interface SellingChannelStatusProps {
  market: any;
  toggleErrorDialog?: () => void;
  showIcon?: boolean;
  showDetails?: boolean;
  closePopupCb?: () => void;
  isRedirect?: boolean;
}

const SellingChannelStatus = ({
  market,
  toggleErrorDialog,
  showIcon = true,
  showDetails = false,
  closePopupCb,
  isRedirect = true,
}: SellingChannelStatusProps) => {
  const classes = useStyles();
  const { lastRefreshProcess, requiresReconnect } = market;

  const handleClosePopup = () => {
    if (closePopupCb) {
      closePopupCb();
    }
  };

  const renderUpdatingInProgress = () => {
    return (
      <div className={classes.updating}>
        {showIcon && <UpdateOutlined className={classes.icon} />}
        Updating in progress...
      </div>
    );
  };

  if (requiresReconnect) {
    const isInProgress = lastRefreshProcess?.status === 'IN_PROGRESS';
    return isInProgress ? (
      <>{renderUpdatingInProgress()}</>
    ) : (
      <div>
        <div className={classes.error} onClick={handleClosePopup}>
          {showIcon && <ErrorOutlineOutlined className={classes.icon} />}
          <RedirectToEditSC market={market} content="Please reconnect" />
        </div>
        {showDetails && (
          <span className={classes.errorDetails} onClick={toggleErrorDialog}>
            View details
          </span>
        )}
      </div>
    );
  }

  if (!lastRefreshProcess) {
    return null;
  }

  const humanDate = lastRefreshProcess.createdAt
    ? new Date(lastRefreshProcess.createdAt).toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    : '';

  switch (lastRefreshProcess.status) {
    case 'SUCCESS':
      return (
        <div className={classes.success}>
          {showIcon && <CheckOutlined className={classes.icon} />}
          Last updated at {humanDate}
        </div>
      );

    case 'FAIL':
    case 'FINISHED_WITH_ERRORS':
      return (
        <div className={classes.errorContainer}>
          <div className={classes.error} onClick={handleClosePopup}>
            {showIcon && <ErrorOutlineOutlined className={classes.icon} />}
            {isRedirect ? (
              <RedirectToEditSC market={market} content={`Update failed at ${humanDate}`} />
            ) : (
              `Update failed at ${humanDate}`
            )}
          </div>
          {showDetails && (
            <span className={classes.errorDetails} onClick={toggleErrorDialog}>
              View details
            </span>
          )}
        </div>
      );

    case 'IN_PROGRESS':
      return <>{renderUpdatingInProgress()}</>;

    default:
      return null;
  }
};

export default SellingChannelStatus;
