import React from 'react';
import Lightbox from './Lightbox';
import MessageBox from './MessageBox';
import RefillDialog from './RefillDialog';
import PhoneVerificationDialog from 'components/phoneVerification/PhoneVerificationDialog';

const Dialogs = () => (
  <>
    <Lightbox />
    <MessageBox />
    <RefillDialog />
    <PhoneVerificationDialog />
  </>
);

export default Dialogs;
