import { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useVersion } from 'react-admin';

const useProfile = () => {
  const dataProvider = useDataProvider();
  const version = useVersion();

  const [profile, setProfile] = useState<Record<string, any>>();
  const [loading, setLoading] = useState<boolean>(true);

  const getProfile = useCallback(async () => {
    setLoading(true);

    const { data: profile } = await dataProvider.getOne('profile', { id: 'profile' });

    setProfile(profile);
    setLoading(false);

    return profile;
  }, [dataProvider]);

  useEffect(() => {
    getProfile();
  }, [dataProvider, getProfile, version]);

  return { profile, getProfile, loading };
};

export default useProfile;
