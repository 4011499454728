export const responseToContacts = (values) =>
  values.map((val) => {
    const { countryCode, companyName, stateProvinceCode, fullName, ...otherFields } = val;
    return {
      ...otherFields,
      country: countryCode,
      company: companyName,
      state: stateProvinceCode,
      name: fullName,
    };
  });

export const contactToRequest = (contact) => {
  const { country, company, state, name, ...otherFields } = contact;

  return {
    ...otherFields,
    countryCode: country,
    companyName: company,
    stateProvinceCode: state,
    fullName: name,
  };
};

export const doUpdateRecipients = (
  searchString: string,
  setCurrentSearchString,
  setRecipients,
  setIsRecipientsError,
  setIsRecipientsLoading,
  dataProvider,
  setChosenRecipientID?,
  toObject?
): any => {
  setCurrentSearchString(searchString ?? '');
  if ((searchString ?? '').length < 2 || !isNaN(searchString ?? '')) {
    setRecipients([]);
    setIsRecipientsError(false);
    setIsRecipientsLoading(false);
  } else {
    setIsRecipientsLoading(true);
    setIsRecipientsError(false);
    const requestParams = {
      q: searchString ?? '',
    };
    dataProvider.get('recipientsSearch', requestParams).then((data) => {
      const recipients = responseToContacts(data?.data?.contacts ?? []);
      setRecipients(recipients);
      setIsRecipientsError(!!data.error);
      setIsRecipientsLoading(false);
      if (toObject) {
        const foundRecipient = recipients.find(
          (recipient) =>
            recipient.name === toObject.name &&
            recipient.city === toObject.city &&
            recipient.postalCode === toObject.zipCode &&
            recipient.company === toObject.companyName &&
            recipient.addressLine1 === toObject.addressLine1 &&
            recipient.country === toObject.countryCode &&
            recipient.phone === toObject.phone &&
            recipient.state === toObject.stateProvinceCode
        );

        if (foundRecipient?.id && typeof setChosenRecipientID === 'function') {
          setChosenRecipientID(foundRecipient.id);
          return foundRecipient.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  }
};
