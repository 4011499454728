import { SET_DUPLICATING_SHIPMENT } from '../actions/duplicatingShipmentActions';

interface State {
  duplicatingShipment: object | null;
}

const INITIAL_STATE = {
  duplicatingShipment: null,
};

const duplicatingShipmentReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_DUPLICATING_SHIPMENT:
      return {
        ...state,
        duplicatingShipment: payload,
      };

    default:
      return state;
  }
};

export default duplicatingShipmentReducer;
