import React from 'react';
import classNames from 'classnames';
import { Button, makeStyles } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginRight: '15px',
  },
}));

const NewCardButton = ({ onClick, ...props }: any) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root)}>
      <Button variant="contained" color="secondary" onClick={onClick}>
        <AddCircleOutlineRoundedIcon className={classNames(classes.icon)} />
        Add new card
      </Button>
    </div>
  );
};

export default NewCardButton;
