import React, { useState, useEffect } from 'react';
import { useMutation, useDelete, useRedirect } from 'react-admin';
import { IconButton } from '@material-ui/core';
import { Confirmation } from 'components/common';
import { Delete } from 'icons';

const DeleteActionButton = ({ basePath, resource, record }) => {
  const [content, setContent] = useState<string>('Are you sure you want to delete this address?');

  const [checkBeforeDelete, { data, loading, error }] = useMutation({
    type: 'post',
    resource: 'warehouseCheckBeforeDelete',
    payload: {
      id: record.id,
    },
  });

  const redirect = useRedirect();
  const [deleteOne] = useDelete(resource, record.id, record, {
    onSuccess: () => {
      redirect('list', basePath);
    },
  });

  useEffect(() => {
    if (data && !loading) {
      setContent((prevContent) => data.messages[0]?.name || prevContent);
    }
  }, [data, loading]);

  const onDelete = async () => {
    await deleteOne();
  };

  const getContent = async () => {
    await checkBeforeDelete();
  };

  if (!record.id || error) {
    return null;
  }

  return (
    <Confirmation
      title="Delete address"
      content={content}
      beforeOpenCb={getContent}
      loading={loading}>
      <IconButton onClick={onDelete}>
        <Delete />
      </IconButton>
    </Confirmation>
  );
};

export default DeleteActionButton;
