import { ListSubheader, MenuItem, Typography, makeStyles } from '@material-ui/core';
import SelectBase, { ISelectProps, OptionInterface } from 'components/form/SelectBase';
import React, { useMemo } from 'react';
import { FieldProps } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 0, 4),
    color: theme.palette.text.primary,
  },
  selectGroup: {
    backgroundColor: '#FFFFFF',
  },
}));

export interface IGroupedOption {
  label: string;
  options: OptionInterface[];
}

export interface IGroupedOptions {
  options: IGroupedOption[];
}

const GroupedSelect = ({
  options,
  ...rest
}: FieldProps<any, any> & ISelectProps & IGroupedOptions) => {
  const classes = useStyles();
  return (
    <SelectBase {...rest}>
      {useMemo(
        () =>
          options?.reduce((acc: JSX.Element[], group) => {
            group.label &&
              acc.push(
                <ListSubheader className={classes.selectGroup} key={group.label}>
                  {group.label}
                </ListSubheader>
              );
            group.options.forEach((option) =>
              acc.push(
                <MenuItem
                  classes={classes}
                  key={option.value.id || option.value}
                  value={option.value}>
                  <Typography>{option.label}</Typography>
                </MenuItem>
              )
            );

            return acc;
          }, []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options]
      )}
    </SelectBase>
  );
};

export default GroupedSelect;
