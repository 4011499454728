import parser from 'parse-address';

const addressParser = (value) => {
  const addressRegex = /^(PO|P.O.|\d)/;
  const splitValue = value.split('\n');
  const isAddress = splitValue[1] && splitValue[1].match(addressRegex);

  let nameValue = '',
    companyNameValue = '',
    addressValue;
  if (isAddress) {
    [nameValue, ...addressValue] = value.split('\n');
  } else {
    [nameValue, companyNameValue, ...addressValue] = value.split('\n');
  }

  const fullAddress = addressValue.reduce((acc, curr) => {
    return acc ? `${acc}, ${curr}` : curr;
  }, '');

  if (!fullAddress) {
    return [nameValue, '', '', '', '', '', companyNameValue];
  }

  const structuredAddressObject = parser.parseLocation(fullAddress);

  if (!structuredAddressObject) {
    return;
  }

  const {
    city = '',
    number = '',
    prefix = '',
    sec_unit_num = '',
    sec_unit_type = '',
    state = '',
    street = '',
    type = '',
    suffix = '',
    zip = '',
  } = structuredAddressObject;

  const address = [number, prefix, suffix, street, type]
    .reduce((acc, curr) => (curr && curr !== 'undefined' ? `${acc} ${curr}` : acc), '')
    .trim();

  const secondAddress = [sec_unit_type, sec_unit_num]
    .reduce((acc, curr) => (curr && curr !== 'undefined' ? `${acc} ${curr}` : acc), '')
    .trim();

  const result = address
    ? [nameValue, address, secondAddress, city, state, zip, companyNameValue]
    : [nameValue, secondAddress, '', city, state, zip, companyNameValue];

  return result;
};

export default addressParser;
