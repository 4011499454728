import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingBottom: theme.spacing(3),
    },

    title: {
      fontWeight: 'bold',
      alignSelf: 'center',
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(1),
    },

    gap: {
      flexGrow: 1,
    },

    content: {
      '& > :not(:first-child)': {
        marginLeft: theme.spacing(4),
      },
      display: 'flex',
      flexWrap: 'nowrap',
    },
  });

const ActionToolbar = ({
  classes,
  title,
  left,
  back,
  children,
}: {
  classes: any;
  title?: string | React.ReactNode;
  back?: React.ReactNode;
  left?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
}) => (
  <div className={classes.root} data-test="toolbar">
    {back}
    <div className={classes.title} data-test="title">
      {title}
    </div>
    {left}
    <div className={classes.gap} />
    <div className={classes.content}>{children}</div>
  </div>
);

export default withStyles(styles)(ActionToolbar);
