import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Dialog } from 'components/common';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    '& span': {
      color: 'white !important',
    },
  },
}));

const EditActionButton = ({ record, handleSubmit, submitting, values, dirty }) => {
  const classes = useStyles();
  const [content, setContent] = useState<string>('');
  const [isOpen, setOpen] = useState<boolean>(false);
  const [checkBeforeEdit, { data, loading, error }] = useMutation({
    type: 'post',
    resource: 'warehouseCheckBeforeEdit',
    payload: {
      id: record.id,
    },
  });

  useEffect(() => {
    if (data && data.messages.length && !loading) {
      setContent((prevContent) => data.messages[0]?.name || prevContent);
      toggleDialog();
    }
  }, [data, loading]);

  useEffect(() => {
    if (data && data.messages.length === 0 && !loading) {
      handleSubmit(values);
    }
  }, [data, loading]); //eslint-disable-line

  const getContent = async () => {
    await checkBeforeEdit();
  };

  const toggleDialog = () => {
    setOpen((prev) => !prev);
  };

  if (!record.id || error) {
    return null;
  }

  return (
    <>
      <Button
        classes={classes}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        disabled={submitting || !dirty || loading}
        onClick={getContent}>
        {!submitting && 'Save'}
        {(submitting || loading) && <CircularProgress size={24} />}
      </Button>
      <Dialog
        open={isOpen}
        title="Edit address"
        confirmText="OK"
        onConfirm={() => handleSubmit(values)}
        onCancel={toggleDialog}>
        {content}
      </Dialog>
    </>
  );
};

export default EditActionButton;
