import React, { Component } from 'react';
import { Field, FieldProps } from 'react-final-form';
import {
  createStyles,
  withStyles,
  Checkbox as MUICheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';

interface CheckboxPropsInterface {
  inputProps?: CheckboxProps;
}

const styles = (theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
    },
    noMarginBottom: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  });

class Checkbox extends Component<FieldProps<any, any> & CheckboxPropsInterface> {
  render() {
    const {
      classes,
      name,
      disabled,
      label,
      noMarginBottom = false,
      inputProps = {},
      fullWidth = true,
      ...rest
    } = this.props;
    return (
      <Field
        type="checkbox"
        name={name}
        render={({
          input: { name, onBlur, onChange, onFocus, value },
          meta: { touched, error },
        }) => {
          const handleChange = (event) => {
            if (rest.onChange) {
              rest.onChange(event.target.checked);
            } else {
              onChange(event);
            }
          };

          return (
            <FormControl
              className={noMarginBottom ? classes.noMarginBottom : classes.root}
              fullWidth={fullWidth}>
              <FormControlLabel
                control={
                  <MUICheckbox
                    color="primary"
                    name={name}
                    onChange={handleChange}
                    checked={!!value}
                    {...inputProps}
                  />
                }
                label={label}
              />
              {touched && error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
          );
        }}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(Checkbox);
