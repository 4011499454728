import { GET_MANY_REFERENCE } from 'react-admin';

export default {
  [GET_MANY_REFERENCE]: ({ response, url, options, httpClient, params, apiUrl }) => ({
    ...response,
    json: [
      {
        ...response.json,
        id: 0,
      },
    ],
  }),
};
