import { GET_LIST } from 'react-admin';

export default {
  [GET_LIST]: ({ response }) => {
    const headers = new Headers(response.headers);
    headers.set('x-total-count', response.json.length);

    return {
      ...response,
      json: response.json.map((group) => ({
        id: btoa(
          JSON.stringify({
            account: group.accountInfo.id,
            warehouse: group.warehouse.warehouseName,
          })
        ),
        ...group,
      })),
      headers,
    };
  },
};
