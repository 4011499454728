import React, { useState } from 'react';
import {
  makeStyles,
  Link,
  List,
  Popover,
  TextField,
  InputAdornment,
  Button,
  ListItem,
} from '@material-ui/core';
import classNames from 'classnames';
import ShippingPreset from './ShippingPreset';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { NewWindow } from 'icons';

const useStyles = makeStyles((theme) => ({
  selectButton: {
    marginRight: 0,
    minWidth: theme.spacing(3),
    padding: theme.spacing(0.5, 0.5),

    justifyContent: 'flex-start',
    maxWidth: '220px',

    '& span.MuiButton-label': {
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
      width: 'fit-content',
      textTransform: 'none',
      fontSize: 13,
    },
  },

  active: {
    color: `${theme.palette.primary.main} !important`,

    '& icon': {
      right: 2,
    },
  },

  popoverContent: {
    padding: theme.spacing(2),
    maxWidth: theme.spacing(32),
    maxHeight: theme.spacing(60),
    overflow: 'hidden',
    paddingRight: theme.spacing(1),
  },

  content: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: theme.spacing(50),
  },

  header: {
    marginTop: theme.spacing(-1),
  },

  endAdornment: {
    marginRight: theme.spacing(-0.5),
    color: theme.palette.secondary.contrastText,
  },

  input: {
    '& .MuiFormLabel-root': {
      color: theme.palette.secondary.contrastText,
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: '#E0E0E0',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.primary.main,
    },

    '& .MuiInputBase-root': {
      lineHeight: 'normal',
    },
  },

  icon: {
    fontSize: 14,
    marginRight: 0,
    color: theme.palette.secondary.dark,
    position: 'relative',
    top: 2,
  },

  item: {
    padding: theme.spacing(1.5, 1.5),
    borderBottom: `1px solid ${theme.palette.divider}`,

    '&:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },

  linkIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    verticalAlign: 'middle',
  },

  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 45px',
    borderRadius: '16px',
    backgroundColor: '#F2F4FF',
    '& > span': {
      marginRight: theme.spacing(1),
    },
  },

  link: {
    fontSize: 14,
    color: theme.palette.secondary.contrastText,
    textTransform: 'uppercase',
  },
}));

const ShippingPresetDialog = ({
  initialPresets,
  presets,
  setPresets,
  onChange,
  fieldValue,
  isQuickPreset,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPresets(initialPresets);
  };

  const handleChangeSelect = (preset) => {
    onChange(preset);
    handleClose();
  };

  const onSearch = (e) => {
    const value = e.target.value;
    const regex = new RegExp(value, 'i');
    const filteredPresets = initialPresets.filter((preset) => regex.test(preset.name));
    setPresets(filteredPresets);
  };

  if (!initialPresets.length) {
    return null;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        onClick={handleOpen}
        className={classNames(
          classes.selectButton,
          !isQuickPreset() && fieldValue.name && classes.active
        )}
        variant="outlined"
        size="small">
        <ArrowDropDownOutlinedIcon className={classes.icon} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className={classes.popoverContent}>
          <div className={classes.header}>
            <TextField
              fullWidth
              className={classes.input}
              label="Search"
              onChange={onSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" className={classes.endAdornment}>
                    <SearchRoundedIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.content}>
            <List disablePadding={true}>
              {presets.map((preset) => {
                const isActive = preset.id === fieldValue.id;

                return (
                  <ListItem
                    button
                    className={classes.item}
                    key={preset.id}
                    onClick={() => handleChangeSelect(preset)}>
                    <ShippingPreset preset={preset} isActive={isActive} />
                  </ListItem>
                );
              })}
            </List>
          </div>

          <Link className={classes.link} href={'/shippingPresets'} target="_blank">
            <p className={classes.linkWrapper}>
              <span>MANAGE PRESETS</span> <NewWindow className={classes.linkIcon} />
            </p>
          </Link>
        </div>
      </Popover>
    </>
  );
};

export default ShippingPresetDialog;
