import { Reducer } from 'redux';
import { SET_DIALOG_STATE, SET_DIALOG_STATE_ACTION } from '../actions/markOrderShippedActions';

type State = {
  open: boolean;
  orderId: undefined | string;
};

type ActionTypes = SET_DIALOG_STATE_ACTION | { type: 'OTHER_TYPE' };

const markOrderShippedReducer: Reducer<State> = (
  state = { open: false, orderId: undefined },
  action: ActionTypes
) => {
  switch (action.type) {
    case SET_DIALOG_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default markOrderShippedReducer;
