import { GET_ONE, UPDATE } from 'react-admin';
import { get } from 'lodash';

const LOCAL_STORAGE_KEY = 'rollo_local_provider';

const getStorage = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');

const updateStorage = (storage) => localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storage));

const getOne = (resource, id) => get(getStorage(), `${resource}.${id}`, { id });

export default (type, resource, params) => {
  switch (type) {
    case GET_ONE:
      return { data: getOne(resource, params.id) };
    case UPDATE:
      const storage = getStorage();

      if (!storage[resource]) {
        storage[resource] = {};
      }

      storage[resource][params.id] = { id: params.id, ...params.data };

      updateStorage(storage);

      return { data: storage[resource][params.id] };
  }
};
