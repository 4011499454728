import { GET_LIST, GET_MANY } from 'react-admin';

const getUserServices = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.map((item) => ({
    name: item === 'rollo_no_ship_service' ? 'No service selected' : item,
    id: item,
  })),
});

export default {
  [GET_LIST]: getUserServices,
  [GET_MANY]: getUserServices,
};
