import { Chip, createStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { get } from 'lodash';

const styles = (theme) =>
  createStyles({
    root: {},
    chip: {
      height: 18,
      fontSize: 12,
      fontWeight: 'bold',
      marginRight: theme.spacing(2),
    },
    return: {
      backgroundColor: '#FFF6DA',
      color: '#BF9200',
    },
  });

const ShipmentStatusField = ({
  classes,
  className = '',
  record = {},
  basePath = '',
  translateChoice,
  resource,
  typeSource,
  statusSource,
  ...props
}) => {
  const markedAsShipped = get(get(record, 'account'), 'number') === 'Manual';
  const count = get(record, 'count');
  const label = get(get(record, statusSource), 'name');

  return (
    <div className={classes.root}>
      {(get(record, typeSource) === 'RETURN' || get(record, 'returnShipment')) && (
        <>
          <Chip className={classNames(classes.chip, classes.return, className)} label="Return" />
          <br />
        </>
      )}
      <Chip
        className={classNames(classes.chip, className)}
        style={{
          backgroundColor: get(get(record, statusSource), 'bgColor'),
          color: get(get(record, statusSource), 'color'),
        }}
        label={markedAsShipped ? 'Marked As Shipped' : label + (count > 1 ? ` x${count}` : '')}
      />
    </div>
  );
};

export default withStyles(styles)(ShipmentStatusField);
