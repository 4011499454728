import React from 'react';
import classNames from 'classnames';
import { Chip, makeStyles } from '@material-ui/core';
import { get, upperFirst, lowerCase } from 'lodash';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    '& > span:first-letter': {
      textTransform: 'uppercase',
    },
  },
  deposite: {
    backgroundColor: 'rgb(223, 255, 230)',
    color: 'rgb(36, 199, 70)',
  },
}));

const ChipField = (props) => {
  const classes = useStyles();
  const { record, source, defaultValue = '-', className, withTitle, ...rest } = props;
  const label = get(record, source) || defaultValue;
  const isDeposit = record.type && record.type.includes('DEPOSIT');
  const isCreditCode = record.type && record.type.includes('CREDIT_CODE');

  return (
    <Chip
      {...rest}
      className={classNames(
        classes.chip,
        className,
        (isDeposit || isCreditCode) && classes.deposite
      )}
      label={isDeposit || isCreditCode ? upperFirst(lowerCase(label)) : label}
      title={!!withTitle ? label : undefined}
    />
  );
};

export default ChipField;
