import React, { Children, cloneElement, useState } from 'react';
import Dialog from './Dialog';
import { FormLabel, CircularProgress } from '@material-ui/core';

interface ConfirmationProps {
  title: string;
  content: string;
  confirmText?: string;
  cancelText?: string;
  children: React.ReactNode;
  cancelSize?: number;
  confirmSize?: number;
  beforeOpenCb?: () => void;
  loading?: boolean;
}

const Confirmation = (props: ConfirmationProps) => {
  const {
    title,
    content,
    confirmText,
    cancelText,
    children,
    cancelSize,
    confirmSize,
    beforeOpenCb,
    loading = false,
  } = props;
  const child = Children.only(children);
  const { props: childProps } = child;

  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>(null);

  const handleClickAction = () => {
    if (beforeOpenCb) {
      beforeOpenCb();
    }
    setOpen(true);
  };

  const handleConfirm = () => {
    const promise = childProps.onClick();

    if (!promise) {
      setOpen(false);
      return;
    }

    setError(null);
    setSubmitting(true);

    Promise.resolve(promise)
      .then((done) => {
        setSubmitting(false);
        setOpen(false);
        done && done();
      })
      .catch((err) => {
        setSubmitting(false);
        setError(err.message);
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {cloneElement(child, {
        ...childProps,
        onClick: handleClickAction,
      })}
      <Dialog
        open={open}
        title={title}
        submitting={submitting}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmText={confirmText}
        cancelText={cancelText}
        cancelSize={cancelSize}
        confirmSize={confirmSize}>
        {loading ? <CircularProgress size={24} /> : <div>{content}</div>}

        {error && (
          <FormLabel error component="p">
            {error}
          </FormLabel>
        )}
      </Dialog>
    </>
  );
};

export default Confirmation;
