import { GET_ONE, DELETE } from 'react-admin';

export default {
  [GET_ONE]: async ({ response, url, options, httpClient, params, apiUrl }) => ({
    ...response,
    json: {
      ...response.json,
      order: response.json.order
        ? {
            ...response.json.order,
            items: await httpClient(apiUrl + `/orders/${response.json.order.id}/items`, {
              ...options,
              method: 'GET',
            })
              .then((r) => r.json)
              .catch((e) => []),
          }
        : response.json.order,
    },
  }),
  [DELETE]: ({ response, url, options, httpClient, params, apiUrl }) => ({
    ...response,
    json: {
      ...response.json,
      id: 0,
    },
  }),
};
