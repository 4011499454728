import React, { FunctionComponent, useState } from 'react';
import { get } from 'lodash';
import { createStyles, withStyles, Grid, Typography, IconButton, Popover } from '@material-ui/core';
import { FieldProps, InjectedFieldProps } from 'react-admin';
import PriceField from 'components/fields/PriceField';
import { InfoOutlined } from '@material-ui/icons';

interface PriceFieldPropsInterface {
  labelVariant?: string;
  priceVariant?: string;
  info?: string;
}

const styles = (theme) =>
  createStyles({
    root: {},

    label: {
      lineHeight: '20px',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
    },

    price: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 'bold',
    },

    info: {},

    content: {
      padding: theme.spacing(2),
      width: theme.spacing(40),
      '& > p': {
        fontWeight: 'bold',
      },
    },
  });

const LabeledPriceField: FunctionComponent<
  InjectedFieldProps & FieldProps & PriceFieldPropsInterface
> = ({
  classes,
  record,
  source,
  label,
  labelVariant = 'overline',
  priceVariant = 'body1',
  info = false,
  fixedValue,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid
        className={classes.root}
        container
        justify="space-between"
        alignItems="center"
        data-test="labeled-field">
        <Typography className={classes.label} variant={labelVariant} data-test="label">
          {label}
          {info && (
            <IconButton size="small" onClick={handleOpen}>
              <InfoOutlined fontSize="small" />
            </IconButton>
          )}
        </Typography>
        {record.isDisplayString ? (
          <span className={classes.price}>{fixedValue ?? get(record, source)}</span>
        ) : (
          <PriceField
            className={classes.price}
            {...{ record, source }}
            variant={priceVariant}
            fixedValue={fixedValue}
            data-test="value"
          />
        )}
      </Grid>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className={classes.content}>
          <Typography>{info}</Typography>
        </div>
      </Popover>
    </>
  );
};

export default withStyles(styles)(LabeledPriceField);
