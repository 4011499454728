import { get, mapValues } from 'lodash';

const getAll = (collection, mapping) =>
  mapValues(mapping, (value) => {
    if (typeof value === 'function') {
      return value();
    }

    if (typeof value === 'string') {
      return get(collection, value);
    }

    if (Array.isArray(value)) {
      return value.map((subvalue) => getAll(collection, subvalue));
    }

    return getAll(collection, value);
  });

export default getAll;
