import { createStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Field, FieldProps } from 'react-final-form';
import { ICountry, IWarehousePreset, IRecipient } from 'services/data/interfaces';
import getAll from 'services/helpers/getAll';
import CountrySelect from './zip-input/CountrySelect';
import SuggestibleTextInput from './zip-input/SuggestibleTextInput';
import ZipAutocomplete from './zip-input/ZipAutocomplete';
import RecipientAutocomplete from './zip-input/RecipientAutocomplete';
import { cloneDeep } from 'lodash';
import { isDigitalZipCode } from '../utils/isDigitalZipCode';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      height: theme.spacing(7),
      borderRadius: theme.spacing(3.5),
      backgroundColor: theme.palette.background.paper,
      textTransform: 'none',
      border: '1px solid transparent',
      boxSizing: 'border-box',
      boxShadow: theme.shadows[1],

      '&hover:not($error)': {
        borderColor: theme.palette.primary.main,
      },
    },

    error: {
      borderColor: theme.palette.error.main,
    },

    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: theme.spacing(7),
      flexShrink: 0,
    },

    container: {
      width: '100%',
    },

    input: {
      flexGrow: 1,
    },
  });

let debouncedSearch;

const ZipInput = ({
  classes,
  name,
  disabled,
  label,
  icon,
  warehousePresets,
  recipients,
  updateRecipients,
  isRecipientsError,
  isRecipientsLoading,
  isDestinationAddress,
  countries,
  zipCodeRequired,
  countryDisabled,
  chosenRecipientID,
  setChosenRecipientID,
  currentSearchString,
  shippingData,
  redirectAfterSubmit,
  ...props
}: {
  classes: any;
  name: string;
  disabled?: boolean;
  label: string;
  icon: React.ReactNode;
  warehousePresets: IWarehousePreset[];
  recipients: IRecipient[];
  isRecipientsError: boolean;
  isRecipientsLoading: boolean;
  updateRecipients: (string) => void;
  countries: ICountry[];
  zipCodeRequired?: boolean;
  countryDisabled?: boolean;
  shippingData?: any;
  isDestinationAddress?: boolean;
} & FieldProps<any, any>) => {
  return (
    <Field
      name={name}
      render={({ input: { name, onChange, value }, meta: { touched, error } }) => {
        const isError = !!(touched && error);

        const handleKeyDown = (evt) => {
          if (evt.keyCode === 13) {
            evt.preventDefault();
          }
        };

        if (shippingData && chosenRecipientID && !value.id) {
          onChange({
            ...value,
            zipCode: shippingData.to?.zipCode,
            id: chosenRecipientID,
          });
        }

        const renderInput = (props) => (
          <SuggestibleTextInput
            {...props}
            inputProps={{
              ...props.inputProps,
              autoComplete: 'disabled',
              placeholder: label,
            }}
            InputProps={{
              ...props.InputProps,
              startAdornment: <div className={classes.icon}>{icon}</div>,
            }}
          />
        );

        const handleChangeCountry = (event) => {
          const newCountry = event.target.value;
          let newValue = cloneDeep(value);
          if (newCountry !== 'US' && !!value.city && !value.zipCode) {
            newValue.zipCode = value.city;
            newValue.city = undefined;
          }
          if (
            newCountry === 'US' &&
            !value.city &&
            !!value.zipCode &&
            !isDigitalZipCode(value.zipCode)
          ) {
            newValue.city = value.zipCode;
            newValue.zipCode = undefined;
          }
          if (props.onChangeCountry) {
            props.onChangeCountry({ value: newValue, countryCode: event.target.value });
          }
          onChange({ ...newValue, countryCode: event.target.value });
        };

        const handleChangeAddress = (event, address) => {
          onChange({
            ...value,
            ...getAll(address, {
              warehouseName: 'name',
              zipCode: 'postalCode',
              stateProvinceCode: 'stateOrProvince.code',
              city: 'city',
              addressLine1: 'addressLine1',
              addressLine2: 'addressLine2',
              addressLine3: 'addressLine3',
              phone: 'phone',
              id: 'id',
            }),
          });
        };

        const handleChangeRecipient = (event, address) => {
          onChange({
            ...value,
            ...getAll(address, {
              name: 'name',
              email: 'email',
              company: 'company',
              zipCode: 'postalCode',
              stateProvinceCode: 'state',
              city: 'city',
              addressLine1: 'addressLine1',
              addressLine2: 'addressLine2',
              addressLine3: 'addressLine3',
              phone: 'phone',
              id: 'id',
            }),
          });
        };

        const handleChangeRecipientInput = (value) => {
          if (updateRecipients) {
            setChosenRecipientID(undefined);
            const newName = value ?? '';
            if (debouncedSearch) {
              clearTimeout(debouncedSearch);
              debouncedSearch = undefined;
            }

            if (newName.length >= 2) {
              debouncedSearch = setTimeout(() => {
                updateRecipients(newName);
              }, 600);
            } else {
              updateRecipients(newName);
            }
          }
        };

        return (
          <div
            className={classNames(classes.root, isError && classes.error)}
            onKeyDown={handleKeyDown}>
            {warehousePresets ? (
              <ZipAutocomplete
                className={classes.input}
                value={{
                  postalCode: zipCodeRequired ? value.zipCode : undefined,
                  id: value.id,
                  city: !zipCodeRequired ? value.city : undefined,
                }}
                zipCodeRequired={!!zipCodeRequired}
                options={warehousePresets}
                renderInput={renderInput}
                onChange={handleChangeAddress}
                isDestinationAddress={!!isDestinationAddress}
                redirectAfterSubmit={redirectAfterSubmit}
              />
            ) : (
              <RecipientAutocomplete
                className={classes.input}
                value={{
                  postalCode: zipCodeRequired ? value.zipCode : undefined,
                  id: value.id,
                  city: !zipCodeRequired ? value.city : undefined,
                }}
                zipCodeRequired={!!zipCodeRequired}
                options={recipients}
                isRecipientsLoading={isRecipientsLoading}
                isRecipientsError={isRecipientsError}
                renderInput={renderInput}
                onChange={handleChangeRecipient}
                onChangeInputValue={handleChangeRecipientInput}
                isDestinationAddress={!!isDestinationAddress}
                setChosenRecipientID={setChosenRecipientID}
                currentSearchString={currentSearchString}
                notUS={value.countryCode !== 'US'}
              />
            )}
            <CountrySelect
              countries={countries}
              value={value.countryCode || ''}
              onChange={handleChangeCountry}
              countryDisabled={countryDisabled}
            />
          </div>
        );
      }}
      {...props}></Field>
  );
};

export default withStyles(styles)(ZipInput);
