import { GET_LIST, GET_MANY } from 'react-admin';

const getWarehouses = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.map((item) => ({
    id: item,
    warehouse: item,
  })),
});

export default {
  [GET_LIST]: getWarehouses,
  [GET_MANY]: getWarehouses,
};
