import { CLEAR_SHIPPING_RATES_ACTION } from '../actions/navMenuAction';

interface State {
  shouldClearShippingRates: boolean;
}

const INITIAL_STATE = {
  shouldClearShippingRates: false,
};

const navMenuReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CLEAR_SHIPPING_RATES_ACTION:
      return {
        ...state,
        shouldClearShippingRates: payload,
      };

    default:
      return state;
  }
};

export default navMenuReducer;
