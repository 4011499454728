import { TOGGLE_WELCOME_PAGE, HOME_PAGE_CREATE_SHIPMENT } from '../actions/commonActions';

interface State {
  isWelcomePage: boolean;
  newShipment: any;
}

const INITIAL_STATE = {
  isWelcomePage: false,
  newShipment: null,
};

const commonReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TOGGLE_WELCOME_PAGE:
      return {
        ...state,
        isWelcomePage: payload,
      };

    case HOME_PAGE_CREATE_SHIPMENT:
      return {
        ...state,
        newShipment: payload,
      };

    default:
      return state;
  }
};

export default commonReducer;
