import { GET_MANY_REFERENCE } from 'react-admin';

export default {
  [GET_MANY_REFERENCE]: ({ response }) => ({
    ...response,
    json: response.json.map((item) => ({
      ...item,
      id: item.shopId,
    })),
  }),
};
