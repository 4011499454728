import React, { useEffect } from 'react';
import { useField } from 'react-final-form';
import addressParser from 'services/helpers/addressParser';

const WhenFieldChangesImpl = ({ field, targets }: { field: string; targets: string[] }) => {
  const origField = useField(field);
  const value = origField.input.value;
  if (!targets[6]) targets.push('vCompany');

  const targetFields = [
    useField(targets[0]),
    useField(targets[1]),
    useField(targets[2]),
    useField(targets[3]),
    useField(targets[4]),
    useField(targets[5]),
    useField(targets[6]),
  ];

  if (targets[7] === 'vCompany') targetFields.pop();

  useEffect(() => {
    if (value) {
      const toValue = addressParser(value);
      targetFields.forEach((e, i) => e.input.onChange(toValue[i]));
    }
  }, [value]); //eslint-disable-line

  return null;
};

const addressDistributor = ({ field, targets }) => {
  return (
    <>
      <WhenFieldChangesImpl field={field} targets={targets} />
    </>
  );
};

export default addressDistributor;
