import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  title: {
    position: 'relative',
    fontSize: 14,
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    whiteSpace: 'normal',
  },

  active: {
    '& $title': {
      color: theme.palette.primary.main,
    },
  },

  checkIcon: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(0.5),
    fontSize: 14,
  },

  params: {
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.text.secondary,
  },
}));

const ShippingPreset = ({ preset, isActive }) => {
  const classes = useStyles();

  return (
    <Grid container className={isActive ? classes.active : undefined}>
      <Grid item xs={12} className={classes.title}>
        {preset.name}&nbsp;
        {isActive && <CheckOutlined className={classes.checkIcon} />}
      </Grid>
      <Grid item xs={12} className={classes.params}>
        {`${preset.size.width} x ${preset.size.height} x ${preset.size.length} in, ${preset.weight.lb} lb ${preset.weight.oz} oz`}
      </Grid>
    </Grid>
  );
};

export default ShippingPreset;
