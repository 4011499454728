const formatSortParameters = (resource, sort, query) => {
  const { field, order } = sort;
  const setDefaultSortingParams = (field: string, order: string): void => {
    query.sort = field;
    query[`${field}.dir`] = order;
  };

  switch (resource) {
    case 'orders':
      switch (field) {
        case 'marketplaceOrderId':
          query.sort = ['sellerOrderId', field];
          query[`sellerOrderId.dir`] = order;
          query[`${field}.dir`] = order;
          break;
        case 'id':
          query.sort = 'purchaseDate';
          query['purchaseDate.dir'] = 'DESC';
          break;
        default:
          setDefaultSortingParams(field, order);
          break;
      }
      break;
    case 'shipments':
      switch (field) {
        case 'order.marketplaceOrderId':
          query.sort = ['order.sellerOrderId', 'order.marketplaceOrderId'];
          query[`order.marketplaceOrderId.dir`] = order;
          query[`order.sellerOrderId.dir`] = order;
          break;
        case 'id':
          query.sort = 'createdAt';
          query['createdAt.dir'] = 'DESC';
          delete query['id.dir'];
          break;
        default:
          setDefaultSortingParams(field, order);
          break;
      }
      break;
    case 'scanFormsHistory':
      switch (field) {
        case 'shipDate':
          query.sort = 'shipped';
          query['shipped.dir'] = order;
          break;
        default:
          setDefaultSortingParams(field, order);
          break;
      }
      break;
    case 'groups':
      switch (field) {
        case 'marketplaceOrderId':
          query.sort = ['sellerOrderId', field];
          query[`sellerOrderId.dir`] = order;
          query[`${field}.dir`] = order;
          break;
        case 'id':
          query.sort = 'purchaseDate';
          query['purchaseDate.dir'] = 'DESC';
          break;
        default:
          setDefaultSortingParams(field, order);
          break;
      }
      break;
    case 'groupShipments':
      switch (field) {
        case 'order.marketplaceOrderId':
          query.sort = ['order.sellerOrderId', 'order.marketplaceOrderId'];
          query[`order.marketplaceOrderId.dir`] = order;
          query[`order.sellerOrderId.dir`] = order;
          break;
        case 'id':
          query.sort = 'shipmentDate';
          query['shipmentDate.dir'] = 'DESC';
          delete query['id.dir'];
          break;
        default:
          setDefaultSortingParams(field, order);
          break;
      }
      break;
    case 'singlePickupList':
      switch (field) {
        case 'id':
          query.sort = 'scheduledDate';
          query['scheduledDate.dir'] = 'ASC';
          delete query['id.dir'];
          break;
        default:
          setDefaultSortingParams(field, order);
          break;
      }
      break;
    default:
      setDefaultSortingParams(field, order);
      break;
  }
};

export default formatSortParameters;
