import React, { memo } from 'react';

import { Input, Select } from 'components/form';
import { taxNumber } from 'components/form/validationRules';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: {
    maxWidth: 70,
    margin: 0,
    justifyContent: 'center',
    minWidth: 70,
    '&.MuiInput-formControl': {
      marginTop: 0,
    },
  },
  taxNumberInput: {
    minWidth: 200,
    marginTop: 0,
    width: '100%',
  },
  taxContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  taxNumberRoot: {
    marginRight: theme.spacing(2),
    width: '100%',
  },
  label: {
    fontSize: 12,
    color: theme.palette.secondary.contrastText,
  },
}));

interface ITaxIdInputProps {
  options: any;
  sourceName?: string;
  label?: string;
  inputLabel?: string;
  handleTypeChange?: (value: string) => void;
  taxNumberTypes: any;
  handleTaxIdChange?: (value: string) => void;
}

const TaxIdInput = ({
  options,
  sourceName,
  taxNumberTypes,
  label,
  inputLabel,
  handleTypeChange,
  handleTaxIdChange,
}: ITaxIdInputProps) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.label}>{label}</Typography>
      <div className={classes.taxContainer}>
        <Select
          name={sourceName ? `${sourceName}.taxIdType` : 'taxIdType'}
          options={options}
          classes={{ root: classes.select, select: classes.select }}
          onChange={handleTypeChange}
        />
        <Input
          name={sourceName ? `${sourceName}.taxId` : 'taxId'}
          inputProps={{ placeholder: inputLabel }}
          parse={(v) => v.trim().toUpperCase()}
          classes={{
            input: classes.taxNumberInput,
            root: classes.taxNumberRoot,
          }}
          validate={taxNumber(sourceName, taxNumberTypes, false)}
          onChange={handleTaxIdChange}
        />
      </div>
    </div>
  );
};

export default memo(TaxIdInput);
