import React from 'react';
import { SvgIcon } from '@material-ui/core';

const WarehouseOption = (props) => (
  <SvgIcon width={18} height={18} {...props} viewBox="0 0 18 18">
    <path
      d="M4.75318 16.7604C3.85028 17.4112 2.77248 16.6404 3.121 15.5895L4.75653 10.6553L0.496092 7.61278C-0.409692 6.9661 -0.000386905 5.71595 1.11958 5.71827L6.37744 5.72965L7.99125 0.788481C8.33428 -0.261801 9.66506 -0.263853 10.0086 0.788481L11.6224 5.72965L16.8803 5.71827C17.9982 5.71594 18.4111 6.96481 17.5038 7.61278L13.2433 10.6553L14.8789 15.5895C15.2266 16.6386 14.1514 17.4122 13.2467 16.7604L8.99986 13.6995L4.75318 16.7604ZM8.14263 12.0229C8.64016 11.6643 9.36 11.6646 9.85727 12.0229L12.3069 13.7884L11.3635 10.9424C11.1718 10.3643 11.3946 9.68839 11.8932 9.33207L14.3507 7.57716L11.3179 7.58389C10.702 7.58518 10.1197 7.16708 9.93089 6.58868L8.99997 3.73848L8.06905 6.58868C7.87998 7.16735 7.29733 7.58521 6.68201 7.58389L3.64919 7.57716L6.10672 9.33207C6.60582 9.68863 6.82788 10.3648 6.63645 10.9424L5.69299 13.7884L8.14263 12.0229Z"
      fill="#BEBEBE"
    />
  </SvgIcon>
);

export default WarehouseOption;
