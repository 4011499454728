import React from 'react';
import { FormLabel } from '@material-ui/core';

interface FormErrorProps {
  submitError: any;
  submitFailed: boolean;
  hasValidationErrors: boolean;
  preventedErrorLabels?: string[];
  validationErrorText?: string;
}

export const makeFormError = ({
  submitError,
  submitFailed,
  hasValidationErrors,
  validationErrorText,
}: FormErrorProps) => {
  if (submitFailed && hasValidationErrors) {
    return validationErrorText ?? 'Validation Error. Please scroll up and check your entries.';
  }

  if (submitError) {
    return submitError;
  }

  return null;
};

const FormErrorLabel = ({
  className,
  submitError,
  submitFailed,
  hasValidationErrors,
  preventedErrorLabels,
  validationErrorText,
}: FormErrorProps & {
  className?: string;
}) => {
  const error = makeFormError({
    submitError,
    submitFailed,
    hasValidationErrors,
    validationErrorText,
  });

  if (error && !(preventedErrorLabels ?? []).includes(error)) {
    return (
      <FormLabel error component="div" className={className}>
        {error}
      </FormLabel>
    );
  }

  return null;
};

export default FormErrorLabel;
