import React from 'react';
import { IconButton, createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    button: {
      color: theme.palette.secondary.contrastText,
    },
  });

const ActionButton = ({ classes, ...props }) => (
  <IconButton className={classes.button} size="small" {...props} />
);

export default withStyles(styles)(ActionButton);
