import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Provider = (props) => (
  <SvgIcon {...props}>
    <path
      d="M14,9 L16,9 C16.5522847,9 17,9.44771525 17,10 C17,10.5522847 16.5522847,11 16,11 L14,11 C13.4477153,11 13,10.5522847 13,10 C13,9.44771525 13.4477153,9 14,9 Z M9.94999094,19 C9.71835578,20.1411202 8.70947896,21 7.5,21 C6.29052104,21 5.28164422,20.1411202 5.05000906,19 L4,19 C3.44771525,19 3,18.5522847 3,18 L3,5 L2,5 C1.44771525,5 1,4.55228475 1,4 C1,3.44771525 1.44771525,3 2,3 L4,3 C4.55228475,3 5,3.44771525 5,4 L5,17 L5.49981691,17 C5.95592007,16.3927766 6.68209108,16 7.5,16 C8.31790892,16 9.04407993,16.3927766 9.50018309,17 L18.4998169,17 C18.9559201,16.3927766 19.6820911,16 20.5,16 C21.8807119,16 23,17.1192881 23,18.5 C23,19.8807119 21.8807119,21 20.5,21 C19.290521,21 18.2816442,20.1411202 18.0500091,19 L9.94999094,19 Z M20.5,19 C20.7761424,19 21,18.7761424 21,18.5 C21,18.2238576 20.7761424,18 20.5,18 C20.2238576,18 20,18.2238576 20,18.5 C20,18.7761424 20.2238576,19 20.5,19 Z M7.5,19 C7.77614237,19 8,18.7761424 8,18.5 C8,18.2238576 7.77614237,18 7.5,18 C7.22385763,18 7,18.2238576 7,18.5 C7,18.7761424 7.22385763,19 7.5,19 Z M8,5 L20,5 C20.5522847,5 21,5.44771525 21,6 L21,14 C21,14.5522847 20.5522847,15 20,15 L8,15 C7.44771525,15 7,14.5522847 7,14 L7,6 C7,5.44771525 7.44771525,5 8,5 Z M9,7 L9,13 L19,13 L19,7 L9,7 Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Provider;
