import {
  REFILL_CLOSE,
  REFILL_OPEN,
  PAYMENT_AMOUNT_SET,
  PAYMENT_AMOUNT_REMOVE,
} from '../actions/refillActions';

interface State {
  callback?: (err, res) => void;
  singlePaymentAmount: number | null;
}

const INITIAL_STATE = {
  singlePaymentAmount: null,
};

const refillReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case REFILL_OPEN:
      return {
        ...state,
        callback: payload.callback,
        openSingleTabByDefault: payload.openSingleTab,
      };

    case REFILL_CLOSE:
      return {
        ...state,
        callback: null,
        openSingleTabByDefault: false,
      };

    case PAYMENT_AMOUNT_SET:
      return {
        ...state,
        singlePaymentAmount: payload.amount,
      };

    case PAYMENT_AMOUNT_REMOVE:
      return {
        ...state,
        singlePaymentAmount: null,
      };

    default:
      return state;
  }
};

export default refillReducer;
