import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  NotificationType,
  showNotification,
  NotificationOptions,
} from 'store/actions/notificationActions';

const generateNotificationKey = () => {
  return `${Math.random().toString(36).substring(2, 15)}${Math.random()
    .toString(36)
    .substring(2, 15)}`;
};

const useCustomNotify = () => {
  const dispatch = useDispatch();
  return useCallback(
    (content: any, type: NotificationType = 'info', notificationOptions?: NotificationOptions) => {
      dispatch(showNotification(content, type, generateNotificationKey(), notificationOptions));
    },
    [dispatch]
  );
};

export default useCustomNotify;
