import React from 'react';
import classNames from 'classnames';
import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },

  title: {
    padding: theme.spacing(0, 2, 1),
  },
}));

const OptionList = (props) => {
  const classes = useStyles();

  if (!props.children) {
    return null;
  }

  return (
    <Paper {...props} className={classNames(classes.root, props.className)} elevation={8}>
      <Typography variant="overline" className={classes.title}>
        {props.listName ?? 'Contacts'}
      </Typography>
      {props.children}
    </Paper>
  );
};

export default OptionList;
