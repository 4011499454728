import { Reducer } from 'redux';
import {
  HIDE_NOTIFICATION,
  HideNotificationAction,
  SHOW_NOTIFICATION,
  ShowNotificationAction,
  Notification,
} from 'store/actions/notificationActions';

type ActionTypes = ShowNotificationAction | HideNotificationAction | { type: 'OTHER_TYPE' };

type State = Notification[];

const notificationsReducer: Reducer<State> = (previousState = [], action: ActionTypes) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      // @ts-ignore
      return previousState.concat(action.payload);
    case HIDE_NOTIFICATION:
      return action.payload === 'all'
        ? []
        : previousState.filter((notification) => notification.id !== action.payload);
    default:
      return previousState;
  }
};

export default notificationsReducer;
