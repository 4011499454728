import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Dialog } from 'components/common';

const MessageBox = () => {
  const { message, callback } = useSelector((state) => state.messagebox, shallowEqual);

  const handleConfirm = () => {
    callback(true);
  };

  const handleCancel = () => {
    callback(false);
  };

  if (!message || !callback) {
    return null;
  }

  return (
    <Dialog
      open={true}
      title={message.title}
      confirmText={message.confirmText}
      cancelText={message.cancelText}
      onConfirm={message.confirmText && handleConfirm}
      onCancel={handleCancel}>
      <p>{message.text}</p>
    </Dialog>
  );
};

export default MessageBox;
