import React, { useEffect, useState } from 'react';
import { useRedirect, useDataProvider } from 'react-admin';
import { parse } from 'querystring';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as ErrorIcon } from 'assets/general/icons/Error.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '0 auto',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
}));

const TikTokConnect = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const queryPart = props.location.search.substring(1);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const connectTikTok = async (state: string, code: string) => {
      try {
        window.localStorage.setItem('tiktokConnecting', 'connecting');

        setError('');
        setLoading(true);

        await dataProvider.post('tiktokSellingChannel', { data: { code, state } });

        redirect('list', '/sellingChannels');
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const tiktokIdValue = window.localStorage.getItem('tiktokId');

    if (tiktokIdValue) return;

    if (queryPart) {
      const { code, state } = parse(queryPart);

      connectTikTok(state as string, code as string);
    }
  }, [dataProvider, queryPart, redirect]);

  if (error) {
    return (
      <Card className={classes.card}>
        <ErrorIcon width={100} height={100} />
        <Typography variant="h5">{error}. Please try again.</Typography>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card className={classes.card}>
        <CircularProgress size={100} />
        <Typography variant="h5">Connecting...</Typography>
      </Card>
    );
  }

  return <></>;
};

export default TikTokConnect;
