import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Contact = (props) => (
  <SvgIcon {...props}>
    <path
      d="M16 15V5H8v10zm1-12a1 1 0 011 1v16a1 1 0 01-1 1H7a1 1 0 01-1-1V4a1 1 0 011-1zm-5 16a1 1 0 110-2 1 1 0 010 2zm0 0h4v-2H8v2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Contact;
