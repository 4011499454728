import { SAVE_CURRENT_GROUP, SET_IS_GROUPED } from 'store/actions/ordersActions';

interface State {
  group: {
    id: string | null;
    items: {
      name: string | null;
      quantity: number | null;
      sku: string | null;
    }[];
    orderedFromAbroad: boolean;
    ordersInGroup: boolean;
  };
  isGrouped: boolean;
}

const INITIAL_STATE = {
  group: {
    id: null,
    items: [],
    orderedFromAbroad: false,
    ordersInGroup: false,
  },
  isGrouped: false,
};

const ordersReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVE_CURRENT_GROUP:
      return { ...state, group: payload };
    case SET_IS_GROUPED:
      return { ...state, isGrouped: payload };
    default:
      return state;
  }
};

export default ordersReducer;
