import React, { useCallback } from 'react';
import Input from './Input';

const NumberInput = ({ isInteger = false, ...props }) => {
  const handleChange = useCallback((value: string) => {
    return !isInteger
      ? value.replace(/[^\d.]/g, '').replace(/\.(?=.*\.)/g, '')
      : value.replace(/[^\d]/g, '');
  }, []); //eslint-disable-line

  return <Input {...props} changeProxy={handleChange} />;
};

export default NumberInput;
